import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Common';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => getToken() ?
      <Component urlData={ props.match.params
      }
        to={{ pathname: { ...props.path } }} /> : <Redirect to={{ pathname: '/Login', state: { from: props.location } }} />} />
  )
}
