import { useEffect, useState } from "react";

export const LiveVideo = (props) => {
  const { isDrawEnable, setIsDrawEnable, selectedLensXYPoints, remoteVideoElement } = props;

  const [existingLines, setExistingLines] = useState([]);

  useEffect(() => {
    const zoomElement = document.getElementById("videoAndTextContainer");

    var zoom = 1;

    const zoomFunction = (e) => {
      if (e.deltaY > 0) {
        zoomElement.style.transform = `scale(${(zoom -= 0.02)})`; // scrolling down
      } else if (zoomElement.getBoundingClientRect().width >= 30) {
        zoomElement.style.transform = `scale(${(zoom += 0.02)})`; // scrolling left
      }
    };

    if (isDrawEnable) {
      zoomElement.style.transform = `scale(${(zoom = 1)})`;
    } else if (!isDrawEnable) {
      zoomElement.addEventListener("wheel", zoomFunction);
    }

    return () => {
      zoomElement.removeEventListener("wheel", zoomFunction);
    };
  }, [isDrawEnable]);

  useEffect(() => {
    var sourcevideo = document.getElementById("remoteVideo");
    var outputcanvas = document.getElementById("canvas");
    outputcanvas.style.width = "100%"; // Note you must post fix the unit type %,px,em
    outputcanvas.style.height = "100%";

    var canvasctx = outputcanvas.getContext("2d");

    canvasctx.globalAlpha = 0.0;

    var bounds = outputcanvas.getBoundingClientRect();

    var hasLoaded = false;
    var startX = 0;
    var startY = 0;
    var mouseX = 0;
    var mouseY = 0;
    var isDrawing = false;

    function linePixelCalc(endX, startX, endY, startY) {
      var xs = 0;
      var ys = 0;
      xs = endX - startX;
      xs = xs * xs;

      ys = endY - startY;
      ys = ys * ys;

      return Math.sqrt(xs + ys).toFixed(2);
    }

    function lineMicroMeterCalc(lineLengthInPixel, vedioWidth, lensXValue) {
      let output = lineLengthInPixel * (lensXValue / vedioWidth);

      return output.toFixed(2);
    }

    function slopeOfLine(endX, startX, endY, startY) {
      let output = (endY - startY) / (endX - startX);

      return output.toFixed(2);
    }

    function drawLine() {
      outputcanvas.width = sourcevideo.videoWidth;
      outputcanvas.height = sourcevideo.videoHeight;

      canvasctx.beginPath();

      // start --->only to draw line
      for (var i = 0; i < existingLines.length; ++i) {
        canvasctx.strokeStyle = "black";
        canvasctx.lineWidth = 2;
        var line = existingLines[i];
        const { endX, startX, endY, startY } = line;

        canvasctx.moveTo(startX, startY);
        canvasctx.lineTo(endX, endY);

        canvasctx.font = "15px serif";

        canvasctx.stroke();
      }
      // end --> only to draw line

      //start ---> box with text
      for (var j = 0; j < existingLines.length; ++j) {
        var line1 = existingLines[j];
        const { endX, startX, endY, startY } = line1;

        let slope = slopeOfLine(endX, startX, endY, startY);

        let lineLengthInPixel = linePixelCalc(endX, startX, endY, startY);

        let lineLengthInMicroMeter = lineMicroMeterCalc(lineLengthInPixel, sourcevideo.videoWidth, selectedLensXYPoints[0]);

        canvasctx.font = "15px serif";

        function roundRect(ctx, x, y, width, height, fill) {
          ctx.fillStyle = "yellow";
          ctx.strokeStyle = "yellow";
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(x + width, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y);
          ctx.lineTo(x + width, y + height);
          ctx.quadraticCurveTo(x + width, y + height, x + width, y + height);
          ctx.lineTo(x, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height);
          ctx.lineTo(x, y);
          ctx.quadraticCurveTo(x, y, x, y);
          ctx.closePath();

          if (fill) {
            ctx.fill();
          }
          ctx.stroke();
        }

        var rectX = (startX + endX) / 2;
        var rectY = (startY + endY) / 2;

        if (selectedLensXYPoints[0]) {
          if (slope < 0) {
            roundRect(canvasctx, rectX + 5, rectY + 5, canvasctx.measureText(lineLengthInMicroMeter).width * 1.5, 20, true);
            canvasctx.fillStyle = "black";
            canvasctx.fillText(`${lineLengthInMicroMeter} μm`, rectX + 5, rectY + 5 + 20 / 1.5);
          } else {
            roundRect(canvasctx, rectX + 10, rectY - 24, canvasctx.measureText(lineLengthInMicroMeter).width * 1.5, 20, true);
            canvasctx.fillStyle = "black";
            canvasctx.fillText(`${lineLengthInMicroMeter} μm`, rectX + 10, rectY - 24 + 20 / 1.5);
          }
        } else {
          if (slope < 0) {
            roundRect(canvasctx, rectX + 5, rectY + 5, canvasctx.measureText(lineLengthInPixel).width * 1.5, 20, true);
            canvasctx.fillStyle = "black";
            canvasctx.fillText(`${lineLengthInPixel} px`, rectX + 5, rectY + 5 + 20 / 1.5);
          } else {
            roundRect(canvasctx, rectX + 10, rectY - 24, canvasctx.measureText(lineLengthInPixel).width * 1.5, 20, true);
            canvasctx.fillStyle = "black";
            canvasctx.fillText(`${lineLengthInPixel} px`, rectX + 10, rectY - 24 + 20 / 1.5);
          }
        }
      }
      //end --> box with text

      if (isDrawing) {
        canvasctx.strokeStyle = "darkred";
        canvasctx.lineWidth = 3;
        canvasctx.beginPath();
        canvasctx.setLineDash([5, 15]);
        canvasctx.moveTo(startX, startY);
        canvasctx.lineTo(mouseX, mouseY);
        canvasctx.stroke();
      }
    }

    function onmousedown(e) {
      if (hasLoaded && e.button === 0) {
        if (!isDrawing) {
          startX = e.clientX - bounds.left - window.scrollX;
          startY = e.clientY - bounds.top - window.scrollY;
          startX /= bounds.width;
          startY /= bounds.height;

          startX *= outputcanvas.width;
          startY *= outputcanvas.height;

          isDrawing = true;
        }

        drawLine();
      }
    }

    function onmouseup(e) {
      if (hasLoaded && e.button === 0) {
        if (isDrawing) {
          setExistingLines((prev) => [
            ...prev,
            {
              startX: startX,
              startY: startY,
              endX: mouseX,
              endY: mouseY,
            },
          ]);
          isDrawing = false;
        }

        drawLine();
      }
    }

    function onmousemove(e) {
      if (hasLoaded) {
        mouseX = e.clientX - bounds.left - window.scrollX;
        mouseY = e.clientY - bounds.top - window.scrollY;
        mouseX /= bounds.width;
        mouseY /= bounds.height;

        mouseX *= outputcanvas.width;
        mouseY *= outputcanvas.height;

        if (isDrawing) {
          drawLine();
        }
      }
    }

    function initCanvas() {
      if (isDrawEnable) {
        outputcanvas.onmousedown = onmousedown;
        outputcanvas.onmouseup = onmouseup;
        outputcanvas.onmousemove = onmousemove;

        hasLoaded = true;
        drawLine();
      } else {
        outputcanvas.onmousedown = null;
        outputcanvas.onmouseup = null;
        outputcanvas.onmousemove = null;
      }
    }
    initCanvas();

    const clearDrawing = () => {
      canvasctx.clearRect(0, 0, outputcanvas.width, outputcanvas.height);
      setExistingLines([]);
    };

    const loadData = () => {
      setIsDrawEnable(!isDrawEnable);
    };

    document.getElementById("clear").addEventListener("click", clearDrawing, false);
    document.getElementById("draw").addEventListener("click", loadData, false);

    return () => {
      document.getElementById("clear").removeEventListener("click", clearDrawing, false);
      document.getElementById("draw").removeEventListener("click", loadData, false);
    };
  }, [isDrawEnable, existingLines, setIsDrawEnable, selectedLensXYPoints]);

  return (
    <div
      id="full-screen-div"
      style={{ objectFit: "contain", height: "99vh" }}
    >
      <figure
        id="videoAndTextContainer"
        style={{ position: "relative", width: "100%", height: "100%" }}
        className="embed-responsive   video-container"
      >
        <video
          id="remoteVideo"
          className="hidden m-0 p-0"
          ref={remoteVideoElement}
          width="100%"
          height="100%"
          autoPlay
        />
        <canvas
          className="hidden m-0 p-0"
          id="canvas"
        ></canvas>
      </figure>
    </div>
  );
};
