import React, { useRef, useEffect, useContext, useState } from "react";
import { PageContext } from "../../LiveView";
import Map from "ol/Map";
import View from "ol/View";
import ImageLayer from "ol/layer/Image";
import Projection from "ol/proj/Projection";
import Static from "ol/source/ImageStatic";
import { getCenter } from "ol/extent";
// import Feature from 'ol/Feature';
// import Point from 'ol/geom/Point';
import MapContext from "./MapContext";

const MapComponent = ({ children, WSImageData, PostionCoords }) => {
  const stateContext = useContext(PageContext);
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [image, setImage] = useState(null);

  function base64ToArrayBuffer(base64) {
    // console.log("6 enter base64ToArrayBuffer function")
    var binary_string = window.atob(base64); // example: window.atob(SGVsbG8gV29ybGQh) => output: Hello World!
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      // console.log("777... enter base64ToArrayBuffer function for loop")
      bytes[i] = binary_string.charCodeAt(i); // example: "HELLO WORLD".charCodeAt(0) => output: 72
    }
    return bytes.buffer;
  }

  const prepareImageCrop = () => {
    let byteData = base64ToArrayBuffer(WSImageData);

    if (byteData.byteLength > 0) {
      // console.log("8 enter  prepareImageCanvas if condition")
      let blob = new Blob([byteData]);
      let blobURL = URL.createObjectURL(blob);
      var img = new Image();
      img.src = blobURL;
      setImage(img);
      img.onload = function () {
        if (mapRef.current.children.length > 0) {
          var child = mapRef.current.lastElementChild;
          while (child) {
            mapRef.current.removeChild(child);
            child = mapRef.current.lastElementChild;
          }
        }

        var extent = [0, 0, img.width, img.height];

        var projection = new Projection({
          code: "xkcd-image",
          units: "pixels",
          extent: extent,
        });

        var source = new Static({
          imageLoadFunction: function (image, src) {
            image.getImage().src = blobURL;
          },
          imageExtent: extent,
        });

        var IMG_Layer = new ImageLayer({
          source: source,
        });

        var map = new Map({
          layers: [IMG_Layer],
          // target: mapElement.current,
          view: new View({
            projection: projection,
            center: getCenter(extent),
            zoom: 1,
          }),
          controls: [],
        });

        map.getView().fit(extent);
        map.setTarget(mapRef.current);
        setMap(map);
      };
    }
  };

  // on component mount
  useEffect(() => {
    prepareImageCrop();
  }, [WSImageData]);

  return (
    <MapContext.Provider value={{ map, image, PostionCoords }}>
      <div
        id="map"
        ref={mapRef}
        style={{
          width: "100%",
          height: "250px",
          cursor: "pointer",
        }}
      >
        {children}
      </div>
    </MapContext.Provider>
  );
};
export default MapComponent;
