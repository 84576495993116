import React, { useEffect, useContext, useState } from "react";
import { PageContext } from "../Settings";
import { generateOtp, getOtp } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { ProgressBar } from "react-bootstrap";

const OtpGeneration = () => {
  const [isCopied, setIsCopied] = useState(false);
  const context = useContext(PageContext);
  const expiryEpoch = context.pageState?.isMaint?.expiry;
  const [remainingTime, setRemainingTime] = useState(expiryEpoch - Math.floor(Date.now() / 1000));

  useEffect(() => {
    async function fetchOtp() {
      const res = await getOtp();
      context.pageDispatcher.set_isMaint(res.data);
    }
    fetchOtp();
  }, []);

  // context.pageState.isMaint.otp

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      const newRemainingTime = Math.max(expiryEpoch - currentTime, 0);
      setRemainingTime(newRemainingTime);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [expiryEpoch]);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;
  const formattedTime = `${minutes}min:${seconds.toString().padStart(2, "0")}sec`;

  let formattedTimeStyle = {};
  if (remainingTime === 0) {
    formattedTimeStyle.color = "black";
  } else if (remainingTime <= 145) {
    formattedTimeStyle.color = "red";
  } else if (remainingTime <= 250) {
    formattedTimeStyle.color = "orange";
  } else {
    formattedTimeStyle.color = "green";
  }

  const progressPercentage = (1 - remainingTime / 300) * 100;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(context.pageState.isMaint.otp)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500); // Reset the "Copied!" state after 1.5 seconds
      })
      .catch(err => console.error('Failed to copy: ', err));
  };

  return (
    <>
      <NotificationContainer />
      <div>
        <h1>{remainingTime === 0 ? <>_ _ _ _ _ _</> :<span> {context.pageState.isMaint.otp} <button className="btn btn-primary" style={{fontSize:"12px"}} onClick={handleCopyClick}>{isCopied ? 'Copied!' : 'Copy'} </button></span>}</h1>
        <br />

        <>
          Expiry Time: <h3 style={formattedTimeStyle}>{formattedTime}</h3>
        </>
        {progressPercentage === 100 ? (
          <>
            Time Out
            <br />
          </>
        ) : (
          <ProgressBar
            animated
            now={progressPercentage}
            label={`${progressPercentage.toFixed(2)}%`}
          />
        )}
        <a
          href="#!"
          onClick={() => {
            generateOtp().then((res) => {
              if (res && res.data.Message) {
                context.pageDispatcher.set_isMaint(res?.data);
                NotificationManager.success("updated", "", 2000);
                setRemainingTime(expiryEpoch - Math.floor(Date.now() / 1000));
              }
            });
          }}
        >
          Re-generate Otp.
        </a>
      </div>
    </>
  );
};

export default OtpGeneration;
