import React, { useState } from "react";
import { generateQRCode } from "../Service";

const QRcodeGenerator = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState(null);

  const requestQRCode = async () => {
    try {
      const blob = await generateQRCode();
      const url = URL.createObjectURL(blob);
        console.log("url:",url)

      setQrCodeUrl(url);
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={requestQRCode}
      >
        Generate QR Code
      </button>
      {qrCodeUrl && (
        <div className="mt-3">
          <h3>Scan the QR Code</h3>
          <img src={qrCodeUrl} alt="QR Code" />
        </div>
      )}
    </div>
  );
};

export default QRcodeGenerator;
