import { useEffect, useState, useRef } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { PageTitle } from "../../shared/PageTitle";
import "ol/ol.css";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Zoomify from "ol/source/Zoomify";
import Cropper from "cropperjs";
import TileState from "ol/TileState";
import { Button, Modal, Image, Container, Row } from "react-bootstrap";
import { OverviewMap } from "ol/control";
import FullScreen from "ol/control/FullScreen";
import Overlay from "ol/Overlay";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { LineString, Polygon } from "ol/geom";
import Draw from "ol/interaction/Draw";
import { unByKey } from "ol/Observable";
import { ScaleLine } from "ol/control";
import GeoJSON from "ol/format/GeoJSON";
// import olMapScreenshot from 'ol-map-screenshot';
import Projection from "ol/proj/Projection";
import Feature from "ol/Feature";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
  groupSlideToken,
  getScaleData,
  getSlideViewerData,
  getannotated,
  saveAnnotation,
  getAnnotationsList,
  deleteAnnotation,
  // getImageFromCloud,
  getPdfFromCloud,
} from "../Service";
import Loader from "react-loader-spinner";

import { environment } from "../../../environment";

const zoomifyURL = environment.Micalys_Image_Server + "get_slide_img/";

export const ViewSlide = (props) => {
  const slide_id = props.urlData.id;
  const mapElement = useRef(null);
  const selectedElement = useRef(null);
  const zoomLevelElement = useRef(null);
  const imageElement = useRef(null);
  const annotationElement = useRef(null);

  const initialState = {
    slideInfo: {
      slideName: "",
      createdDate: "",
      slideToken: "",
      assigned_to: "",
    },
    scaleValue: "",
    disableTools: true,
    wsImage: "",
    // popupVisible: 'hidden',
    // mode: 'add',
    // isCommentBtnDisable: true,
    comment: "",
    data: [],
  };

  const [state, setState] = useState(initialState);

  const [popupVisible, setpopupVisible] = useState("hidden");

  const [mode, setMode] = useState("add");
  const [isCommentBtnDisable, setisCommentBtnDisable] = useState(true);

  const [annotations, setAnnotations] = useState([]);

  const annotationList = (slide_access_token) => {
    return getAnnotationsList(slide_access_token);
  };

  const prepareSlideViewerData = (slide_id, slide_access_token, scaleData, email) => {
    // let a = await annotationList();

    getSlideViewerData(slide_id, slide_access_token)
      .then((res) => {
        if (res && res.data) {
          // console.log("check if rerendered after save")
          NotificationManager.success("success!", "", 2000);

          setMode("add");

          var parser = new DOMParser();
          var xmlDoc = parser.parseFromString(res.data, "text/xml");
          var elements = xmlDoc.getElementsByTagName("IMAGE_PROPERTIES");
          var tileSize = Number(elements[0].getAttribute("TILESIZE"));

          if (elements[0].getAttribute("WIDTH") != null && elements[0].getAttribute("HEIGHT") != null) {
            var imgWidth = Number(elements[0].getAttribute("WIDTH"));
            var imgHeight = Number(elements[0].getAttribute("HEIGHT"));
            // console.log("imgeheight", imgHeight);
            // console.log("imgWidth", imgWidth)
          }

          let draw, coordinates, interactionData, commentData, measureTooltipElement, measureTooltip, helpTooltipElement, helpTooltip, sketch, result;
          let featurearr = [];
          let listener;
          var typeSelect = document.getElementById("selectedElement");
          var container = document.getElementById("popup");
          var button = document.getElementById("annotationdata");
          var cancelPopup = document.getElementById("cancelBtn");
          var deletedraw = document.getElementById("deletedraw");
          var locatefeature = document.getElementById("locatefeature");
          let annotation_id = "";

          var source = new Zoomify({
            url: zoomifyURL + slide_id + "/",
            size: [imgWidth, imgHeight],
            crossOrigin: "anonymous",
            zDirection: -1,
            tileSize: tileSize / 1,
            tilePixelRatio: 1,
          });
          var extent = source.getTileGrid().getExtent();

          var layer = new TileLayer({
            source: source,
          });
          var vector_layer = new VectorLayer({
            name: "my_vectorlayer",
            source: new VectorSource({
              wrapX: false,
            }),
            style: new Style({
              fill: new Fill({
                color: "rgba(255, 255, 255, 0.2)",
              }),
              stroke: new Stroke({
                color: "#ff0000",
                width: 2,
              }),
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                  color: "#ff0000",
                }),
              }),
            }),
          });

          function createHelpTooltip() {
            if (helpTooltipElement) {
              helpTooltipElement.parentNode.removeChild(helpTooltipElement);
            }
            helpTooltipElement = document.createElement("div");
            helpTooltipElement.className = "ol-tooltip hidden";

            helpTooltip = new Overlay({
              element: helpTooltipElement,
              offset: [15, 0],
              positioning: "center-left",
            });
            initialMap.addOverlay(helpTooltip);
          }
          function createMeasureTooltip() {
            if (measureTooltipElement) {
              measureTooltipElement.parentNode.removeChild(measureTooltipElement);
            }
            measureTooltipElement = document.createElement("div");
            measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
            measureTooltip = new Overlay({
              element: measureTooltipElement,
              offset: [0, -15],
              positioning: "bottom-center",
              stopEvent: false,
              insertFirst: false,
            });
            initialMap.addOverlay(measureTooltip);
          }

          function addInteraction(selectedElement) {
            if (selectedElement && selectedElement.id) {
              if (selectedElement.id !== "none") {
                const type = selectedElement.id === "area" ? "Polygon" : "LineString";

                draw = new Draw({
                  source: vector_layer.getSource(),
                  type: type,
                  style: new Style({
                    fill: new Fill({
                      color: "rgba(255, 255, 255, 0.2)",
                    }),
                    stroke: new Stroke({
                      color: "red",
                      lineDash: [10, 10],
                      width: 2,
                    }),
                    image: new CircleStyle({
                      radius: 5,
                      stroke: new Stroke({
                        color: "yellow",
                      }),
                      fill: new Fill({
                        color: "#ffcc33",
                      }),
                    }),
                  }),
                });

                draw.on("drawstart", function (evt) {
                  if (coordinates && coordinates.length > 0) {
                    //refresh all features when drawing new on start
                    drawingData();
                    coordinates[0].map((el) => {
                      return overlay.setPosition(undefined);
                    });
                    removeFeatures();
                  }

                  // set sketch
                  sketch = evt.feature; //getDrawnFeatureObj i.e Feature{..}
                  listener = sketch.getGeometry().on("change", function (event) {});

                  vector_layer.getSource().on("change", function (evt) {
                    ///this is to get all features of array
                    var source = evt.target;
                    if (source.getState() === "ready") {
                      ///this is imp otherwise first feature data will be empty
                      // console.info(vector_layer.getSource().getFeatures());
                    }
                  });
                });

                draw.on("drawend", function (event) {
                  setpopupVisible("visible");
                  if (mode === "add") {
                    document.getElementById("commentbox").disabled = false;
                    document.getElementById("commentbox").value = "";
                  }
                  let geomType = event.feature.getGeometry();
                  let output;
                  let arr = [];
                  let X, Y;
                  let Xarr = [],
                    Yarr = [];

                  if (geomType.getType() === "Polygon") {
                    coordinates = event.feature.getGeometry().getCoordinates();
                    coordinates = coordinates[0];

                    coordinates.forEach((el) => {
                      X = Math.round(el[0]);
                      Y = Math.round(el[1]);
                      arr.push([X, Y]);
                    });
                    // console.log("arr", arr)
                    arr.forEach((el) => {
                      Xarr.push(el[0]);
                      Yarr.push(el[1]);
                    });

                    output = polygonArea(Xarr, Yarr, Xarr.length);
                    // tooltipCoord = geomType.getInteriorPoint().getCoordinates();
                    // console.log("coo in polygon", coordinates)
                    // console.log("output of Polygon", output)
                  } else if (geomType.getType() === "LineString") {
                    coordinates = event.feature.getGeometry().getCoordinates();
                    coordinates.forEach((el) => {
                      X = Math.round(el[0]);
                      Y = Math.round(el[1]);

                      arr.push([X, Y]);
                    });
                    output = lineStringLength(arr);
                    // console.log("output of Linestring", output)
                    // tooltipCoord = geomType.getLastCoordinate();
                  }

                  // ////open popup and get coordinates & convert to GeoJSON to be saved in database
                  // console.log("coordinates[0]",coordinates)
                  overlay.setPosition(coordinates[0]); //for popup open

                  vector_layer.getSource().on("addfeature", function (event) {
                    // var featureData = vector_layer.getSource().getFeatures();
                    var writer = new GeoJSON();
                    var geojsonformat = writer.writeFeatures([event.feature]);
                    let d1 = JSON.parse(geojsonformat);
                    interactionData = d1.features.at(-1).geometry;
                    interactionData["areacoords"] = output;
                    // console.info("interactionData", interactionData);
                  });
                });
                initialMap.addInteraction(draw);
              }
            }
          }

          // *******************************************************ScaleLine Feature Start*****************************************************************************************//

          const scaleControl = new ScaleLine({
            //adding scaleLine
            units: "metric",
          });

          let px_To_mmVal = 1 / scaleData;

          let proj = new Projection({
            //this step is needed to set micro scale on map.
            units: "pixels",
            extent: [0, 0, imgWidth, imgHeight],
            metersPerUnit: px_To_mmVal / 1000000, //(this is in micro unit)=>here converted px to mm & then mm to micro meter to get value
            // global: true   //by adding this,overview map won't xoom while zooming parent map
          });

          // *******************************************************ScaleLine Feature Start*****************************************************************************************//

          // ******************************************************Overview Feature Start*******************************************************************************************//

          let overviewMapControl = new OverviewMap({
            className: "ol-overviewmap ol-custom-overviewmap",
            layers: [
              new TileLayer({
                source: source,
              }),
            ],
            // autoPan:true,
            collapseLabel: "\u00AB",
            label: "\u00BB",
            collapsed: true,
            collapsible: true,
            view: new View({
              resolutions: layer.getSource().getTileGrid().getResolutions(),
              extent: [imgWidth, 0, 0, -imgHeight],
              projection: scaleData ? proj : "",
              constrainOnlyCenter: true,
              // center: [0, 0],
              // center: [500000, 6000000],
              maxZoom: 0,
              minZoom: 0,
              zoom: 0,
            }),
          });

          // ******************************************************Overview Feature End*****************************************************************************************//

          source.setTileLoadFunction(function (tile, src) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.addEventListener("loadend", function (evt) {
              var data = this.response;
              if (data !== undefined) {
                tile.getImage().src = URL.createObjectURL(data);
              } else {
                tile.setState(TileState.ERROR);
              }
            });
            xhr.addEventListener("error", function () {
              tile.setState(TileState.ERROR);
            });
            xhr.open("GET", src);
            xhr.setRequestHeader("Authorization", "Bearer " + slide_access_token);

            xhr.send();
          });
          const continueLineMsg = "Click to continue drawing the line";
          const continuePolygonMsg = "Click to continue drawing the polygon";

          const pointerMoveHandler = function (evt) {
            if (evt.dragging) {
              return;
            }

            let helpMsg = "Click to start drawing";

            if (sketch) {
              const geom = sketch.getGeometry();
              if (geom instanceof Polygon) {
                helpMsg = continuePolygonMsg;
              } else if (geom instanceof LineString) {
                helpMsg = continueLineMsg;
              }

              helpTooltipElement.innerHTML = helpMsg;
              helpTooltip.setPosition(evt.coordinate);
              helpTooltipElement.classList.remove("hidden");
            }
          };

          // *******************************************************Initialize Map Start*****************************************************************************************//

          var overlay = new Overlay({
            element: container,
            autoPan: true,
            autoPanAnimation: {
              duration: 250,
            },
          });

          var view = new View({
            resolutions: layer.getSource().getTileGrid().getResolutions(),
            extent: extent,
            projection: proj,
            zoom: 2,
            constrainOnlyCenter: true,
            enableRotation: false,
            // center: [0, 0],
            center: [6764.340449438201, -5356.274789325842],
          });

          var initialMap = new Map({
            layers: [layer, vector_layer],
            loadTilesWhileAnimating: true,
            loadTilesWhileInteracting: true,
            target: mapElement.current,
            overlays: [overlay],
            view: view,
          });

          if (scaleData) {
            initialMap.addControl(scaleControl);
          }
          overviewMapControl.getOverviewMap().on("click", function (event) {
            initialMap.getView().setCenter(event.coordinate);
          });
          initialMap.addControl(overviewMapControl);
          initialMap.addControl(new FullScreen());
          initialMap.getView().fit(extent);

          // console.log("current zoom", initialMap.getView().getZoom())

          const polygonArea = function (X, Y, n) {
            let area = 0.0;
            let j = n - 1;
            for (let i = 0; i < n; i++) {
              area += (X[j] + X[i]) * (Y[j] - Y[i]);
              j = i;
            }

            let val = Math.abs(area / 2.0);

            if (!Number.isInteger(val)) {
              val.toFixed(2);
            }

            let output;
            if (scaleData) {
              output = `${(val / Math.pow(scaleData, 2)).toFixed(2)} µm<sup>2</sup>`;
            } else {
              output = `${val} px<sup>2</sup>`;
            }

            return output;
          };

          const lineStringLength = function (lineCoords) {
            let output, data;
            let dataarr = [];
            for (let i = 0; i < lineCoords.length; i++) {
              let xn = lineCoords[i][0];
              let yn = lineCoords[i][1];

              if (lineCoords[i + 1] !== undefined) {
                let xinc = lineCoords[i + 1][0];
                let yinc = lineCoords[i + 1][1];

                // console.log("(x(n+1)-xn):", (xinc - xn))
                // console.log("(y(y+1)-yn):", (yinc - yn))

                data = Math.sqrt(Math.pow(xinc - xn, 2) + Math.pow(yinc - yn, 2));
                dataarr.push(data);
              }
            }

            let sum = dataarr.reduce(function (accumulator, currentValue) {
              return accumulator + currentValue;
            });

            let val = Math.round(sum);

            if (!Number.isInteger(val)) {
              val.toFixed(2);
            }

            if (scaleData) {
              output = `${(val / scaleData).toFixed(2)} µm`;
            } else {
              output = `${val} px`;
            }

            return output;
          };

          // *******************************************************Measure Feature Start*****************************************************************************************//
          if (typeSelect) {
            typeSelect.addEventListener("click", function (event) {
              initialMap.removeInteraction(draw);
              drawingData();

              if (event.target.id !== "none") {
                document.getElementById("commentbox").disabled = false;
                document.getElementById("deletedraw").disabled = true;
                setMode("add");
                initialMap.on("pointermove", pointerMoveHandler);
                createMeasureTooltip();
                createHelpTooltip();
                addInteraction(event.target);
              } else {
                // setMode('edit')
                sketch = null;
                if (draw) {
                  draw = undefined;
                }
                if (typeSelect) {
                  typeSelect = undefined;
                }
                initialMap.removeOverlay(helpTooltip);
                initialMap.removeOverlay(measureTooltip);
              }
            });
          }

          // *******************************************************Measure Feature End*****************************************************************************************//

          // *******************************************************Download View Feature Start*****************************************************************************************//

          const mapScreenshotParam = {
            // dim: [100, 70],
            format: "jpeg",
          };

          let exp_btn = document.getElementById("export-png");

          if (exp_btn) {
            exp_btn.onclick = async () => {
              // doDonwload("map-screenshot.jpg");
              doDonwload("map.png");
            };
          }

          async function doDonwload(fileName) {
            const response = await doScreenshot(fileName);
            // console.log("response:", response)
            // const element = document.createElement("a");
            // element.setAttribute("href", response);
            // element.setAttribute("download", fileName);
            // element.style.display = "none";
            // document.body.appendChild(element);
            // element.click();
            // document.body.removeChild(element);
          }

          async function doScreenshot(fileName) {
            // try {
            //     return await olMapScreenshot.getScreenshot(initialMap, mapScreenshotParam);
            // } catch (ex) {
            //     alert(ex.message);
            // }
            var rtval;
            // document.getElementById('export-png').addEventListener('click', function () {
            initialMap.once("rendercomplete", function () {
              const mapCanvas = document.createElement("canvas");
              const size = initialMap.getSize();
              mapCanvas.width = size[0];
              mapCanvas.height = size[1];
              const mapContext = mapCanvas.getContext("2d");
              Array.prototype.forEach.call(initialMap.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"), function (canvas) {
                if (canvas.width > 0) {
                  const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
                  mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
                  let matrix;
                  const transform = canvas.style.transform;
                  if (transform) {
                    // Get the transform parameters from the style's transform matrix
                    matrix = transform
                      .match(/^matrix\(([^\(]*)\)$/)[1]
                      .split(",")
                      .map(Number);
                  } else {
                    matrix = [parseFloat(canvas.style.width) / canvas.width, 0, 0, parseFloat(canvas.style.height) / canvas.height, 0, 0];
                  }
                  // Apply the transform to the export map context
                  CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
                  const backgroundColor = canvas.parentNode.style.backgroundColor;
                  if (backgroundColor) {
                    mapContext.fillStyle = backgroundColor;
                    mapContext.fillRect(0, 0, canvas.width, canvas.height);
                  }
                  mapContext.drawImage(canvas, 0, 0);
                }
              });

              mapContext.globalAlpha = 1;
              mapContext.setTransform(1, 0, 0, 1, 0, 0);
              rtval = mapCanvas.toDataURL();
              console.log(1);
              const element = document.createElement("a");
              element.setAttribute("href", mapCanvas.toDataURL());
              element.setAttribute("download", fileName);
              element.style.display = "none";
              document.body.appendChild(element);
              element.click();

              //   const link = document.getElementById('image-download');
              //   link.href = mapCanvas.toDataURL();
              //   link.click();
            });
            initialMap.renderSync();
            console.log(3);

            //   }
            //   );
            console.log(2);
          }
          // *******************************************************Download View Feature End*****************************************************************************************//

          initialMap.on("moveend", () => {
            // for testing only.
            // var zoom = initialMap.getView().getZoom();
            // console.log("min zoom", zoom);
            // console.log("maxZoom", initialMap.getView().getMaxZoom()
            // )
            // var view = initialMap.getView();
            // // console.log("view", view);
            // var resolution = view.getResolution();
            // // alert(resolution)
            // console.log("resolution", resolution)
            // var resolutionss = view.getResolutions();
            // console.log("resolutionss", resolutionss)
          });

          function arraysAreIdentical(arr1, arr2) {
            if (arr1.length !== arr2.length) return false;
            for (var i = 0, len = arr1.length; i < len; i++) {
              if (JSON.stringify(arr1[i]).includes(JSON.stringify(arr2[i]))) {
                return true;
              }
            }
            return false;
          }

          initialMap.on("pointermove", function (event) {
            //show area of particular feature on hover on it functionality.
            if (draw && draw.getActive()) return;

            let selected, tooltipCoord;
            let hit = initialMap.forEachFeatureAtPixel(event.pixel, function (f) {
              selected = f;
              return true;
            });

            if (hit) {
              createMeasureTooltip();
              createHelpTooltip();

              this.getTargetElement().style.cursor = "pointer";
              let currentFeature = selected.getGeometry().getCoordinates();

              if (selected.getGeometry().getType() === "Polygon") {
                tooltipCoord = selected.getGeometry().getInteriorPoint().getCoordinates();
              }
              if (selected.getGeometry().getType() === "LineString") {
                tooltipCoord = selected.getGeometry().getLastCoordinate();
              }

              // console.log("ctooltipCoord on hover", tooltipCoord)
              if (featurearr.length > 0) {
                let measureResult = featurearr.filter((el) => {
                  let arr1 = el.coordinates["coordinates"];
                  let arr2 = currentFeature;
                  if (arraysAreIdentical(arr1, arr2)) {
                    return el;
                  }
                  // return el
                });
                if (measureResult.length > 0) {
                  if (measureTooltipElement) {
                    measureTooltipElement.innerHTML = measureResult[0]["coordinates"]["areacoords"];
                    measureTooltip.setPosition(tooltipCoord); //this is for to set tooltip to show in UI

                    measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
                    measureTooltip.setOffset([0, -7]);
                    unByKey(listener);
                  }
                }
              }
            } else {
              this.getTargetElement().style.cursor = "";
              if (measureTooltipElement) {
                measureTooltipElement = null;
                let elements = document.getElementsByClassName("ol-tooltip ol-tooltip-static");
                while (elements.length > 0) {
                  elements[0].parentNode.removeChild(elements[0]);
                }
              }
            }
          });

          initialMap.on("click", function (e) {
            // view particular feature on click functionality
            if (draw && draw.getActive()) return; //if pointr is in drawing mode then return
            setMode("edit");
            initialMap.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
              let currentFeature = feature.getGeometry().getCoordinates();
              console.log("currentfea", feature.getGeometry());
              if (featurearr.length > 0) {
                result = featurearr.filter((el) => {
                  let arr1 = el.coordinates["coordinates"];
                  let arr2 = currentFeature;
                  if (arraysAreIdentical(arr1, arr2)) {
                    return el;
                  }
                  // return el
                });

                if (result.length > 0) {
                  console.log("result", result[0]);
                  setpopupVisible("visible");
                  if (result[0].coordinates.type === "LineString") {
                    overlay.setPosition(result[0].coordinates["coordinates"][0]);
                  }
                  if (result[0].coordinates.type === "Polygon") {
                    overlay.setPosition(result[0].coordinates["coordinates"][0][0]);
                  }
                  annotation_id = result[0].annotation_id;
                  document.getElementById("commentbox").value = result[0]["comments"];
                  document.getElementById("commentbox").disabled = true;
                  document.getElementById("deletedraw").disabled = false;
                }
              }
            });
          });

          function drawingData() {
            //get DrawingList
            annotationList(slide_access_token).then((res) => {
              // debugger
              if (res && res.data.annotation_list && res.data.annotation_list.length > 0) {
                // console.log("res", res.data.annotation_list)
                res.data.annotation_list.forEach((el) => {
                  let d = JSON.parse(el.coordinates);
                  el.coordinates = d;
                  featurearr = [...res.data.annotation_list];
                });

                console.log("featurearr", featurearr);
                setAnnotations(featurearr); //here also setting in state so that other external componenet can also access it.
                renderFeatures(featurearr);
              }
              if (res && res.data.Message) {
                console.log(res.data.Message);
                if (typeSelect) {
                  addInteraction(selectedElement);
                }
              }
            });
          }

          //******************************************************Save Data to db start*************************************************************************************//
          button.addEventListener("click", function (event) {
            commentData = document.getElementById("commentbox").value;
            let obj = { anno_data: JSON.stringify(interactionData) };
            obj["comment"] = commentData;
            obj["slide_id"] = slide_id;
            obj["user_email"] = email;

            // console.log("finalDatatobesavedBefore", obj);

            saveAnnotation(obj, slide_access_token).then((res) => {
              if (res && res.data.Message) {
                coordinates[0].map((el) => {
                  return overlay.setPosition(undefined);
                });
                interactionData = undefined;
                coordinates.length = 0;
                clearCommentInput();
                // removeFeatures();
                NotificationManager.success(res.data.Message, "", 2000);
              }
            });
          });
          //******************************************************Save Data to db end*************************************************************************************//

          let dynamicResolutionBtns = document.getElementById("dynamicResolution");

          if (dynamicResolutionBtns) {
            let resScale = scaleData / 5.275;
            var Resdict = {
              "80X": 1 * resScale,
              "40X": 1.7855350008023005 * resScale,
              "20X": 2.231796258402592 * resScale,
              "10X": 4.523187969088357 * resScale,
              "4X": 8.342052841421221 * resScale,
              fit: 106.34897959183674 * resScale,
            };

            dynamicResolutionBtns.addEventListener("click", (event) => {
              let resolutionVal = event.target.value;
              initialMap.getView().setResolution(Resdict[resolutionVal].toString());

              // switch (resolutionVal) {
              //     case '80X':
              //         initialMap.getView().setResolution('1')
              //         break;
              //     case '40X':
              //         initialMap.getView().setResolution('1.7855350008023005')
              //         break;
              //     case '20X':
              //         initialMap.getView().setResolution('2.231796258402592')
              //         break;
              //     case '10X':
              //         initialMap.getView().setResolution('4.523187969088357')
              //         break;
              //     case '4X':
              //         initialMap.getView().setResolution('8.342052841421221')
              //         break;
              //     case 'fit':
              //         initialMap.getView().setResolution('106.34897959183674')
              //         break;
              //     default:
              //     // code block
              // }
            });
          }
          // *******************************************************Initialize Map End End*****************************************************************************************//

          function removeFeatures() {
            var features = vector_layer.getSource().getFeatures();

            if (features.length > 0) {
              features.forEach((el) => {
                return vector_layer.getSource().removeFeature(el);
              });
            }
          }

          function clearCommentInput() {
            document.getElementById("commentbox").value = "";
            setpopupVisible("hidden");
            setisCommentBtnDisable(true);
            drawingData();

            if (typeSelect) {
              addInteraction(selectedElement);
            }
          }

          function renderFeatures(drawcoords) {
            // console.log("i called")
            if (drawcoords.length > 0) {
              drawcoords.map((el) => {
                let feature;
                if (el.coordinates.type === "Polygon") {
                  feature = new Feature({
                    geometry: new Polygon([el.coordinates.coordinates[0]]),
                  });
                }
                if (el.coordinates.type === "LineString") {
                  feature = new Feature({
                    geometry: new LineString(el.coordinates.coordinates),
                  });
                }
                return vector_layer.getSource().addFeature(feature);
              });
            }
          }

          cancelPopup.addEventListener("click", () => {
            // cancel popup btn
            clearCommentInput();
            removeFeatures();
            if (result && result[0].coordinates.type === "LineString") {
              result[0].coordinates["coordinates"][0].map((el) => {
                return overlay.setPosition(undefined);
              });
            }
            if (result && result[0].coordinates.type === "Polygon") {
              result[0].coordinates["coordinates"][0][0].map((el) => {
                return overlay.setPosition(undefined);
              });
            }
          });

          deletedraw.addEventListener("click", (event) => {
            // delete particular feature
            if (window.confirm("Sure want to delete?")) {
              console.log("event", event);
              if (featurearr.length > 0) {
                let obj = {
                  annotation_id: annotation_id,
                };
                deleteAnnotation(obj, slide_access_token).then((res) => {
                  if (res && res.data.Message) {
                    NotificationManager.success(res.data.Message, "", 2000);
                    removeFeatures();
                    clearCommentInput();
                    if (measureTooltipElement) {
                      measureTooltipElement = null;
                      let elements = document.getElementsByClassName("ol-tooltip ol-tooltip-static");
                      while (elements.length > 0) {
                        elements[0].parentNode.removeChild(elements[0]);
                      }
                    }
                  }
                });
              }
            }
          });

          drawingData(); //load features on map onload

          locatefeature.addEventListener("click", (event) => {
            let selectedAnnoId = event.target.parentElement.id;
            var features = vector_layer.getSource().getFeatures();

            //step:1 fist filtered the coodinates by matching annotationid from featurearr
            let selectedFeatureCoord = featurearr && featurearr.length > 0 && featurearr.filter((el) => el.annotation_id === selectedAnnoId);

            //step:2 then filtered the final coordinates from build-in features array list and matched coordinates and got selected built-in feature obj
            let selectedFeatureResult = features.filter((el) => {
              if (arraysAreIdentical(el.getGeometry().getCoordinates(), selectedFeatureCoord[0].coordinates["coordinates"])) {
                return el;
              }
              // return el
            });

            console.log("selected Feature", selectedFeatureResult[0]);
            //step:3 using fit method locate particulat feature at location
            view.fit(selectedFeatureResult[0].getGeometry(), { size: initialMap.getSize(), duration: 1000 });
          });
        }
      })
      .catch((e) => {
        NotificationManager.error("Error!", "", 2000);
      });
  };

  useEffect(() => {
    if (slide_id) {
      groupSlideToken(slide_id)
        .then((res) => {
          if (res && res.data && res.data.slide_token) {
            let slide_access_token = res.data.slide_token;
            let email = res.data.assigned_to;

            // setState({ ...state, slideInfo: {...state.slideInfo, slideName: res.data.slide_name, createdDate: res.data.unixtime, slideToken: res.data.slide_token } })
            let scaleData = "";
            getScaleData(slide_id, res.data.slide_token)
              .then((Res) => {
                if (Res && Res.data && Res.data.scale) {
                  setState({
                    ...state,
                    scaleValue: Res.data.scale,
                    disableTools: false,
                    slideInfo: {
                      ...state.slideInfo,
                      slideName: res.data.slide_name,
                      createdDate: res.data.unixtime,
                      slideToken: res.data.slide_token,
                      assigned_to: res.data.assigned_to,
                    },
                  });
                  scaleData = res.data.scale;
                  prepareSlideViewerData(slide_id, slide_access_token, scaleData, email);
                }
              })
              .catch((err) => {
                console.log(err);
                setState({ ...state, disableTools: true });
                prepareSlideViewerData(slide_id, slide_access_token, scaleData, email);
              });
          }
        })
        .catch((e) => {
          NotificationManager.error("Not Authrized!!", "", 2000);
        });
    }
  }, []);

  const getFormatedDate = () => {
    if (state.slideInfo.createdDate) {
      // console.log("params.data.unixtime", pageState.slideInfo.createdDate)
      let date_val = new Date(parseInt(state.slideInfo.createdDate) * 1000);
      let humanDateFormat = date_val.toLocaleString("en-AU", { timeZone: "Asia/Kolkata" });
      return humanDateFormat;
    } else {
      return "";
    }
  };

  return (
    <div className="container-fluid">
      <NotificationContainer />
      <PageTitle
        title={state && state.slideInfo.slideName}
        backLink="/groups"
      ></PageTitle>

      <br />
      <div className="row justify-content-between">
        <div className="col-md-6 d-flex justify-content-end align-items-center mb-2">
          <OverViewComponent
            imageElement={imageElement}
            slide_id={slide_id}
            stateVal={{ state, setState }}
          />
          <DisplayPdfFromCloud
            imageElement={imageElement}
            stateVal={{ state, setState }}
          />
          <a
            href={`https://binilj04.github.io/pdf-from-images-react-app/`}
            target="_blank"
            rel="noreferrer"
          >
            <i
              className="fa fa-file-pdf-o fa-2x ml-4"
              aria-hidden="true"
            ></i>
          </a>
        </div>

        <div className="col-md-6">
          <div style={{ textAlign: "right" }}>
            <b>Created Date:</b>
            {getFormatedDate()}
          </div>
        </div>
      </div>

      <div className="row">
        <div
          ref={mapElement}
          className="map-container"
        >
          <div
            id="popup"
            className="ol-popup"
            style={{ visibility: popupVisible }}
          >
            <div
              class="card"
              style={{ width: "376px" }}
            >
              <div class="card-body">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    value={state.comment}
                    id="commentbox"
                    onChange={(event) => {
                      if (event.target.value.length > 0) {
                        setisCommentBtnDisable(false);
                        setState({ ...state, comment: event.target.value });
                      } else {
                        setisCommentBtnDisable(true);
                        setState({ ...state, comment: "" });
                      }
                    }}
                    rows="3"
                    placeholder="Write a comment..."
                  ></textarea>
                </div>
                <button
                  type="button"
                  style={{ float: "right" }}
                  className="btn btn-info btn-sm"
                  id="annotationdata"
                  disabled={isCommentBtnDisable}
                >
                  save
                </button>
                <button
                  type="button"
                  style={{ float: "right" }}
                  className="btn btn-secondary btn-sm"
                  id="cancelBtn"
                >
                  cancel
                </button>
                <button
                  type="button"
                  style={{ float: "left" }}
                  className="btn btn-danger btn-sm"
                  id="deletedraw"
                >
                  delete
                </button>
              </div>
            </div>
          </div>
          <DrawFeaturesComponent
            selectedElement={selectedElement}
            stateVal={{ state, setState }}
          />
          <ZoomLevelsComponent
            zoomLevelElement={zoomLevelElement}
            stateVal={{ state, setState }}
          />
          <ScreenshotSlideComponent stateVal={{ state, setState }} />
          <AnnotationList
            annotationElement={annotationElement}
            stateVal={{ state, setState }}
            annotationDataList={{ annotations, setAnnotations }}
          />
        </div>
      </div>
    </div>
  );
};

const DisplayPdfFromCloud = (props) => {
  const { state, setState } = props.stateVal;

  const [modalVisible, set_modalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <a
        href="#!"
        style={{ float: "right" }}
        onClick={(e) => {
          set_modalVisible(true);
          setLoading(true);

          // console.log("state.slideInfo.slideToken", state.slideInfo.slideToken);
          getPdfFromCloud(state.slideInfo.slideToken)
            .then((res) => {
              if (res && res.data) {
                console.log("data pdf:", res.data);
                let blob = new Blob([res.data], { type: "application/pdf" });
                let blobURL = URL.createObjectURL(blob);

                setState({ ...state, wsImage: blobURL });
                setLoading(false);
              } else if (res && res.data.Message) {
                NotificationManager.error(res.data.Message, "", 2000);
                set_modalVisible(false);
                setLoading(false);
              }
            })
            .catch((err) => {
              NotificationManager.error(err.message, "", 3000);
            });
        }}
      >
        <img
          src={window.location.origin + "/fileImage.png"}
          width="30px"
          className="ml-4 mr-2"
          alt=""
        />
      </a>

      <Modal
        show={modalVisible}
        onHide={() => set_modalVisible(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Pdf From Cloud</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Loader
                  type="Rings"
                  color="#00BFFF"
                  height={100}
                  width={100}
                />
              </div>
            ) : (
              <iframe
                style={{ width: "100%", height: "88vh" }}
                src={state.wsImage}
                type="application/pdf"
                title="Pdf From Cloud"
              />
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const OverViewComponent = (props) => {
  const [modalVisible, set_modalVisible] = useState(false);

  const { state, setState } = props.stateVal;

  return (
    <>
      <a
        href="#!"
        style={{ float: "right" }}
        onClick={(e) => {
          console.log("OverViewComponent_slideToken", state.slideInfo.slideToken);
          getannotated(state.slideInfo.slideToken, props.slide_id).then((res) => {
            if (res && res.data && res.data.byteLength > 0) {
              let blob = new Blob([res.data], { type: res.headers["content-type"] });
              let blobURL = URL.createObjectURL(blob);

              setState({ ...state, wsImage: blobURL });
              set_modalVisible(true);
              new Cropper(props.imageElement.current, {
                viewMode: 0,
                dragMode: "move",
                zoomable: true,
                background: true,
                autoCrop: false,
                toggleDragModeOnDblclick: false,
              });
            }
            if (res && res.data.Message) {
              NotificationManager.error(res.data.Message, "", 2000);
            }
          });
        }}
      >
        <h4>OverView</h4>
      </a>
      {state && state.wsImage && (
        <Modal
          show={modalVisible}
          onHide={() => set_modalVisible(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>WS Image</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Image
                  className="img-container"
                  src={state.wsImage}
                  alt=""
                  id="img"
                  ref={props.imageElement}
                />
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => set_modalVisible(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const DrawFeaturesComponent = (props) => {
  const { state } = props.stateVal;
  return (
    <div
      className=""
      style={{
        position: "absolute",
        right: "6px",
        top: "42px",
        height: "649px",
        width: "65px",
        display: "inline-block",
        backgroundColor: "transparent",
        zIndex: "10",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn btn-secondary"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={state.disableTools}
                style={{
                  cursor: state && state.disableTools ? "not-allowed" : "pointer",
                  backgroundColor: "rgba(0,60,136,0.5)",
                  color: "white",
                  padding: "0",
                  fontSize: "1.14em",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "1.375em",
                  width: "1.375em",
                  border: "none",
                  borderRadius: "2px",
                }}
              >
                <i
                  className="fa fa-paint-brush"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Measurement Type"
                ></i>
              </button>
              {/* top css not working on menu */}
              <div
                className="dropdown-menu"
                id="selectedElement"
                ref={props.selectedElement}
                style={{ lineHeight: "15px", fontSize: "13px" }}
              >
                <a
                  className="dropdown-item"
                  href="#!"
                  id="length"
                >
                  Length (LineString)
                </a>
                <a
                  className="dropdown-item"
                  href="#!"
                  id="area"
                >
                  Area (Polygon)
                </a>
                <a
                  className="dropdown-item"
                  href="#!"
                  id="none"
                >
                  None
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ZoomLevelsComponent = (props) => {
  const { state } = props.stateVal;

  return (
    <div
      className=""
      style={{ position: "absolute", right: "16px", top: "140px", margin: "0px", zIndex: "30", fontSize: "18px" }}
    >
      <ButtonGroup
        vertical
        size="sm"
        id="dynamicResolution"
        style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
      >
        <Button
          variant="outline-secondary"
          value="80X"
          id="80X"
          style={{ cursor: state && state.disableTools ? "not-allowed" : "pointer" }}
          disabled={state.disableTools}
        >
          80X
        </Button>
        <Button
          variant="outline-secondary"
          value="40X"
          id="40X"
          style={{ cursor: state && state.disableTools ? "not-allowed" : "pointer" }}
          disabled={state.disableTools}
        >
          {" "}
          40X
        </Button>
        <Button
          variant="outline-secondary"
          value="20X"
          id="20X"
          style={{ cursor: state && state.disableTools ? "not-allowed" : "pointer" }}
          disabled={state.disableTools}
        >
          20X
        </Button>
        <Button
          variant="outline-secondary"
          value="10X"
          id="10X"
          style={{ cursor: state && state.disableTools ? "not-allowed" : "pointer" }}
          disabled={state.disableTools}
        >
          10X
        </Button>
        <Button
          variant="outline-secondary"
          value="4X"
          id="4X"
          style={{ cursor: state && state.disableTools ? "not-allowed" : "pointer" }}
          disabled={state.disableTools}
        >
          4X
        </Button>
        <Button
          variant="outline-secondary"
          value="fit"
          id="fit"
          style={{ cursor: state && state.disableTools ? "not-allowed" : "pointer" }}
          disabled={state.disableTools}
        >
          Fit
        </Button>
      </ButtonGroup>
    </div>
  );
};

const ScreenshotSlideComponent = (props) => {
  const { state } = props.stateVal;

  return (
    <div
      className=""
      style={{
        position: "absolute",
        right: "6px",
        top: "74px",
        height: "649px",
        width: "65px",
        display: "inline-block",
        backgroundColor: "transparent",
        zIndex: "10",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn btn-secondary"
                id="export-png"
                disabled={state.disableTools}
                style={{
                  cursor: state && state.disableTools ? "not-allowed" : "pointer",
                  backgroundColor: "rgba(0,60,136,0.5)",
                  color: "white",
                  padding: "0",
                  fontSize: "1.14em",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "1.375em",
                  width: "1.375em",
                  border: "none",
                  borderRadius: "2px",
                }}
              >
                <i
                  className="fa fa-arrow-circle-o-down"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Download current view"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AnnotationList = (props) => {
  // const { state, setState } = props.stateVal;
  const { annotations } = props.annotationDataList;
  return (
    <div
      className=""
      style={{ position: "absolute", right: "5px", top: "106px", width: "68px", display: "inline-block", backgroundColor: "transparent", zIndex: "10" }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn btn-secondary"
                data-toggle="dropdown"
                id="annotationslist"
                style={{
                  cursor: "pointer",
                  backgroundColor: "rgba(0,60,136,0.5)",
                  color: "white",
                  padding: "0",
                  fontSize: "1.14em",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "1.375em",
                  width: "1.375em",
                  border: "none",
                  borderRadius: "2px",
                }}
              >
                <i
                  className="fa fa-bars"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Annotations"
                ></i>
              </button>

              <div
                className=" dropdown-menu"
                id="commentlist"
                ref={props.annotationElement}
                style={{ lineHeight: "15px", fontSize: "13px" }}
              >
                <h5 style={{ textAlign: "center" }}>
                  <b>Annotations {annotations.length}</b>
                </h5>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="search..."
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => alert("test")}
                    >
                      <i
                        class="fa fa-search"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
                <ul
                  className="list-group"
                  style={{ overflowY: "scroll", height: "400px", width: "300px" }}
                  id="locatefeature"
                >
                  {annotations &&
                    annotations.length > 0 &&
                    annotations.map((el) => {
                      return (
                        <li
                          className="list-group-item dropdown-item"
                          key={el.annotation_id}
                          id={el.annotation_id}
                        >
                          <a href="#!">{el.comments}</a>
                          {/* <a href="#!"><i className="fa fa-trash-o" aria-hidden="true" style={{ float: 'right' }}></i></a> */}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
