import React, { useEffect, useContext, useState } from "react";
import { PageContext } from "../Settings";
import { getDeviceConfigData, getDeviceNameOfConfig } from "../Service";
import ReactJsonPrettify from "react-json-prettify";
import { HiRefresh } from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa";

const ConfigurationData = () => {
  const { pageState } = useContext(PageContext);
  const [deviceNames, setDeviceNames] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [configData, setConfigData] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState(null);

  useEffect(() => {
    async function fetchDeviceNames() {
      try {
        const response = await getDeviceNameOfConfig();
        setDeviceNames(response.data["device_names"]);
      } catch (error) {
        console.error("Error fetching device names", error);
      }
    }
    fetchDeviceNames();
  }, [pageState.configDataToggle]);

  useEffect(() => {
    fetchConfigData();
  }, [selectedDevice]);

  const refreshConfigFileNames = () => {
    fetchConfigData();
  };

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
    setSelectedConfig(null);
  };

  const fetchConfigData = async () => {
    let obj = { device_name: selectedDevice };
    try {
      const response = await getDeviceConfigData(obj);
      const sortedConfigData = response.data.config_data.sort((a, b) => b.date_time - a.date_time);
      setConfigData(sortedConfigData);
    } catch (error) {
      console.error(`Error fetching config data for ${selectedDevice}:`, error);
    }
  };

  const handleConfigClick = (config) => {
    setSelectedConfig(config);
  };

  const handleCopyClick = () => {
    if (selectedConfig) {
      try {
        const parsedData = JSON.parse(selectedConfig.file_data);
        const jsonString = JSON.stringify(parsedData, null, 2);
        navigator.clipboard.writeText(jsonString);
        alert("JSON data copied to clipboard!");
      } catch (error) {
        console.error("Error parsing JSON:", error);

        // If parsing fails, display raw JSON data in a formatted text area
        navigator.clipboard.writeText(selectedConfig.file_data);
        alert("Raw JSON data copied to clipboard!");
      }
    }
  };

  return (
    <div >
      <label>Select Device:</label>
      <select
        onChange={handleDeviceChange}
        value={selectedDevice}
      >
        <option
          value=""
          disabled
        >
          Select a device
        </option>
        {deviceNames.map((device) => (
          <option
            key={device}
            value={device}
          >
            {device}
          </option>
        ))}
      </select>
      {selectedDevice && (
        <div className="d-flex">
        <div className="config-table">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>
                  File Name{" "}
                  <span>
                    <button
                      style={{ border: "none", background: "none", paddingLeft: "10px" }}
                      onClick={() => refreshConfigFileNames()}
                      title="Refresh"
                    >
                      <HiRefresh style={{ color: "#0275d8" }} />
                    </button>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {configData.map((config) => (
                <tr key={config.date_time}>
                  <td>{new Date(config.date_time * 1000).toLocaleString()}</td>
                  <td className="file-name">
                    <span onClick={() => handleConfigClick(config)}>{config.file_name}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>

          {selectedConfig && (
            <div className="mx-auto" >
              <div className="d-flex justify-content-between">
              <h3>{selectedConfig.file_name}:</h3>
                <button style={{ border: "none", background: "none", paddingLeft: "10px",fontSize: "14px" }} onClick={handleCopyClick}><span><FaRegCopy style={{ marginRight: "5px" }} /></span>Copy JSON</button>
              </div>
              {(() => {
                try {
                  return (
                    <div className="json-container  mx-auto">
                    <ReactJsonPrettify
                      json={JSON.parse(selectedConfig.file_data)}
                      indentation={2}
                      
                    />
                    </div>
                  );
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                  // If parsing fails, display raw JSON data in text format
                  return (
                    <textarea
                      value={selectedConfig.file_data}
                      readOnly
                      style={{ whiteSpace: "pre-wrap", overflow: "auto", height: "400px", width: "400px" }}
                    />
                  );
                }
              })()}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ConfigurationData;
