import './App.css';
import { Route, Switch, BrowserRouter as Router } from "react-router-dom"
import { useHistory } from 'react-router-dom'
import { Navigation } from "./components/shared/Navigation";
import { Settings } from "./components/Settings/Settings";
import React from 'react'
import { PrivateRoute } from "./components/Utils/PrivateRoute";
import { PublicRoute } from "./components/Utils/PublicRoute";
import { Account } from "./components/Auth/Account";
import { ActiveDevices } from "./components/ActiveDevices/ActiveDevices";
import { LiveView } from "./components/ActiveDevices/View/LiveView";
import { Slides } from "./components/Slides/List/Slides";
import { SlideView } from "./components/Slides/View/SlideView";
import { Groups } from "./components/Groups/Groups";
import { GroupsNav } from './components/Groups/Components/GroupsNav';
import { ViewSlide } from "./components/Groups/Components/ViewSlide";

function App() {
  const history = useHistory();
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <PublicRoute path="/Login" component={Account} />
          <Route component={DefaultContainer} />
        </Switch>
      </Router>
    </div>
  );
}

const DefaultContainer = () => {
  let display = true
  if (window.location.pathname.includes('/liveview')) {
    display = false
  }

  return (
    <div>
      {
        display && <Navigation />
      }
      <div>
        <PrivateRoute path="/slides" component={Slides} />
        <PrivateRoute path="/slideview/:id" component={SlideView} />
        <PrivateRoute path="/settings" component={Settings} />
        <PrivateRoute path="/activedevices" component={ActiveDevices} />
        {/* <PrivateRoute exact path="/liveview/:roomid" component={LiveView} /> */}
        <PrivateRoute exact path="/liveview" component={LiveView} />
        <PrivateRoute path="/groups" component={Groups} />
        <PrivateRoute path="/groupsv/:groupid" component={GroupsNav} />
        <PrivateRoute path="/groupslideview/:id" component={ViewSlide} />

      </div>
    </div>
  )

}


export default App;
