import { useContext, useEffect } from "react";
import { PageContext } from "../../LiveView";
import MapContext from "../Map/MapContext";
import { Vector as VectorSource } from "ol/source";
import { NotificationContainer } from "react-notifications";
import { Vector as OLVectorLayer } from "ol/layer";
import { Point } from "ol/geom";
import Feature from "ol/Feature";

const VectorLayer = () => {
  const { map, image, PostionCoords } = useContext(MapContext);
  const stateContext = useContext(PageContext);

  useEffect(() => {
    if (!map) return;

    var source1 = new VectorSource({
      wrapX: false,
      // source,
    });
    let vector = new OLVectorLayer({
      source: source1,
    });

    var feature = new Feature({
      geometry: new Point([PostionCoords[0]["px-x"], image.height - PostionCoords[0]["px-y"]]),
    });

    vector.getSource().clear();
    vector.getSource().addFeature(feature);

    map.on("click", function (evt) {
      let coords = evt.coordinate;
      if (coords.length && 0 < coords[0] && coords[0] < image.width && 0 < coords[1] && coords[1] < image.height) {
        let invertedYValue = image.height - coords[1];
        stateContext && stateContext.pageState.sendWSImageCoords(coords[0], invertedYValue);
      }
    });
    map.addLayer(vector);
    return () => {
      if (map) {
        map.removeLayer(vector);
      }
    };
  }, [PostionCoords[0]["px-x"], PostionCoords[0]["px-y"]]);
  // PostionCoords[0]["px-x"], PostionCoords[0]["px-y"]
  return null;
};
export default VectorLayer;
