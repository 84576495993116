import { useEffect, useState } from "react"
import { getDiskSpaceData } from "../Service";
import ProgressBar from 'react-bootstrap/ProgressBar';

export const DiskSpace = () => {

    const initialState = {
        current_size: null,
        free_space: null,
        max_size: null,
        progressVal: 0
    }

    const [diskSpaceData, setDiskSpaceData] = useState(initialState)

    function formatMBs(a, decimals = 2) {
        let bytes = a;

        if (bytes === 0) return '0 GB';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['MB', 'GB', 'TB', 'PB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        let result = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
        // console.log("result", Math.round(result)*10)
        return result + ' ' + sizes[i];
        // return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    useEffect(() => {
        getDiskSpaceData().then(res => {
            if (res && res.data) {
                let total = formatMBs(res.data.max_size)
                let used = formatMBs(res.data.current_size)
                let free = formatMBs(res.data.free_space)

                setDiskSpaceData({ ...diskSpaceData, current_size: used, free_space: free, max_size: total, progressVal:used && Math.round(used.slice(0, -3)) * 10 })
            }
        })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="container">
            {
                diskSpaceData &&
                <div>
                    <br />
                    <h2 style={{ textAlign: 'left' }}>Total - {diskSpaceData.max_size}</h2>
                    <ProgressBar now={diskSpaceData.progressVal} style={{ height: '20px' }} />
                    <div className="row justify-content-between">
                        <div className="col-4">
                            <h5 style={{ textAlign: 'left', color: 'gray' }}>{diskSpaceData.current_size} used</h5>
                        </div>
                        <div className="col-4">
                            <h5 style={{ textAlign: 'right', color: 'gray' }}>{diskSpaceData.free_space} free</h5>

                        </div>
                    </div>
                </div>
            }

        </div>
    )
}
