import { createContext } from "react";
import axios from "axios";
import { environment } from "../../environment";
import { getToken } from "../Utils/Common";
const baseURL = environment.MICALYS_POSTGRES_API;
const zoomifyURL = environment.Micalys_Image_Server + "get_slide_img/";
const requestFormURL = environment.Micalys_Image_Server;

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_slideRecords":
      // console.log("nbdvdf", action.payload)
      state = {
        ...state,
        slideList: action.payload.slideList,
        totalRecords: action.payload.totalRecords,
        index: action.payload.index,
      };
      return state;

    // case 'set_taglist':
    //   state = {
    //     ...state, taglist: action.payload
    //   }
    //   return state

    case "set_searchList":
      state = {
        ...state,
        searchList: { ...state.searchList, taglist: action.payload.taglist, slidenamelist: action.payload.slidenamelist },
      };
      return state;

    case "set_tagObj":
      state = {
        ...state,
        tagObj: action.payload,
      };
      return state;

    case "set_updateIndex":
      state = {
        ...state,
        index: action.payload,
      };
      return state;

    case "set_modalVisible":
      return {
        ...state,
        modalVisible: action.payload,
      };
    case "set_slidenameTitle":
      return {
        ...state,
        SlidenameTitle: action.payload,
      };
    case "set_slideInfo":
      state = {
        ...state,
        slideInfo: {
          ...state.slideInfo,
          slideName: action.payload.slide_name,
          createdDate: action.payload.unixtime,
          slideToken: action.payload.slide_token,
        },
      };
      return state;

    case "set_disableTools":
      return {
        ...state,
        disableTools: action.payload,
      };

    case "set_wsImage":
      return {
        ...state,
        wsImage: action.payload,
      };

    case "set_setMyJoinedGroups":
      return {
        ...state,
        myJoinedGroups: action.payload.group_list,
        userid: action.payload.user_id,
      };
    default:
      return state;
  }
};

export const getSlideRecords = (index, number_of_items, pageDispatcher, searchparams) => {
  let obj = {
    number_of_items: number_of_items,
    index: index,
  };

  if (searchparams) {
    Object.assign(obj, searchparams);
    // console.log("obj",obj)
  }
  console.log("obj", obj);
  axios.post(baseURL + "getSlideList", obj, { headers: { Authorization: `Bearer ${getToken()}` } }).then((res) => {
    if (res && res.data) {
      // console.log("res",res.data)
      res.data.slideList.forEach((tagsArr) => {
        if (tagsArr.tagsarr !== null) {
          let d = JSON.parse(tagsArr.tagsarr);
          tagsArr.tagsarr = d;
          return;
        }
      });
      pageDispatcher.setSlideRecords(res.data);
    }
  });
};

export const updateSlidename = (obj) => {
  return axios.post(baseURL + "update_slidename", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const deleteSlide = (obj, slide_token) => {
  return axios.post(baseURL + "flag_file_to_delete", obj, { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const getSearchList = (pageDispatcher) => {
  axios.get(baseURL + "get_searchList", { headers: { Authorization: `Bearer ${getToken()}` } }).then((res) => {
    if (res && res.data) {
      pageDispatcher.setSearchList(res.data);
    }
  });
};

export const save_updateTags = (obj) => {
  return axios.post(baseURL + "save_update_tags", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getGeneratedSignedURL = (data) => {
  return axios.post(baseURL + "getSignedURL", data, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getSignedURLAfterInit = (slideId, type) => {
  let obj = {
    slide_id: slideId,
    action: "PUT",
    filetype: type,
  };
  return axios.post(baseURL + "get_signedurl_after_slide_init", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getSlideToken = (slide_id) => {
  var data = {
    slide_id: slide_id,
  };
  return axios.post(baseURL + "get_slidetoken", data, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getSlideViewerData = (slide_id, slide_token) => {
  return axios.get(zoomifyURL + slide_id + "/ImageProperties.xml", { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const getScaleData = (slide_id, slide_token) => {
  return axios.get(zoomifyURL + slide_id + "/scale.json", { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const getannotated = (slide_token, slide_id) => {
  return axios.get(zoomifyURL + slide_id + "/ws-annotated.jpg", { headers: { Authorization: `Bearer ${slide_token}` }, responseType: "arraybuffer" });
};
export const getFormUrl = (slide_token) => {
  return axios.get(requestFormURL + "get_request_form_url", { headers: { Authorization: `Bearer ${slide_token}` } });
};
export const getPdfFromCloud = (slide_token) => {
  return axios.get(requestFormURL + `get_requestform?${Date.now()}`, { headers: { Authorization: `Bearer ${slide_token}` }, responseType: "arraybuffer" });
};

export const addupdatescale = (obj, slide_token) => {
  return axios.post(baseURL + "set_slide_scale", obj, { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const myGroups = (pageDispatcher) => {
  axios.get(baseURL + "getMyGroups", { headers: { Authorization: `Bearer ${getToken()}` } }).then((res) => {
    if (res && res.data.group_list) {
      pageDispatcher.setMyJoinedGroups(res.data);
    }
  });
};

export const shareSlidetoGroup = (obj) => {
  return axios.post(baseURL + "add_slide_to_group", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const PageContext = createContext(null);
