import React, { useState, useContext } from "react";
import { PageContext } from "../LiveView";

export const Commands = (props) => {
  const context = useContext(PageContext);

  const { move_up_Ybtn, move_left_Xbtn, move_right_Xbtn, move_down_Ybtn, move_up_Zbtn, move_down_Zbtn } = props.movePropObj.buttonElementRefs;

  const { gotoXbtn, gotoYbtn, gotoZbtn } = props.gotoPropObj.buttonElementRefs;

  const { homeXbtn, homeYbtn, homeZbtn } = props.homePositionPropObj.buttonElementRefs;

  const { coarsebtn, finebtn } = props.focusPropObj.focusbuttonElementRefs;
  const { requestslidebtn, revokeslidebtn } = props.requestPropObj.requestbuttonElementRefs;

  const [gotoCoords, setgotoCoords] = useState({
    x: context.pageState.gotoCoords[0].x,
    y: context.pageState.gotoCoords[0].y,
    z: context.pageState.gotoCoords[0].z,
  });

  return (
    <>
      <div className="d-none">
        <div className="container">
          <div className="row my-5">
            {/* *************************************************************************Move X Y Z Remote**************************************************************************************************** */}

            <div
              id="wrapperDiv"
              style={{ border: "1px solid #91918c", backgroundColor: "#e7e7e7", borderRadius: "20px" }}
              className=" d-flex flex-row justify-content-around pr-5"
            >
              <div
                className="col-sm-7 col-md-7 col-lg-7 col-xl-3 mx-1 my-2"
                // style={{ border: '2px solid red' }}
              >
                {/* <h5>Move x,y-axis</h5> */}
                <div
                  className="circleBase"
                  id="rotateMode"
                >
                  <div className="top">
                    <button
                      id="up"
                      className="btn btn-default btn-sm"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="move y position up"
                      ref={move_up_Ybtn}
                      disabled={props.isDisabled}
                    >
                      <i
                        className="fa fa-arrow-circle-up fa-2x"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>

                  <div className="middle">
                    <button
                      id="left"
                      className="btn btn-default btn-sm"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="move x position left"
                      ref={move_left_Xbtn}
                      tabIndex={0}
                      disabled={props.isDisabled}
                    >
                      <i
                        className="fa fa-arrow-circle-left fa-2x"
                        aria-hidden="true"
                      ></i>
                    </button>

                    <button
                      id="right"
                      className="btn btn-default btn-sm"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="move x position right"
                      ref={move_right_Xbtn}
                      disabled={props.isDisabled}
                    >
                      <i
                        className="fa fa-arrow-circle-right fa-2x"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>

                  <div className="bottom">
                    <button
                      id="down"
                      className="btn btn-default btn-sm"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="move y position down"
                      ref={move_down_Ybtn}
                      disabled={props.isDisabled}
                    >
                      <i
                        className="fa fa-arrow-circle-down fa-2x"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-4 col-md-4 col-lg-4 col-xl-3 mx-1 my-2"
                // style={{ border: '2px solid blue' }}
              >
                {/* <h5>Move z-axis</h5> */}
                <div
                  className=""
                  id="rotateMode1"
                >
                  <div className="top">
                    <button
                      id="z-up"
                      className="btn btn-default btn-sm"
                      data-toggle="tooltip"
                      data-placement="up"
                      title="move z position up"
                      ref={move_up_Zbtn}
                      disabled={props.isDisabled}
                    >
                      <i
                        className="fa fa-arrow-circle-up fa-2x"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>

                  <div className="bottom">
                    <button
                      id="z-down"
                      className="btn btn-default btn-sm"
                      data-toggle="tooltip"
                      data-placement="doen"
                      title="move z position down"
                      ref={move_down_Zbtn}
                      disabled={props.isDisabled}
                    >
                      <i
                        className="fa fa-arrow-circle-down fa-2x"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/***************************************************coarse and fine button****************** */}

            {/* *************************************************************************Goto X Y Z Remote**************************************************************************************************** */}
          </div>

          <div className="row my-5 d-none">
            <div className="docs-data">
              <span>Goto</span>

              <div className="input-group input-group-sm">
                <button
                  id="gotoX"
                  className="btn btn-primary btn-sm"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="goto x position"
                  ref={gotoXbtn}
                  disabled={props.isDisabled}
                >
                  X
                </button>

                <input
                  className="form-control"
                  type="number"
                  id="dataX"
                  placeholder="x"
                  ref={props.gotoPropObj.inputElementRefs.dataXInput}
                  disabled={props.isDisabled}
                  value={gotoCoords.x}
                  onChange={(event) => {
                    setgotoCoords({ ...gotoCoords, x: event.target.value });
                  }}
                />
              </div>

              <div className="input-group input-group-sm">
                <button
                  id="gotoY"
                  className="btn btn-primary btn-sm"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="goto y position"
                  ref={gotoYbtn}
                  disabled={props.isDisabled}
                >
                  Y
                </button>

                <input
                  className="form-control"
                  type="number"
                  id="dataY"
                  placeholder="y"
                  ref={props.gotoPropObj.inputElementRefs.dataYInput}
                  disabled={props.isDisabled}
                  value={gotoCoords.y}
                  onChange={(event) => setgotoCoords({ ...gotoCoords, y: event.target.value })}
                />
              </div>

              <div className="input-group input-group-sm">
                <button
                  id="gotoZ"
                  className="btn btn-primary btn-sm"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="goto z position"
                  ref={gotoZbtn}
                  disabled={props.isDisabled}
                >
                  Z
                </button>

                <input
                  className="form-control"
                  type="number"
                  id="dataZ"
                  placeholder="z"
                  ref={props.gotoPropObj.inputElementRefs.dataZInput}
                  disabled={props.isDisabled}
                  value={gotoCoords.z}
                  onChange={(event) => setgotoCoords({ ...gotoCoords, z: event.target.value })}
                />
              </div>
            </div>

            <div>
              <span>Home</span>

              <div className="row">
                <div className="col">
                  <button
                    id="gotoX"
                    className="btn btn-primary btn-sm"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="goto home x position"
                    ref={homeXbtn}
                    disabled={props.isDisabled}
                  >
                    X
                  </button>
                </div>
                <div className="col">
                  <button
                    id="gotoY"
                    className="btn btn-primary btn-sm"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="goto home y position"
                    ref={homeYbtn}
                    disabled={props.isDisabled}
                  >
                    Y
                  </button>
                </div>
                <div className="col">
                  <button
                    id="gotoZ"
                    className="btn btn-primary btn-sm"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="goto home z position"
                    ref={homeZbtn}
                    disabled={props.isDisabled}
                  >
                    Z
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-around mb-3 ">
        <div className=" col-xl-4  my-auto">
          <button
            type="button"
            className="btn btn-outline-primary  btn-sm "
            id="coarsebtn"
            ref={coarsebtn}
            disabled={props.isDisabled}
          >
            CoarseFocus
          </button>
        </div>

        <div className="col-xl-4 my-auto">
          <button
            type="button"
            className="btn btn-outline-primary  btn-sm "
            id="finebtn"
            ref={finebtn}
            disabled={props.isDisabled}
          >
            FineFocus
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-around mb-3 ">
        <div className="col-xl-4 my-auto">
          <button
            type="button"
            className="btn btn-outline-primary  btn-sm "
            id="requestslidebtn"
            ref={requestslidebtn}
            disabled={
              props.subState === "w" ||
              props.subState === "x" ||
              props.subState === "y" ||
              props.subState === "z" ||
              props.subState === "b" ||
              props.subState === "c" ||
              props.subState === "d" ||
              props.subState === "e" ||
              props.subState === "f" ||
              props.subState === "g"
                ? true
                : false
            }
          >
            RequestLoadSlide
          </button>
        </div>

        <div className="col-xl-4 my-auto">
          <button
            type="button"
            className="btn btn-outline-primary  btn-sm "
            id="revokeslidebtn"
            ref={revokeslidebtn}
            disabled={
              props.subState === "w" ||
              props.subState === "x" ||
              props.subState === "y" ||
              props.subState === "z" ||
              props.subState === "a" ||
              props.subState === "d" ||
              props.subState === "e" ||
              props.subState === "f" ||
              props.subState === "g"
                ? true
                : false
            }
          >
            RevokeRequest
          </button>
        </div>
      </div>
    </>
  );
};
