import React, { useContext, useRef, useState, useEffect } from "react";
import { PageContext } from "../List/Slides";
import { Button, Modal, Form, ProgressBar } from "react-bootstrap";
import { getGeneratedSignedURL, getSignedURLAfterInit, getSlideRecords, getSearchList } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";
import axios, { CancelToken, isCancel } from "axios";

export const AddUploadSlide = (props) => {
  const { pageState, pageDispatcher } = useContext(PageContext);

  // for cannot be empty validation
  const [AddSlideFormValidated, setAddSlideFormValidated] = useState(false);
  const [FileUploadFormValidated, setFileUploadFormValidated] = useState(false);

  // for setting files in state after selecting on onChange
  const [fileObj, setFileObj] = useState("");

  // for showing percentage of uploading files
  const [uploadPercent, setUploadPercent] = useState(0);

  // for rendering ui
  const [step, setStep] = useState(1);

  // for file input validation zip/tiff
  const [validateFileFormat, setValidateFileFormat] = useState(false);

  //for show entered slidename "xyz" is getting uploaded.
  const [slidename, set_slidename] = useState("");

  const AddSlideForm = useRef(null);
  const FileUploadForm = useRef(null);

  const initialState = {
    disableBtn: true,
    signedURL: "",
  };
  const [state, setState] = useState(initialState);

  const cancelFileUpload = useRef(null);

  const [disableSubmitAfterInit, setDisableSubmitAfterInit] = useState(true);

  useEffect(() => {
    if (props.uploadShow.showUploadComponent) {
      // console.log("isShow", props.uploadShow.showUploadComponent)
      setStep(1);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function uploadToCloud(signedURLData, msg) {
    // console.log(msg, signedURLData)

    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        // console.log("Dataloaded", loaded + " from " + "TotalData", total)
        let percent = Math.floor((loaded * 100) / total);
        // console.log(`${loaded}kb of ${total}kb | ${percent}%`)

        if (percent < 100) {
          setUploadPercent(percent);
        }
      },
      cancelToken: new CancelToken((cancel) => (cancelFileUpload.current = cancel)),
    };
    axios
      .put(signedURLData, fileObj, options)
      .then((res) => {
        if (res) {
          setUploadPercent(100);
          setTimeout(() => {
            setUploadPercent(0);
            NotificationManager.success("success!", "", 2000);
            pageDispatcher.set_modalVisible(false);
            getSlideRecords(pageState.index, pageState.number_of_items, pageDispatcher, "");
            getSearchList(pageDispatcher);
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        if (isCancel(err)) {
          NotificationManager.error(err.message, "", 3000);
        }
      });
  }

  const cancelUpload = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("user has canceled the file upload.");
    }
  };

  const handleNext = (event) => {
    event.preventDefault();
    const form = FileUploadForm.current;
    if (form.checkValidity()) {
      // alert("ok")
      if (step < 2) {
        setStep(step + 1);
      }
      if (props.uploadShow.showUploadComponent) {
        setStep(3);
      }
    } else {
      setFileUploadFormValidated(true);
    }
  };

  const handleOk = (event) => {
    event.preventDefault();
    const form = AddSlideForm.current;

    if (props && !props.uploadShow.showUploadComponent) {
      // for generateSignedURL
      if (form.checkValidity()) {
        // console.log("step", step)
        // if (step < 3) {
        //     setStep(step + 1);
        // }
        console.log("direct generate init");
        set_slidename(form["slidename"].value); //set slidename to showin ui
        let obj = {
          slide_name: form["slidename"].value,
          action: "PUT",
          filetype: fileObj["type"],
        };

        getGeneratedSignedURL(obj).then((res) => {
          // debugger
          if (res && res.data && res.data.message) {
            setStep(3);
            let signedURLData = res.data.message.replace(/\"/g, "");
            uploadToCloud(signedURLData, "Add");
          }
          if (res && res.data && res.data.error) {
            NotificationManager.error(res.data.error, "cannot upload slide", 3000);
            setTimeout(() => {
              pageDispatcher.set_modalVisible(false);
            }, 1000);
          }
        });
      } else {
        setAddSlideFormValidated(true);
      }
    }
    if (props && props.uploadShow.showUploadComponent) {
      //for generateSignedURL after init

      if (state.signedURL) {
        setStep(3);
        uploadToCloud(state.signedURL, "AfterInit");
      } else {
        setFileUploadFormValidated(true);
      }
    }
  };

  return (
    <div>
      <NotificationContainer />

      <Modal
        show={pageState.modalVisible}
        onHide={() => pageDispatcher.set_modalVisible(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add / Upload Slide</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            {
              {
                1: (
                  <FileUploadComponent
                    FileUploadFormValidated={FileUploadFormValidated}
                    FileUploadForm={FileUploadForm}
                    fileState={{ fileObj, setFileObj }}
                    uploadShow={props.uploadShow.showUploadComponent}
                    selectedRowRecord={props.selectedRowRecord}
                    stateData={{ state, setState }}
                    checkFileFormat={{ validateFileFormat, setValidateFileFormat }}
                    disableAfterInit={{ disableSubmitAfterInit, setDisableSubmitAfterInit }}
                  />
                ),
                2: (
                  <AddSlideFormComponent
                    AddSlideFormValidated={AddSlideFormValidated}
                    AddSlideForm={AddSlideForm}
                  />
                ),
                3: (
                  <ProgressComponent
                    uploadPercent={uploadPercent}
                    slideName={{ slidename, set_slidename }}
                  />
                ),
              }[step] //switch case
            }
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* ********************************Cancel Button Start***********************************/}
          {step === 3 ? (
            <Button
              variant="secondary"
              onClick={() => {
                if (window.confirm("Sure wants to cancel upload?")) {
                  cancelUpload();
                  pageDispatcher.set_modalVisible(false);
                  getSlideRecords(pageState.index, pageState.number_of_items, pageDispatcher, "");
                  getSearchList(pageDispatcher);
                }
              }}
            >
              Cancel Upload
            </Button>
          ) : (
            <Button
              variant="secondary"
              onClick={() => {
                pageDispatcher.set_modalVisible(false);
                getSlideRecords(pageState.index, pageState.number_of_items, pageDispatcher, "");
                getSearchList(pageDispatcher);
              }}
            >
              Close
            </Button>
          )}

          {/************************************** Cancel Button End *****************************/}

          {/* ********************************NEXT/Submit Button Start***********************************/}

          {step === 1 && !props.uploadShow.showUploadComponent && (
            <button
              className="btn btn-primary"
              disabled={state.disableBtn}
              onClick={(e) => handleNext(e)}
            >
              Next
            </button>
          )}

          {step === 2 && (
            <button
              className="btn btn-primary"
              disabled={state.disableBtn}
              onClick={(e) => handleOk(e)}
            >
              Submit
            </button>
          )}

          {step === 1 && props.uploadShow.showUploadComponent && (
            <button
              className="btn btn-primary"
              disabled={disableSubmitAfterInit}
              onClick={(e) => handleOk(e)}
            >
              Submit
            </button>
          )}

          {/* ********************************NEXT/Submit Button End***********************************/}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const AddSlideFormComponent = (props) => {
  return (
    <Form
      noValidate
      validated={props.AddSlideFormValidated}
      ref={props.AddSlideForm}
    >
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Slide Name:</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter slidename"
          name="slidename"
          required
        />
        <Form.Control.Feedback type="invalid">Slidename cannot be empty!</Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};
const FileUploadComponent = (props) => {
  const { state, setState } = props.stateData;
  const { validateFileFormat, setValidateFileFormat } = props.checkFileFormat;

  const { setDisableSubmitAfterInit } = props.disableAfterInit;
  return (
    <>
      {/* 
            <label><b>Slide Name:</b></label>bnvb
            <br /> */}
      <Form
        noValidate
        validated={props.FileUploadFormValidated}
        ref={props.FileUploadForm}
      >
        <Form.Group
          controlId="formFileSm"
          className="mb-3"
        >
          <Form.Label>Upload zip/tiff files only:</Form.Label>
          <Form.Control
            type="file"
            required
            // name="file"
            accept=".zip,.tiff"
            onChange={(e) => {
              setState({ ...state, disableBtn: false });
              setValidateFileFormat(false);
              // console.log("props.uploadShow on Change", props.uploadShow)
              if (
                (e.target.files[0].type && e.target.files[0].type === "application/zip") ||
                e.target.files[0].type === "image/tiff" ||
                e.target.files[0].type === "application/x-zip-compressed"
              ) {
                setValidateFileFormat(false);
                setDisableSubmitAfterInit(false);

                props.fileState.setFileObj(e.target.files[0]);
                if (props && props.uploadShow) {
                  setState({ ...state, disableBtn: false });

                  getSignedURLAfterInit(props.selectedRowRecord.slide_id, e.target.files[0].type).then((res) => {
                    if (res && res.data.message) {
                      let signedURLData = res.data.message.replace(/\"/g, "");
                      setState({ ...state, signedURL: signedURLData });
                    }
                  });
                }
              } else {
                setValidateFileFormat(true);
                setDisableSubmitAfterInit(true);
                setState({ ...state, disableBtn: true });
              }
            }}
          />
          {validateFileFormat ? <span style={{ color: "red" }}>Please Upload zip/tiff files only!</span> : <span></span>}
          <Form.Control.Feedback type="invalid">please select a file</Form.Control.Feedback>
        </Form.Group>
      </Form>
    </>
  );
};
const ProgressComponent = (props) => {
  const { slidename } = props.slideName;
  return (
    <div>
      <b>Slide {slidename} is Uploading...</b>
      <br />
      <br />
      {props.uploadPercent > 0 && (
        <ProgressBar
          now={props.uploadPercent}
          variant="success"
          animated
          label={`${props.uploadPercent}%`}
        />
      )}
    </div>
  );
};
// export const AddUploadSlidePageContext = createContext(null)
