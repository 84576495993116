
const prod = {
    
    REACT_APP_SERVER_TYPE: process.env.REACT_APP_SERVER_TYPE,
    MICALYS_POSTGRES_API: process.env.REACT_APP_SERVER_TYPE === 'PRODUCTION' ? 'https://api.micalys.com/' : 'https://dev-api.micalys.com/',
    // Micalys_Image_Server: process.env.REACT_APP_SERVER_TYPE === 'PRODUCTION' ? 'https://gettile.micalys.com/' : 'https://dev-gettile.micalys.com/'
    Micalys_Image_Server:'/'
};

const dev = {
    // MICALYS_POSTGRES_API: 'http://127.0.0.1/api/',
    // MICALYS_POSTGRES_API: 'http://127.0.0.1:8888/',
    // MICALYS_POSTGRES_API: 'https://dev-api.micalys.com/',
    MICALYS_POSTGRES_API: 'http://127.0.0.1:8877/',
    // MICALYS_POSTGRES_API: 'http://dev-mp.com:8877/',  // for windows testing
    // MICALYS_ClOUD_API: 'http://127.0.0.1:8899/',
    REACT_APP_SERVER_TYPE: process.env.REACT_APP_SERVER_TYPE,
    // Micalys_Image_Server: 'http://127.0.0.1:8899/'
    Micalys_Image_Server: 'http://127.0.0.1:8888/'

};

export const environment = process.env.NODE_ENV === 'development' ? dev : prod;