import axios from "axios";
import { environment } from "../../environment";
import { getToken, getGroupToken } from "../Utils/Common";
import { createContext } from "react";

const baseURL = environment.MICALYS_POSTGRES_API;
const zoomifyURL = environment.Micalys_Image_Server + "get_slide_img/";
const requestFormURL = environment.Micalys_Image_Server;

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "setGroupList":
      return {
        ...state,
        groupList: action.payload,
      };

    case "set_otherGroupData":
      return {
        ...state,
        groupname: action.payload.group_name,
        totalmembers: action.payload.total_members,
      };
    default:
      return state;
  }
};

export const myGroups = () => {
  return axios.get(baseURL + "getMyGroups", { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getGroupJWT = (group_id) => {
  return axios.get(baseURL + `get_group_token/${group_id}`, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getSlides = (index, number_of_items, searchparam) => {
  let obj = {
    number_of_items: number_of_items,
    index: index,
  };
  if (searchparam && searchparam["slide_name"] && searchparam["slide_name"] !== "") {
    obj["slide_name"] = searchparam.slide_name;
  }
  if (searchparam && searchparam["showmyslides"] && searchparam["showmyslides"] === true) {
    obj["search_useremail"] = localStorage.getItem("email");
  }

  console.log("final obj", obj);

  return axios.post(baseURL + "get_group_slide_data", obj, { headers: { Authorization: `Bearer ${getGroupToken()}` } });
};

export const groupSlideToken = (slide_id) => {
  var data = {
    slide_id: slide_id,
  };
  return axios.post(baseURL + "get_group_slidetoken", data, { headers: { Authorization: `Bearer ${getGroupToken()}` } });
};

export const getScaleData = (slide_id, slide_token) => {
  return axios.get(zoomifyURL + slide_id + "/scale.json", { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const getSlideViewerData = (slide_id, slide_token) => {
  return axios.get(zoomifyURL + slide_id + "/ImageProperties.xml", { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const getannotated = (slide_token, slide_id) => {
  return axios.get(zoomifyURL + slide_id + "/ws-annotated.jpg", { headers: { Authorization: `Bearer ${slide_token}` }, responseType: "arraybuffer" });
};

export const getPdfFromCloud = (slide_token) => {
  return axios.get(requestFormURL + `get_requestform?${Date.now()}`, { headers: { Authorization: `Bearer ${slide_token}` }, responseType: "arraybuffer" });
};

export const addupdatescale = (obj, slide_token) => {
  return axios.post(baseURL + "set_slide_scale", obj, { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const updateReportingStatus = (obj) => {
  return axios.post(baseURL + "update_reporting_status", obj, { headers: { Authorization: `Bearer ${getGroupToken()}` } });
};

export const updateAssignedUser = (obj) => {
  return axios.post(baseURL + "update_assigned_user", obj, { headers: { Authorization: `Bearer ${getGroupToken()}` } });
};

export const getAssociatedGroupUsers = () => {
  return axios.get(baseURL + "associated_groupuser_data", { headers: { Authorization: `Bearer ${getGroupToken()}` } });
};

export const saveAnnotation = (obj, slide_token) => {
  return axios.post(baseURL + "add_annotation_data", obj, { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const getAnnotationsList = (slide_token) => {
  return axios.get(baseURL + "get_annotation_list", { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const deleteAnnotation = (obj, slide_token) => {
  return axios.post(baseURL + "delete_annotation", obj, { headers: { Authorization: `Bearer ${slide_token}` } });
};

export const PageContext = createContext(null);
