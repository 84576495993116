import { useEffect, useContext } from 'react'
import { Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { PageContext } from "../Groups";


export const GroupList = (props) => {
    const context = useContext(PageContext);
    const { pageState } = context;

    const history = useHistory()

    useEffect(() => {
    }, [])

    const handleClick = (groupdata) => {

        history.push({ pathname: '/groupsv/' + groupdata.group_id });

    }

    return (

        <div>
            {/* <h5 style={{ textAlign: 'left' }} onClick={() => history.push('/groups')}><b>Groups <small>{pageState.groupList && pageState.groupList.length}</small></b></h5> */}
            <br />
            <Card style={{ width: '60rem', margin: '0 auto', float: 'none' }}>
                <Card.Header>
                    <b>Groups You've Joined.</b> {pageState.groupList.length}
                </Card.Header>
                <Card.Body>
                    {/* <Card.Title>
                        <Form className="d-flex">
                            <FormControl
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                            />
                            <Button variant="outline-success"><i className="fa fa-search" aria-hidden="true"></i></Button>

                        </Form>
                    </Card.Title> */}
                    <div className="table">
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr height='15px'>
                                    <th>Sr</th>
                                    {/* <th>Group Id</th> */}
                                    <th>Group Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    pageState && pageState.groupList.length > 0 ? pageState.groupList.map((item, key) => (
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            {/* <td><a href="#!">{item.group_id}</a></td> */}
                                            <td onClick={() => handleClick(item)}><a href="#!">{item.group_name}</a></td>
                                        </tr>
                                    )) : <div>Loading...</div>
                                }

                            </tbody>
                        </table>

                    </div>

                </Card.Body>
            </Card>


        </div>

    )
}
