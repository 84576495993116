import { useEffect, useState } from "react";
import { SubscriptionData } from "./Users";
import { getUserSubscriptions } from "../Service";

export const Subscription = () => {

    const [userSubscriptions, set_userSubscriptions] = useState([])

    useEffect(() => {
        getUserSubscriptions({ "userid": "" }).then(res => {
            if (res && res.data.result) {
                set_userSubscriptions(res.data.result);
            }
        })
    }, [])
    return (
        <div>
            {
                userSubscriptions && <SubscriptionData subdata={{userSubscriptions}} />
            }
        </div>
    )
}