import React, { useReducer, useEffect, createContext, useRef, useContext, useState } from "react";
import { getAnswer, pageReducer } from "../Service";
import { setOfferAndIceCandidate } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { LiveVideo } from "./LiveViewComponents/LiveVideo";
import { Commands } from "./LiveViewComponents/Commands";
import { WSImage } from "./LiveViewComponents/WSImage";
import { Logs } from "./LiveViewComponents/Logs";
import { ActiveDevices } from "../ActiveDevices";
import { ActiveDeviceList } from "../ActiveDeviceList";
import cameraswitch from "../../../Images/cameraswitch.png";
import { FaPowerOff, FaUncharted } from "react-icons/fa";
import { RiCameraLensFill } from "react-icons/ri";
import { GoFileSymlinkFile, GoPrimitiveDot } from "react-icons/go";
import WsImage1 from "./LiveViewComponents/WsImage1";

export const LiveView = (props) => {
  const remoteVideoElement = useRef(null);

  let refreshButtonElement = useRef(null);

  let exitcommandButtonElement = useRef(null);
  let recentImageButtonElement = useRef(null);

  let sendcommandButtonElement = useRef(null);
  let serialInputButtonElement = useRef(null);

  let gotoPropObj = {
    buttonElementRefs: {
      gotoXbtn: useRef(null),
      gotoYbtn: useRef(null),
      gotoZbtn: useRef(null),
    },
    inputElementRefs: {
      dataXInput: useRef(null),
      dataYInput: useRef(null),
      dataZInput: useRef(null),
    },
  };

  let movePropObj = {
    buttonElementRefs: {
      move_left_Xbtn: useRef(null),
      move_right_Xbtn: useRef(null),

      move_up_Ybtn: useRef(null),
      move_down_Ybtn: useRef(null),

      move_up_Zbtn: useRef(null),
      move_down_Zbtn: useRef(null),
    },
    inputElementRefs: {
      dataXInput: useRef(null),
      dataYInput: useRef(null),
      dataZInput: useRef(null),
    },
    keyName: {
      xPos: { x: "mo_right" },
      xNeg: { x: "mo_left" },
      yPos: { y: "mo_up" },
      yNeg: { y: "mo_down" },
      zPos: { z: "mo_up" },
      zNeg: { z: "mo_down" },
    },
    keyboardKeyName: {
      xPos: { x: "kb_right" },
      xNeg: { x: "kb_left" },
      yPos: { y: "kb_up" },
      yNeg: { y: "kb_down" },
      zPos: { z: "kb_up" },
      zNeg: { z: "kb_down" },
    },
  };

  let homePositionPropObj = {
    buttonElementRefs: {
      homeXbtn: useRef(null),
      homeYbtn: useRef(null),
      homeZbtn: useRef(null),
    },
  };

  let focusPropObj = {
    focusbuttonElementRefs: {
      coarsebtn: useRef(null),
      finebtn: useRef(null),
    },
  };
  let requestPropObj = {
    requestbuttonElementRefs: {
      requestslidebtn: useRef(null),
      revokeslidebtn: useRef(null),
    },
  };
  let drawPropObj = {
    drawbuttonElementRefs: {
      drawbtn: useRef(null),
    },
  };

  let lensPropObj = {
    lens: {
      lens4X: useRef(null),
      lens10X: useRef(null),
      lens20X: useRef(null),
      lens40X: useRef(null),
      lens100X: useRef(null),
    },
    lensValue: {
      lens4X: "4",
      lens10X: "10",
      lens20X: "20",
      lens40X: "40",
      lens100X: "100",
    },
  };

  let dataChannel = "";

  const initialState = {
    isDeviceBusy: 1,
    MsgLog: [],
    Connection_Msg: "",
    isRefreshBtnDisable: true,
    isDisabled: false,
    subState: "",
    wsImageData: "",
    wsRecentImageData: "",
    heartbeatData: "",
    selectedLensButton: "",
    selectedLensXYPoints: [],
    positionData: [{ x: 123, y: 44, z: 43, "px-x": 100, "px-y": 20 }], //note:px-x and px-y is used in WSImage,So sent there also && x,y,z is used only in logscomponent
    timeInterval: 15000,
    gotoCoords: [{ x: "100", y: "110", z: "120" }],
    moveCoords: [],
    homePositionCoords: [{ x: 1, y: 1, z: 1 }],
    sendWSImageCoords: (x, y) => {
      //send wsImageCoordsOnClick

      const event = new CustomEvent("sendPxPy", {
        detail: { x: x, y: y },
      });
      window.dispatchEvent(event);
      // NotificationManager.success("msg sent!", "", 2000);
    },
    sendSerialPortData: (a, b) => {
     
      const serialEvent = new CustomEvent("sendSerialData", {
        detail: { x: a, y: b },
      });
      window.dispatchEvent(serialEvent);
    },
  };

  const [pageState, dispatch] = useReducer(pageReducer, initialState);

  const collectOffer = function collectOfferAndIceCandidiate(roomname, devicename, peerConnection) {
    var offerOptions = {
      offerToReceiveAudio: false,
      offerToReceiveVideo: true,
    };

    peerConnection
      .createOffer(offerOptions)
      .then((offer) => {
        peerConnection.setLocalDescription(offer);
      })
      .then(function () {
        function checkState() {
          if (peerConnection.iceGatheringState === "complete") {
            peerConnection.removeEventListener("icegatheringstatechange", checkState);
            var offer = peerConnection.localDescription;

            let obj = {
              offer: offer,
              roomName: roomname,
              deviceName: devicename,
            };

            setOfferAndIceCandidate(obj)
              .then((res) => {
                if (res && res.data && res.data.Message) {
                  NotificationManager.info("Connecting...", "", 3000);
                  pageDispatcher.set_connection_Msg("Connecting...");
                }
              })
              .catch((e) => {
                console.log("error", e);
              });
          }
        }
        peerConnection.addEventListener("icegatheringstatechange", checkState);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const registerPeerConnection = function registerPeerConnectionListeners(peerConnection, remoteStream) {
    peerConnection.addEventListener("icegatheringstatechange", () => {
      // console.log(`ICE gathering state changed: ${peerConnection.iceGatheringState}`);
    });
    peerConnection.addEventListener("connectionstatechange", async () => {
      console.log(`Connection state change: ${peerConnection.connectionState}`);

      if (peerConnection.connectionState === "connected") {
        pageDispatcher.set_connection_Msg("connected");
        // pageDispatcher.set_enableButtons(false);
        pageDispatcher.set_enableRefreshBtn(false);

        window.onbeforeunload = function () {
          return "device will get disconnected, are you sure?";
        };
      }

      if (peerConnection.connectionState === "disconnected") {
        pageDispatcher.set_connection_Msg("disconnected");
        // alert("disconnected from server side! Try after some time.");

        if (remoteStream) {
          remoteStream.getTracks().forEach((track) => track.stop());
        }
        if (peerConnection) {
          await peerConnection.close();
        }
        remoteVideoElement.current.srcObject = null;
        if (pageState.wsImageData) {
          pageDispatcher.set_wsImage("");
        }
      }

      if (peerConnection.connectionState === "failed") {
      }
    });
    peerConnection.addEventListener("signalingstatechange", () => {
      console.log(`Signaling state change: ${peerConnection.signalingState}`);
    });
    peerConnection.addEventListener("iceconnectionstatechange ", () => {
      console.log(`ICE connection state change: ${peerConnection.iceConnectionState}`);
    });
    peerConnection.addEventListener("track", function (evt) {
      if (evt.track.kind === "video") {
        // remoteVideoElement.current.srcObject = evt.streams[0];
        evt.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track);
        });
      }
    });
    peerConnection.addEventListener("datachannel", (event) => {
      // after vdo streams get & set another text data .dataChannel connection is mainly for sending & receving  msgs
      dataChannel = event.channel;
      let interval, startTime;

      dataChannel.addEventListener("open", (event) => {
        console.log("connection opened !");

        //getWS ReCapture btn
        refreshButtonElement.current.addEventListener("click", () => {
          var obj = {
            msg_type: "getWS",
            msg_data: "",
          };
          dataChannel.send(JSON.stringify(obj));
          NotificationManager.success("msg sent!", "", 2000);
        });

        //get recent image btn
        recentImageButtonElement.current.addEventListener("click", () => {
          var obj = {
            msg_type: "getImage",
            msg_data: "",
          };
          dataChannel.send(JSON.stringify(obj));
          NotificationManager.success("msg sent!", "", 2000);
          // console.log("Exit command not sent", obj)
        });

        const sendSerialData = (e) => {
          var obj = {
            msg_type: "usbjoystick",
            msg_data: [e.detail.x],
          };

          if (e.detail.y) {
            // console.log("data channel sending");
            dataChannel.send(JSON.stringify(obj));
          }
        };
        window.addEventListener("sendSerialData", sendSerialData);

        const sendPxPy = (e) => {
          var obj = {
            msg_type: "gotopx",
            msg_data: [
              {
                x: Math.round(e.detail.x),
                y: Math.round(e.detail.y),
              },
            ],
          };
          // console.log("gotopx Sent", obj)

          dataChannel.send(JSON.stringify(obj));
        };

        window.addEventListener("sendPxPy", sendPxPy);

        //Disconnect
        exitcommandButtonElement.current.addEventListener("click", () => {
          var obj = {
            msg_type: "doexit",
            msg_data: "",
          };
          // setRoomId("")
          dataChannel.send(JSON.stringify(obj));

          NotificationManager.success("msg sent!", "", 2000);
        });

        //switch Camera
        document.getElementById("switchCam").addEventListener("click", () => {
          var obj = {
            msg_type: "switchcam",
          };
          // console.log("switchcam sent", obj)
          dataChannel.send(JSON.stringify(obj));
          NotificationManager.success("msg sent!", "", 2000);
        });

        //sendRandomCommand
        sendcommandButtonElement.current.addEventListener("click", (event) => {
          var data = document.getElementById("commandtext").value;
          var obj = {
            msg_type: "raw_cmd",
            msg_data: data,
          };
          // console.log("rawCMD sent", obj)
          dataChannel.send(JSON.stringify(obj));
          document.getElementById("commandtext").value = "";
          NotificationManager.success("msg sent!", "", 2000);
        });

        //gotoXYZ position
        gotoPropObj.buttonElementRefs.gotoXbtn.current.addEventListener("click", () => {
          var obj = {
            msg_type: "goto",
            msg_data: [
              {
                x: gotoPropObj.inputElementRefs.dataXInput.current.value,
              },
            ],
          };
          dataChannel.send(JSON.stringify(obj));
          NotificationManager.success("msg sent!", "", 700);
        });
        gotoPropObj.buttonElementRefs.gotoYbtn.current.addEventListener("click", () => {
          var obj = {
            msg_type: "goto",
            msg_data: [
              {
                y: gotoPropObj.inputElementRefs.dataYInput.current.value,
              },
            ],
          };
          dataChannel.send(JSON.stringify(obj));
          NotificationManager.success("msg sent!", "", 700);
        });
        gotoPropObj.buttonElementRefs.gotoZbtn.current.addEventListener("click", () => {
          var obj = {
            msg_type: "goto",
            msg_data: [
              {
                z: gotoPropObj.inputElementRefs.dataZInput.current.value,
              },
            ],
          };
          dataChannel.send(JSON.stringify(obj));
          NotificationManager.success("msg sent!", "", 700);
        });

        //moveXYZ position mopuse click event trigger
        movePropObj.buttonElementRefs.move_left_Xbtn.current.addEventListener("click", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyName.xNeg],
          };
          dataChannel.send(JSON.stringify(obj));
        });
        movePropObj.buttonElementRefs.move_right_Xbtn.current.addEventListener("click", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyName.xPos],
          };
          dataChannel.send(JSON.stringify(obj));
        });
        movePropObj.buttonElementRefs.move_up_Ybtn.current.addEventListener("click", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyName.yPos],
          };
          dataChannel.send(JSON.stringify(obj));
        });
        movePropObj.buttonElementRefs.move_down_Ybtn.current.addEventListener("click", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyName.yNeg],
          };
          dataChannel.send(JSON.stringify(obj));
        });
        movePropObj.buttonElementRefs.move_up_Zbtn.current.addEventListener("click", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyName.zPos],
          };
          dataChannel.send(JSON.stringify(obj));
        });
        movePropObj.buttonElementRefs.move_down_Zbtn.current.addEventListener("click", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyName.zNeg],
          };
          dataChannel.send(JSON.stringify(obj));
        });
        // var myInterval;
        // function getDATA() {
        //   var gamepads = navigator.getGamepads();
        //   const value_in_fraction_x = gamepads[0].axes[0];
        //   const value_in_fraction_y = gamepads[0].axes[1];
        //   const value_in_fraction_z = gamepads[0].axes[3];
        //   const x = value_in_fraction_x.toFixed(2);
        //   const y = value_in_fraction_y.toFixed(2);
        //   const z = value_in_fraction_z.toFixed(2);

        //   // console.log("x:",x, "y:",y, "z:",z)
        //   if (x != 0 || y != 0 || z != 0) {
        //     var obj = {
        //       msg_type: "movexyz",
        //       msg_data: [{ x: x, y: y, z: z }],
        //     };
        //     dataChannel.send(JSON.stringify(obj));
        //   }
        // }
        // function connecthandler() {
        //   myInterval = setInterval(getDATA, 100);
        //   console.log("Connected");
        // }
        // function disconnecthandler() {
        //   clearInterval(myInterval);
        //   console.log("Disconnected");
        // }
        // window.addEventListener("gamepadconnected", connecthandler);
        // window.addEventListener("gamepaddisconnected", disconnecthandler);

        //moveXYZ position Mouse-hover position and keyboard click event trigger
        movePropObj.buttonElementRefs.move_left_Xbtn.current.addEventListener("keydown", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyboardKeyName.xNeg],
          };
          dataChannel.send(JSON.stringify(obj));

          const rn = Math.random();
          pageDispatcher.set_logMsg(["←", rn]);
        });
        movePropObj.buttonElementRefs.move_right_Xbtn.current.addEventListener("keydown", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyboardKeyName.xPos],
          };
          dataChannel.send(JSON.stringify(obj));

          const rn = Math.random();
          pageDispatcher.set_logMsg(["→", rn]);
        });
        movePropObj.buttonElementRefs.move_up_Ybtn.current.addEventListener("keydown", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyboardKeyName.yPos],
          };
          dataChannel.send(JSON.stringify(obj));

          const rn = Math.random();
          pageDispatcher.set_logMsg(["↑", rn]);
        });
        movePropObj.buttonElementRefs.move_down_Ybtn.current.addEventListener("keydown", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyboardKeyName.yNeg],
          };
          dataChannel.send(JSON.stringify(obj));

          const rn = Math.random();
          pageDispatcher.set_logMsg(["↓", rn]);
        });
        movePropObj.buttonElementRefs.move_up_Zbtn.current.addEventListener("keydown", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyboardKeyName.zPos],
          };
          dataChannel.send(JSON.stringify(obj));

          const rn = Math.random();
          pageDispatcher.set_logMsg(["⇈", rn]);
        });
        movePropObj.buttonElementRefs.move_down_Zbtn.current.addEventListener("keydown", (e) => {
          e.currentTarget.blur();
          var obj = {
            msg_type: "move",
            msg_data: [movePropObj.keyboardKeyName.zNeg],
          };
          dataChannel.send(JSON.stringify(obj));

          const rn = Math.random();
          pageDispatcher.set_logMsg(["⇊", rn]);
        });

        //homeXYZ position
        homePositionPropObj.buttonElementRefs.homeXbtn.current.addEventListener("click", (e) => {
          var obj = {
            msg_type: "home",
            msg_data: [{ x: pageState.homePositionCoords[0].x }],
          };
          dataChannel.send(JSON.stringify(obj));
          // NotificationManager.success("msg sent!", '', 700);
        });
        homePositionPropObj.buttonElementRefs.homeYbtn.current.addEventListener("click", (e) => {
          var obj = {
            msg_type: "home",
            msg_data: [{ y: pageState.homePositionCoords[0].y }],
          };
          dataChannel.send(JSON.stringify(obj));
          // NotificationManager.success("msg sent!", '', 700);
        });
        homePositionPropObj.buttonElementRefs.homeZbtn.current.addEventListener("click", (e) => {
          var obj = {
            msg_type: "home",
            msg_data: [{ z: pageState.homePositionCoords[0].z }],
          };
          console.log("homeZdata sent", obj);
          dataChannel.send(JSON.stringify(obj));
          // NotificationManager.success("msg sent!", '', 700);
        });

        //focusBtns

        focusPropObj.focusbuttonElementRefs.coarsebtn.current.addEventListener("click", (e) => {
          var obj = {
            msg_type: "autofocus",
            msg_data: "1",
          };
          dataChannel.send(JSON.stringify(obj));
          // NotificationManager.success("msg sent!", '', 2000);
        });
        focusPropObj.focusbuttonElementRefs.finebtn.current.addEventListener("click", (e) => {
          var obj = {
            msg_type: "autofocus",
            msg_data: "0",
          };
          dataChannel.send(JSON.stringify(obj));
          // NotificationManager.success("msg sent!", '', 2000);
        });

        //Slide Request
        var el = requestPropObj.requestbuttonElementRefs.requestslidebtn.current;
        if (el) {
          el.addEventListener("click", (e) => {
            var obj = {
              msg_type: "give_control",
              msg_data: "",
            };
            dataChannel.send(JSON.stringify(obj));

            // NotificationManager.success("msg sent!", '', 2000);
          });
        }

        //Revoke Request
        var el1 = requestPropObj.requestbuttonElementRefs.revokeslidebtn.current;
        if (el1) {
          el1.addEventListener("click", (e) => {
            var obj = {
              msg_type: "take_control",
              msg_data: "",
            };
            dataChannel.send(JSON.stringify(obj));

            // NotificationManager.success("msg sent!", '', 2000);
          });
        }

        // Lens selected for mouse click
        lensPropObj.lens.lens4X.current.addEventListener("click", (e) => {
          var obj = {
            msg_type: "setlens",
            msg_data: lensPropObj.lensValue.lens4X,
          };
          dataChannel.send(JSON.stringify(obj));
        });
        lensPropObj.lens.lens10X.current.addEventListener("click", (e) => {
          var obj = {
            msg_type: "setlens",
            msg_data: lensPropObj.lensValue.lens10X,
          };
          dataChannel.send(JSON.stringify(obj));
        });
        lensPropObj.lens.lens20X.current.addEventListener("click", (e) => {
          var obj = {
            msg_type: "setlens",
            msg_data: lensPropObj.lensValue.lens20X,
          };
          dataChannel.send(JSON.stringify(obj));
        });
        lensPropObj.lens.lens40X.current.addEventListener("click", (e) => {
          var obj = {
            msg_type: "setlens",
            msg_data: lensPropObj.lensValue.lens40X,
          };
          dataChannel.send(JSON.stringify(obj));
        });
        lensPropObj.lens.lens100X.current.addEventListener("click", (e) => {
          var obj = {
            msg_type: "setlens",
            msg_data: lensPropObj.lensValue.lens100X,
          };
          dataChannel.send(JSON.stringify(obj));
        });

        // Lens selected for keyboard keydown event
        lensPropObj.lens.lens4X.current.addEventListener("keydown", (e) => {
          var obj = {
            msg_type: "setlens",
            msg_data: lensPropObj.lensValue.lens4X,
          };
          dataChannel.send(JSON.stringify(obj));
        });
        lensPropObj.lens.lens10X.current.addEventListener("keydown", (e) => {
          var obj = {
            msg_type: "setlens",
            msg_data: lensPropObj.lensValue.lens10X,
          };
          dataChannel.send(JSON.stringify(obj));
        });
        lensPropObj.lens.lens20X.current.addEventListener("keydown", (e) => {
          var obj = {
            msg_type: "setlens",
            msg_data: lensPropObj.lensValue.lens20X,
          };
          dataChannel.send(JSON.stringify(obj));
        });
        lensPropObj.lens.lens40X.current.addEventListener("keydown", (e) => {
          var obj = {
            msg_type: "setlens",
            msg_data: lensPropObj.lensValue.lens40X,
          };
          dataChannel.send(JSON.stringify(obj));
        });
        lensPropObj.lens.lens100X.current.addEventListener("keydown", (e) => {
          var obj = {
            msg_type: "setlens",
            msg_data: lensPropObj.lensValue.lens100X,
          };
          dataChannel.send(JSON.stringify(obj));
        });

        //start pinging for heartbet data in every 5 second
        startTime = new Date().getTime();
        interval = setInterval(() => {
          //  console.log("i'm pinging for hearbeat data in every 5 sec..");
          var obj = {
            msg_type: "heartbeat",
            msg_data: "",
          };

          if (dataChannel.readyState === "open") {
            dataChannel.send(JSON.stringify(obj));
          }
        }, 5000);
      });

      dataChannel.addEventListener("close", (event) => {
        sessionStorage.clear();
        window.onbeforeunload = null;
        window.location.reload(true);
      });
      // *************************************************************************************************************************************************************************//

      function setUpdatedData(obj) {
        switch (obj.msg_type) {
          case "setlens":
            if (obj.msg_scale) {
              pageDispatcher.set_lensXYPoints(obj.msg_scale);
            }
            return pageDispatcher.set_lensButton(obj.msg_data);

          case "setWS":
            return pageDispatcher.set_wsImage(obj.msg_data);

          case "position":
            console.log("position", obj.msg_data);
            // console.log("PostionCoords", pageState.positionData[0]);
            return pageDispatcher.set_position(obj.msg_data);

          case "move":
            return pageDispatcher.set_moveCoords(obj.msg_data);

          case "goto":
            return pageDispatcher.set_gotoCoords(obj.msg_data);

          case "device_busy":
            return pageDispatcher.set_device_state(obj.msg_data);

          default:
            return "default";
        }
      }

      dataChannel.addEventListener("message", (event) => {
        //RECEIVING MESGS HERE!

        let str = event.data.replace(/'/g, '"');
        let obj = JSON.parse(str);
        // console.log("i'm listening for hearbeat data in every 5...");
        // console.log("Message", obj.msg_type);

        //If hearbeat data didnt recive in 1st 15 second then disconnect!!
        // console.log("Time diffrence", new Date().getTime() - startTime)
        if (obj.msg_type === "heartbeat") {
          console.log("heartbeat", obj.subState);
          pageDispatcher.set_subState(obj.subState);
          startTime = new Date().getTime();
        }

        if (new Date().getTime() - startTime > pageState.timeInterval && obj.msg_type !== "heartbeat") {
          if (
            obj.msg_type === "setlens" ||
            obj.msg_type === "setWS" ||
            obj.msg_type === "position" ||
            obj.msg_type === "move" ||
            obj.msg_type === "goto" ||
            obj.msg_type === "device_busy"
          ) {
            //This condition is for if in case 15 sec over and in between other data has been received except heartbeat data so increment the time interval so that it won't block even if other msgs has come.
            setUpdatedData(obj);
            return pageDispatcher.set_updateTimeInterval();
          }
          //  else if (obj.msg_type === "doexit"){
          //     window.opener.location.reload(true);
          //     window.self.close();
          // }

          alert("Connection Timeout! 15 sec over,did not receive hearbeatData");
          clearInterval(interval);
          window.opener.location.reload();
          window.close();
          return;
        }
        setUpdatedData(obj);
      });
    });
    return peerConnection;
  };

  const devlopmentMode = false;
  useEffect(() => {
    const roomname = sessionStorage.getItem("roomname");
    console.log("Roomname >>", roomname);

    if (!devlopmentMode && roomname) {
      let peerConnection = null;
      let remoteStream = new MediaStream();
      let devicename = sessionStorage.getItem("devicename");
      console.log("useEffect Rendered >>>");
      console.log("Devicename >>>", devicename);

      const turn_url = sessionStorage.getItem("turn_url");
      const user = sessionStorage.getItem("user");
      const password = sessionStorage.getItem("password");
      var config = {
        sdpSemantics: "unified-plan",
        iceServers: [
          { urls: ["stun:stun.l.google.com:19302"] },

          {
            urls: turn_url,
            username: user,
            credential: password,
          },
        ],
      };
      peerConnection = new RTCPeerConnection(config);

      peerConnection.createDataChannel("channel");

      sessionStorage.setItem("activeroomname", roomname);
      sessionStorage.removeItem("roomname");

      remoteVideoElement.current.srcObject = remoteStream;
      registerPeerConnection(peerConnection, remoteStream); //step:1 RegisterPeerConn
      collectOffer(roomname, devicename, peerConnection); //step2:collect offer & ice and send to backend
      let obj = {
        roomName: roomname,
      };

      var startTime = new Date().getTime();
      var interval = setInterval(function () {
        //step 2.1 if answer not came in defined time then close window.
        if (new Date().getTime() - startTime > 16000) {
          alert("Connection Timeout! Please Try Again.");
          clearInterval(interval);
          window.opener.location.reload();
          window.close();
          return;
        }
        getAnswer(obj) //step:3 get for answer
          .then(async (res) => {
            if (res && res.data && res.data.answer !== null && res.data.answer[0] !== undefined && res.data.answer[0].answer !== null) {
              var inanswer = JSON.parse(res.data.answer[0].answer);
              await peerConnection.setRemoteDescription(inanswer);
              clearInterval(interval);
            }
          });
      }, 1000);
    }

    // console.log("In useEffect roomId:",roomId)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const pageDispatcher = {
    set_enableRefreshBtn: (value) => dispatch({ type: "set_enableRefreshBtn", payload: value }),
    set_wsImage: (data) => dispatch({ type: "set_wsImage", payload: data }),
    // set_wsRecentImage: (data) => dispatch({ type: "set_wsRecentImage", payload: data }),
    set_subState: (value) => dispatch({ type: "set_subState", payload: value }),
    set_position: (data) => dispatch({ type: "set_position", payload: data }),
    set_device_state: (data) => dispatch({ type: "set_device_state", payload: data }),
    set_logMsg: (data) => dispatch({ type: "set_logMsg", payload: data }),
    set_connection_Msg: (data) => dispatch({ type: "set_connection_Msg", payload: data }),
    set_lensButton: (data) => dispatch({ type: "set_lensButton", payload: data }),
    set_SerialPortButton: (data) => dispatch({ type: "set_SerialPortButton", payload: data }),

    set_lensXYPoints: (data) => dispatch({ type: "set_lensXYPoints", payload: data }),
    set_gotoCoords: (data) => dispatch({ type: "set_gotoCoords", payload: data }),
    set_moveCoords: (data) => dispatch({ type: "set_moveCoords", payload: data }),
    set_updateTimeInterval: () => dispatch({ type: "set_updateTimeInterval", payload: 5000 }),
  };

  return (
    <div className="container-fluid">
      <PageContext.Provider value={{ pageState, pageDispatcher, dispatch }}>
        <NotificationContainer />
        <LiveViewComponent
          remoteVideoElement={remoteVideoElement}
          refreshButtonElement={refreshButtonElement}
          sendcommandButtonElement={sendcommandButtonElement}
          recentImageButtonElement={recentImageButtonElement}
          exitcommandButtonElement={exitcommandButtonElement}
          gotoPropObj={gotoPropObj}
          movePropObj={movePropObj}
          homePositionPropObj={homePositionPropObj}
          focusPropObj={focusPropObj}
          lensPropObj={lensPropObj}
          requestPropObj={requestPropObj}
          drawPropObj={drawPropObj}
          serialInputButtonElement={serialInputButtonElement}
          // setRoomIdCallback={setRoomIdCallback}
        />
      </PageContext.Provider>
    </div>
  );
};

const LiveViewComponent = (props) => {
  const context = useContext(PageContext);
  const [isDrawEnable, setIsDrawEnable] = useState(false);

  const { lens4X, lens10X, lens20X, lens40X, lens100X } = props.lensPropObj.lens;
  const captureSnap = () => {
    if (props.remoteVideoElement) {
      //create URL
      const canvas = document.createElement("canvas");
      // scale the canvas accordingly
      canvas.width = props.remoteVideoElement.current.videoWidth;
      canvas.height = props.remoteVideoElement.current.videoHeight;
      // draw the video at that frame
      canvas.getContext("2d").drawImage(props.remoteVideoElement.current, 0, 0, canvas.width, canvas.height);
      // convert it to a usable data URL
      const dataURL = canvas.toDataURL();

      //download
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "snapshot.png";
      link.click();
    }
  };

  const initialStates = "";

  const updateNotificationRef = useRef();

  const serialPortDispatcher = async () => {

    var outputStream, inputStream, port, serialReader;
    if (port) {
      disconnect();
    } else {
      await connect();
    }

    async function connect() {
      const filter = {
        usbVendorId: 0x2341, // Arduino SA
      };

      try {

        port = await navigator.serial.requestPort({} /*{ filters: [filter] }*/);
        // Continue connecting to |port|.
        // console.log("port Info:", port.getInfo());
        context.pageDispatcher.set_SerialPortButton(!context.pageState.isSerialPortButtonSelected);

        // - Wait for the port to open.
        await port.open({ baudRate: 38400 });
        console.log("port opened ");

        //   statusBar.innerText = "Connected";
        //   connectButton.innerText = "Disconnect"
        readLoop(port);
      } catch (e) {
        //If the pipeTo error appears; clarify the problem by giving suggestions.
        if (e == "TypeError: Cannot read property 'pipeTo' of undefined") {
          e += "\n Use Google Chrome and enable-experimental-web-platform-features";
        }
        if (e == "TypeError: Failed to execute 'requestPort' on 'Serial': 1 argument required, but only 0 present.") {
          e += "\n Use Google Chrome and enable-experimental-web-platform-features";
        }
        if (e == "NetworkError: Failed to open serial port.") {
          e += "Try unplugging the Arduino and connect again";
        }
        disconnect();
        //   connectButton.innerText = "Connect"
        //   statusBar.innerText = e;
      }
    }

    async function disconnect() {
      context.pageDispatcher.set_SerialPortButton(!context.pageState.isSerialPortButtonSelected);

      if (serialReader) {
        await serialReader.cancel();
        //   await inputDone.catch(() => { });
        serialReader = null;
        //   inputDone = null;
      }
      if (outputStream) {
        await outputStream.getWriter().close();
        //   await outputDone;
        //   outputStream = null;
        //   outputDone = null;
      }
      // statusBar.innerText = "Disconnected";
      // connectButton.innerText = "Connect"
      //Close the port.
      if (port) {

        await port.close();
      }
      port = null;
    }

    async function readLoop(port) {
      while (port.readable) {
        const reader = port.readable.getReader();
        try {
          while (true) {

            const { value, done } = await reader.read();
            const byteData = Array.apply([], value).join(",");
            context && context.pageState.sendSerialPortData(byteData, true);
            if (done) {
              // |reader| has been canceled.
              break;
            }
            // Do something with |value|…
          }
        } catch (error) {
          // Handle |error|…
          console.log(error);
        } finally {
          reader.releaseLock();
        }
      }
    }
   

  };

  useEffect(() => {
    const keyaction = (e) => {
      e.preventDefault();
      if (e.which === 37) {
        document.getElementById("left").dispatchEvent(new KeyboardEvent("keydown", { key: "a" }));
      } else if (e.which === 38) {
        document.getElementById("up").dispatchEvent(new KeyboardEvent("keydown", { key: "b" }));
      } else if (e.which === 39) {
        document.getElementById("right").dispatchEvent(new KeyboardEvent("keydown", { key: "c" }));
      } else if (e.which === 40) {
        document.getElementById("down").dispatchEvent(new KeyboardEvent("keydown", { key: "d" }));
      } else if (e.which === 81) {
        document.getElementById("z-up").dispatchEvent(new KeyboardEvent("keydown", { key: "e" }));
      } else if (e.which === 65) {
        document.getElementById("z-down").dispatchEvent(new KeyboardEvent("keydown", { key: "f" }));
      } else if (e.which === 16) {
        document.getElementById("z-up").dispatchEvent(new KeyboardEvent("keydown", { key: "e" }));
      } else if (e.which === 17) {
        document.getElementById("z-down").dispatchEvent(new KeyboardEvent("keydown", { key: "f" }));
      } else if (e.which === 49) {
        document.getElementById("4").dispatchEvent(new KeyboardEvent("keydown", { key: "g" }));
      } else if (e.which === 50) {
        document.getElementById("10").dispatchEvent(new KeyboardEvent("keydown", { key: "h" }));
      } else if (e.which === 51) {
        document.getElementById("20").dispatchEvent(new KeyboardEvent("keydown", { key: "i" }));
      } else if (e.which === 52) {
        document.getElementById("40").dispatchEvent(new KeyboardEvent("keydown", { key: "j" }));
      } else if (e.which === 53) {
        document.getElementById("100").dispatchEvent(new KeyboardEvent("keydown", { key: "k" }));
      }
    };

    window.wsiarrowKeyContainer = document.getElementById("liveViewParentDiv");
    window.wsiarrowKeyContainer.addEventListener("mouseover", function () {
      document.addEventListener("keydown", keyaction);
      // window.wsiarrowKeyContainer.style.backgroundColor = '#e7e7e7';
      // window.wsiarrowKeyContainer.style.border = '1px solid #91918c';
    });
    // window.wsiarrowKeyContainer.addEventListener("mouseout", function () {
    window.wsiarrowKeyContainer.removeEventListener("mouseover", function () {
      document.removeEventListener("keydown", keyaction, false);
      // window.wsiarrowKeyContainer.style.backgroundColor = "#f2f2f2";
      // window.wsiarrowKeyContainer.style.border = '1px solid #f2f2f2'
    });
  }, []);

  useEffect(() => {
    if (context.pageState.MsgLog === initialStates) {
      return;
    }
    updateNotificationRef.current.animate(
      {
        opacity: [0, 1, 0],
      },
      500
    );
  }, [context.pageState.MsgLog]);

  return (
    <div
      className="rtable"
      id="liveViewParentDiv"
    >
      <div className="row justify-content-between">
        <div className="col-md-9 p-0">
          <LiveVideo
            remoteVideoElement={props.remoteVideoElement}
            isDrawEnable={isDrawEnable}
            setIsDrawEnable={setIsDrawEnable}
            selectedLensXYPoints={context.pageState.selectedLensXYPoints}
          />
        </div>
        <div className="col-md-3 p-0">
          <div className="d-flex justify-content-between align-item-center ml-4">
            <h4 className="ml-5">Micalys Live Control</h4>
            <div className="videoContainer">
              <div
                ref={updateNotificationRef}
                style={{ opacity: 0 }}
              >
                <h1 className="videoTextArrowElement">{context.pageState.MsgLog[0]}</h1>
              </div>
              {context.pageState.selectedLensButton && (
                <div>
                  <h1 className="videoTextElement  p-2">{context.pageState.selectedLensButton}</h1>
                </div>
              )}
              <GoPrimitiveDot
                fontSize="1.5em"
                color={context.pageState.isDeviceBusy === 0 ? "green" : "red"}
              />
            </div>
          </div>

          {/* <WSImage
            WSImageData={context.pageState.wsImageData}
            PostionCoords={context.pageState.positionData}
          /> */}
          <WsImage1
            WSImageData={context.pageState.wsImageData}
            PostionCoords={context.pageState.positionData}
          />

          <div className="d-flex align-items-end flex-column">
            <button
              className="btn btn-outline-primary  btn-sm"
              type="button"
              data-toggle="collapse"
              data-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              ↓
            </button>

            <div
              className="collapse show width "
              id="collapseExample"
            >
              <div className="row justify-content-between p-0 mr-2 mt-1 mb-3">
                <button
                  type="button"
                  className="btn btn-outline-primary  btn-sm "
                  id="refresh"
                  ref={props.refreshButtonElement}
                  disabled={context.pageState.isRefreshBtnDisable}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="New Capture"
                >
                  <RiCameraLensFill fontSize="1.5em" />{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  disabled={context.pageState.isRefreshBtnDisable}
                  ref={props.recentImageButtonElement}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Last Captured"
                >
                  <GoFileSymlinkFile fontSize="1.5em" />
                </button>
                <button
                  type="button"
                  className={context.pageState.isSerialPortButtonSelected ? "btn btn-secondary btn-sm" : "btn btn-outline-secondary btn-sm"}
                  disabled={context.pageState.isRefreshBtnDisable}
                  onClick={serialPortDispatcher}
                  ref={props.serialInputButtonElement}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Serial Input"
                >
                  <FaUncharted fontSize="1.5em" />
                </button>

                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm"
                  ref={props.exitcommandButtonElement}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Disconnect"
                >
                  <FaPowerOff />
                </button>

                <div>
                  <button
                    type="button"
                    className="btn btn-light btn-sm"
                    id="switchCam"
                    disabled={context.pageState.isDisabled}
                    data-toggle="tooltip"
                    data-placement="left"
                    title="switch camera"
                  >
                    <img
                      src={cameraswitch}
                      className="camera-switch-btn"
                      alt=""
                    />
                  </button>

                  <button
                    type="button"
                    className="btn btn-light"
                    id="captureSnap"
                    onClick={() => captureSnap()}
                    disabled={context.pageState.isDisabled}
                    data-toggle="tooltip"
                    data-placement="left"
                    title="download capture"
                  >
                    <i
                      className="fa fa-download fa-lg"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>

              <div className="card card-body">
                <div className="my-3 d-none">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="form-group d-flex flex-direction-row">
                      <input
                        width={2}
                        type="text"
                        className="form-control"
                        id="commandtext"
                        placeholder="Enter command"
                      />
                      <button
                        type="button"
                        className="btn btn-primary ml-5"
                        id="sendcommands"
                        disabled={context.pageState.isDisabled}
                        ref={props.sendcommandButtonElement}
                      >
                        send
                      </button>
                    </div>
                  </form>
                </div>
                <Commands
                  isDisabled={context.pageState.isDisabled}
                  subState={context.pageState.subState}
                  gotoPropObj={props.gotoPropObj}
                  movePropObj={props.movePropObj}
                  homePositionPropObj={props.homePositionPropObj}
                  focusPropObj={props.focusPropObj}
                  requestPropObj={props.requestPropObj}
                />

                <div
                  id="select-lens-div"
                  style={{
                    border: "1px solid blue",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <p>Select Lens</p>
                  <div>
                    <button
                      id="4"
                      className={"4" === context.pageState.selectedLensButton ? "btn btn-primary m-1" : "btn btn-outline-primary m-1"}
                      ref={lens4X}
                      disabled={context.disableTools}
                    >
                      4X
                    </button>
                    <button
                      id="10"
                      className={"10" === context.pageState.selectedLensButton ? "btn btn-primary m-1" : "btn btn-outline-primary m-1"}
                      ref={lens10X}
                      disabled={context.disableTools}
                    >
                      {" "}
                      10X
                    </button>
                    <button
                      id="20"
                      className={"20" === context.pageState.selectedLensButton ? "btn btn-primary m-1" : "btn btn-outline-primary m-1"}
                      ref={lens20X}
                      disabled={context.disableTools}
                    >
                      {" "}
                      20X
                    </button>
                    <button
                      id="40"
                      className={"40" === context.pageState.selectedLensButton ? "btn btn-primary m-1" : "btn btn-outline-primary m-1"}
                      ref={lens40X}
                      disabled={context.disableTools}
                    >
                      40X
                    </button>
                    <button
                      id="100"
                      className={"100" === context.pageState.selectedLensButton ? "btn btn-primary m-1" : "btn btn-outline-primary m-1"}
                      ref={lens100X}
                      disabled={context.disableTools}
                    >
                      100X
                    </button>
                  </div>
                </div>

                <div
                  style={{ border: "1px solid blue" }}
                  className="my-3 d-none "
                >
                  <Logs PostionCoords={context.pageState.positionData} />
                </div>

                <div
                  style={{ border: "1px solid blue" }}
                  className="my-3"
                >
                  <div className="row">
                    <div className="col">
                      <b>Connection Status:</b> {sessionStorage.getItem("devicename")} {context.pageState.Connection_Msg}
                    </div>
                  </div>
                </div>
                <div
                  style={{ border: "1px solid blue" }}
                  className="my-3"
                >
                  <div className="d-flex flex-column justify-content-center align-items-center ">
                    <b>Device List</b>
                    {/* <ActiveDevices /> */}
                    <ActiveDeviceList />
                  </div>
                </div>
                <div className="d-flex justify-content-around mb-3 ">
                  <div className="col-xl-5 my-auto">
                    <button
                      type="button"
                      className="btn btn-outline-primary  btn-sm "
                      id="clear"
                      disabled={props.isDisabled}
                    >
                      Clear
                    </button>
                  </div>
                  <div className="col-xl-5 my-auto">
                    <button
                      type="button"
                      className="btn btn-outline-primary  btn-sm "
                      id="draw"
                    >
                      {isDrawEnable ? "DisableDraw" : "EnableDraw"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PageContext = createContext(null);
