import React, { useState } from "react";

const DeviceDropdown = ({ activeDevices, inactiveDevices, onCopyDeviceName }) => {
  const [selectedDevice, setSelectedDevice] = useState(null);

  const handleOptionClick = (deviceName) => {
    setSelectedDevice(deviceName);
  };

  const handleOptionDoubleClick = () => {
    if (selectedDevice) {
      onCopyDeviceName(selectedDevice);
      setSelectedDevice(null);
    }
  };

  const activeDeviceStyle = {
    padding: "8px 16px", // Adjust padding as needed
    marginBottom: "4px", // Add margin between strips
    cursor: "pointer",
    background: "lightgreen", // Green background for active devices
  };

  const inactiveDeviceStyle = {
    padding: "8px 16px", // Adjust padding as needed
    marginBottom: "4px", // Add margin between strips
    cursor: "pointer",
    background: "lightgray", // Gray background for inactive devices
  };

  return (
    <div className="mt-2" style={{maxHeight:'300px', maxWidth:'300px', overflow:'auto',border:'1px solid #ccc'}}>
    {activeDevices.map((device, index) => (
      <div
        key={index}
        style={activeDeviceStyle}
        onClick={() => handleOptionClick(device)}
        onDoubleClick={handleOptionDoubleClick}
      >
        {device}
      </div>
    ))}
    {inactiveDevices.map((device, index) => (
      <div
        key={index}
        style={inactiveDeviceStyle}
        onClick={() => handleOptionClick(device)}
        onDoubleClick={handleOptionDoubleClick}
      >
        {device}
      </div>
    ))}
  </div>
  );
};

export default DeviceDropdown;
