import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Common';

export const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) =>
      getToken() && window.location.pathname === "/Login" ? <Redirect to='/slides' />
        : !getToken() ? <Component {...props} /> : <Redirect to='/Login' />} />
  )
}
