import React, { useReducer, useEffect, createContext, useContext } from 'react'
import { useHistory } from "react-router-dom";
import { pageReducer, getActiveDeviceList } from "./Service";
import { AgGridReact } from 'ag-grid-react';


export const ActiveDevices = (props) => {
    
    const initialState = {
        activeDeviceList: [],
        modalVisible: false
    }

    const [pageState, dispatch] = useReducer(pageReducer, initialState)
    const activeRoomName=localStorage.getItem('activeroomname')
    const filteredActiveDevicelist=pageState.activeDeviceList.map(device=>{if (device!=activeRoomName){
        return device
    }})
    console.log("filteredActiveDevicelist:", filteredActiveDevicelist)
    // console.log("activeDeviceList:",pageState.activeDeviceList)
    useEffect(() => {
        // getActiveDeviceList(dispatch)
        const interval=setInterval(()=>{
            getActiveDeviceList(dispatch)
        },1000)
        return ()=>clearInterval(interval)
    }, [])

    // useEffect(() => {
    //     getActiveDeviceList(dispatch)
    // }, [])

    const pageDispatcher = {
        setModalVisible: (value) => dispatch({ type: 'set_modalVisible', payload: value })
    }

    return (
        <div className="container-fluid">
            <PageContext.Provider value={{ pageState, pageDispatcher, dispatch }}>
                <br />
                <ActiveDeviceComponent filteredActiveDevicelist={filteredActiveDevicelist}  />
            </PageContext.Provider>
        </div>
    )
}

const ActiveDeviceComponent = (props) => {
   
    let history = useHistory ();
    const context = useContext(PageContext);
    const activeRoomName=localStorage.getItem('activeroomname')

    // console.log("params:",context.pageState.activeDeviceList)
    const columnDefs = [
        {
            
            headerName: "Sr#",
            field: "id",
            sortable: true,
            suppressMovable: true,
            cellRendererFramework: (params) =>( activeRoomName!=params.data.roomname &&  <div>{params.rowIndex + 1}</div>)
        },

        // activeRoomName!=params.data.roomname
        {
            headerName: "Active Device",
            field: "devicename",
            sortable: true,
            suppressMovable: true,
            cellRendererFramework: (params) => ( activeRoomName!=params.data.roomname && <button onClick={() => {
                    console.log("onClick micalys list")
                    localStorage.setItem('devicename', params.data.devicename);
                    localStorage.setItem('roomname', params.data.roomname);
                    // history.push('/liveview/' + params.data.roomname, "_blank");
                    // console.log("hello location run")
                    // props.setRoomIdCallback(params.data.roomname)
                    window.location.reload(true)
    
                    
                }} style={{ color: "#008cff", border: "none", backgroundColor: 'white' }}>{params.data.devicename}</button>)
        },
    ]
    const onGridReady = params => {
        params.api.resetRowHeights();
    }
    return (
        <>
            {
                context && context.pageState.activeDeviceList ?
                    <div className="rtable" >
                        {/* {console.log("In Active devices UI")} */}
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                colResizeDefault='shift'
                                domLayout="autoHeight"
                                columnDefs={columnDefs}
                                rowData={context.pageState.activeDeviceList}
                                onGridReady={onGridReady}
                                
                            >
                            </AgGridReact>
                            {/* {console.log("columnDefs:",params)} */}
                        </div>
                    </div> : <div>Loading</div>

            }
        </>
    )
}
export const PageContext = createContext(null)
