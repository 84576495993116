import React, { useEffect, useContext } from "react";
import { PageContext } from "../Settings";
import { getCloudId, generateCloudId } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";

export const CloudId = () => {
  const context = useContext(PageContext);

  useEffect(() => {
    getCloudId(context.pageDispatcher);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <NotificationContainer />

      {context.pageState.cloudId ? (
        <div>
          <input
            type="password"
            value={context.pageState.cloudId}
            disabled={true}
            onChange={() => console.log()}
            id="cloudId"
          />
          <i
            className="fa fa-eye"
            style={{ cursor: "pointer" }}
            aria-hidden="true"
            id="toggleCloudId"
            onClick={() => {
              let cloudId = document.getElementById("cloudId");
              const type = cloudId.getAttribute("type") === "password" ? "text" : "password";
              cloudId.setAttribute("type", type);
            }}
          ></i>

          <br />
          <br />

          <a
            href="#!"
            onClick={() => {
              generateCloudId().then((res) => {
                if (res && res.data.Message) {
                  getCloudId(context.pageDispatcher);
                  NotificationManager.success("updated", "", 2000);
                  // window.location.reload()
                }
              });
            }}
          >
            Re-generate CloudId.
          </a>
        </div>
      ) : (
        <div>
          You don't have a cloudID <br />
          <a
            href="#!"
            onClick={() => {
              generateCloudId().then((res) => {
                if (res && res.data.Message) {
                  getCloudId(context.pageDispatcher);
                  NotificationManager.success("updated", "", 2000);
                  window.location.reload();
                }
              });
            }}
          >
            Click here to generate.
          </a>
        </div>
      )}
    </>
  );
};
