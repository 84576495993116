import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useState } from "react";
import { initializeFirebaseUI } from "../../Auth/Account";
import firebase from "firebase/app"
import "firebase/auth"

export const ChangePassword = () => {

    const [showInstruction, setShowInstruction] = useState(false)

    const resetPassword = () => {
        initializeFirebaseUI('')
        const auth = firebase.auth()
        let email = localStorage.getItem('email');

        auth.sendPasswordResetEmail(email)
            .then(() => {
                NotificationManager.success(<b>Please check your email for reset password.</b>, '', 2000);
                setShowInstruction(true)
                setTimeout(() => {
                    setShowInstruction(false)
                }, 6000)
            })
            .catch((error) => {
                // const errorCode = error.code;
                const errorMessage = error.message;
                NotificationManager.error(errorMessage, '', 7000)
            });
    }
    return (
        <div>
            <NotificationContainer />
            <a href="#!" onClick={() => resetPassword()}>Click to reset your password.</a>
            <br />
            {
                showInstruction &&

                <span style={{ color: 'red' }}><b>Please Log Off and Login Again if you've reset your password.</b></span>

            }
        </div>
    )
}