import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import { getName } from "../../components/Utils/Common";
import { environment } from "../../environment";
import { logout } from "../Auth/Account";


export const Navigation = () => {
    const history = useHistory()

    useEffect(() => {
        if (window.location.pathname === "/") {
            history.push("/slides")
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="">
            <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: environment.REACT_APP_SERVER_TYPE === "PRODUCTION" ? '#3f51b5' : "black" }}>

                <Nav.Link as={Link} to="/slides" className="navbar-brand" style={{ color: "#fff" }}>MICALYS</Nav.Link>


                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav mr-auto" style={{
                        lineHeight: "45px",
                        backgroundColor: "transparent",
                        textTransform: "upperCase",
                        fontSize: "13px",
                    }}>
                        {/* <li className="nav-item">
                            <Nav.Link as={Link} to="/groups">Groups</Nav.Link>
                        </li> */}
                        <li className="nav-item">
                            <p style={{ color: "#008cff", border: "none", cursor:'pointer' }} onClick={() => {
                                const win = window.open('/liveview/', "_blank");
                                win.focus();
                            }}>Remote</p>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown active">
                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-user-circle-o fa-lg" aria-hidden="true"></i> &nbsp;{getName()}
                            </a>
                            <div className="dropdown-menu  dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                                <Link className="dropdown-item" to="/groups"><i className="fa fa-users" aria-hidden="true"></i> &nbsp;My Groups</Link>
                                <Link className="dropdown-item" to="/settings"><i className="fa fa-cog fa-lg" aria-hidden="true"></i> &nbsp;Settings</Link>
                                <Link className="dropdown-item" to="#" onClick={() => logout(history)}><i className="fa fa-sign-out fa-lg" aria-hidden="true"></i> &nbsp;Logout</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

        </div>

    )
}
