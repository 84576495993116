import React, { useEffect, useState } from "react";
import { getDeviceSubscriptionStatus, updateDeviceSubscription } from "../Service";
import { BsToggleOn } from "react-icons/bs";
import { BsToggleOff } from "react-icons/bs";
import { NotificationContainer, NotificationManager } from "react-notifications";


const DeviceSubscription = () => {
  const [deviceSubscriptionStatus, setDeviceSubscriptionStatus] = useState({});

  useEffect(() => {
    async function fetchDeviceNames() {
      try {
        const response = await getDeviceSubscriptionStatus();
        setDeviceSubscriptionStatus(response.data["device_subscription_status"]);
      } catch (error) {
        console.error("Error fetching device names", error);
      }
    }
    fetchDeviceNames();
  }, []);

  const updateDeviceSubscriptionStatus = async (deviceName, subscriptionStatus) => {
    let newValue;
    if (subscriptionStatus) {
      newValue = 0;
    } else {
      newValue = 1;
    }
    let obj = { device_name: deviceName, subscription_status: newValue };
    try {
      const response = await updateDeviceSubscription(obj);
      if (response.status === 200) {
        // Update state only if the response is successful
        setDeviceSubscriptionStatus((prevStatus) => ({
          ...prevStatus,
          [deviceName]: response.data.device_active_status,
        }));
      } else {
        NotificationManager.error("Error!", "", 2000);
        console.error(`Failed to update device subscription status for ${deviceName}. Status code: ${response.status}`);
        // Optionally, you can revert the toggle switch state here if needed
      }
    } catch (error) {
        NotificationManager.error("Error!", "", 2000);
      console.error(`Error fetching config data for ${deviceName}:`, error);
    }
  };

  const handleToggle = (deviceName, subscriptionStatus) => {
    updateDeviceSubscriptionStatus(deviceName, subscriptionStatus);
  };
  return (
    <>
    <NotificationContainer />
    <div style={{ textAlign: "center" }}>
      <h2>Device Active Status</h2>
      <table style={{ margin: "auto" }}>
        <thead>
          <tr>
            <th style={{ minWidth: "250px" }}>Device Name</th>
            <th style={{ minWidth: "150px" }}>Subscription</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(deviceSubscriptionStatus).map(([deviceName, subscriptionStatus]) => (
            <tr key={deviceName}>
              <td style={{ color: subscriptionStatus === 1 ? "#007bff" : "inherit" }}>{deviceName}</td>
              <td>
                {subscriptionStatus ? (
                  <BsToggleOn
                    style={{ color: "#007bff", fontSize: "24px" }}
                    onClick={() => handleToggle(deviceName, subscriptionStatus)}
                  />
                ) : (
                  <BsToggleOff
                    onClick={() => handleToggle(deviceName, subscriptionStatus)}
                    style={{ color: "#007bff", fontSize: "24px" }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default DeviceSubscription;
