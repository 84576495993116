import { useEffect, useState } from 'react';
import { About } from "./About";
import { Slides } from "./Slides";
import { Tabs, Tab } from 'react-bootstrap'
import { PageTitle } from "../../shared/PageTitle";
import { getSlides,getGroupJWT } from "../Service";
import { setGroupToken } from "../../Utils/Common";

export const GroupsNav = (props) => {
    let groupid = props.urlData.groupid;

    const initialState = {
        groupname: '',
        groupadmin: '',
        totalmembers: 0,
        allowPaging: true,
        slideData: [],
        number_of_items: 10,
        index: 0,
        totalRecords: 0,
        msg: undefined
    }

    const [myGroupState, setMyGroupState] = useState(initialState)

    useEffect(() => {
        let checkshowmyslide = localStorage.getItem('showmyslides');
        let obj = {};
        if (checkshowmyslide && checkshowmyslide === 'true') {
            obj['showmyslides'] = true
        }
        else {
            obj['showmyslides'] = false
        }

        getGroupJWT(groupid).then(response => {
            if (response && response.data.group_token) {
                setGroupToken(response.data.group_token);
                // pageDispatcher.setOtherGroupData(res.data)  there's no reach of group conext here..it is showing null.

                getSlides(myGroupState.index, myGroupState.number_of_items, obj).then(res => {
                    if (res && res.data.slide_data) {
                        setMyGroupState({
                            ...myGroupState, slideData: res.data.slide_data,
                            index: res.data.index,
                            totalRecords: res.data.totalRecords,
                            totalmembers: res.data.total_members,
                            groupname: res.data.groupotherdata.groupname,
                            groupadmin: res.data.groupotherdata.admin
                        })
                    }
                    if (res.data.message) {
                        setMyGroupState({ ...myGroupState, msg: res.data.message, groupname: res.data.groupotherdata.groupname, groupadmin: res.data.groupotherdata.admin })
                    }
                })
            }
        })

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <PageTitle title={myGroupState.groupname} backLink='/groups'></PageTitle>
            <br />
            <div>
                <Tabs defaultActiveKey="slides" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="slides" title="Slides">
                        <Slides state={{ myGroupState, setMyGroupState }} />
                    </Tab>
                    <Tab eventKey="about" title="About">
                        <About state={{ myGroupState, setMyGroupState }} />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )

}
