import React, { useEffect, useContext, useState } from "react";
import { PageContext } from "../Settings";
import { generateOtp, getOtp } from "../Service";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { ProgressBar } from "react-bootstrap";
import DeviceNameInput from "./DeviceNameInput";

const DeviceRegistration = () => {
  const context = useContext(PageContext);


  return (
    <>
     
     <DeviceNameInput />
    </>
  );
};

export default DeviceRegistration;
