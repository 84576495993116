import React, { createContext, useEffect, useReducer } from "react"
import { GroupList } from "./Components/GroupList";
import { pageReducer, myGroups } from "./Service";


export const Groups = () => {

    useEffect(() => {
        myGroups().then(res => {
            if (res && res.data.group_list) {
                pageDispatcher.setGroupList(res.data.group_list)
            }
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const initialState = {
        groupList: [],
        groupname:'',
        totalmembers:0
    };

    const pageDispatcher = {
        setOtherGroupData: (data) => dispatch({ type: 'set_otherGroupData', payload: data }),
        setGroupList: (data) => dispatch({ type: 'setGroupList', payload: data }),
    }

    const [pageState, dispatch] = useReducer(pageReducer, initialState)

    return (
        <div className='container-fluid'>
            <PageContext.Provider value={{ pageState, pageDispatcher }}>
                <div style={{ marginTop: '10px' }}>
                    <GroupList />
                </div>

                {/* 
                <Row style={{ marginTop: '10px' }}>
                    <Col sm={2}>
                        <GroupList />
                    </Col>

                    <Col sm={10} style={{ borderLeft: '1px dashed #333' }}>
                                <GroupsNav />
                    </Col>
                </Row> */}
            </PageContext.Provider>

        </div>
    )
}

export const PageContext = createContext(null)



