import React from 'react'

export const Pagination = (props) => {
    const state = props.initialState
    const totalPages = Math.ceil(state.totalRecords / state.number_of_items).toFixed()
    let showCurrentPage;
    if (state.index === 0) {
        showCurrentPage = 1
    }
    else {
        showCurrentPage = state.index + 1
    }
    return (
        <div style={{ float: 'right' }}>
            {
                state.allowPaging === true && (
                    <div className="pagination row" style={{ display: 'inline-block' }}>
                        <button className="btn btn-link col-xs-6" onClick={() => props.refreshListwithSearch()} >Refresh</button>

                        <span className="col-xs-6">
                            |{' '}Page
                            {' '}
                            <strong>
                                {showCurrentPage} of {state.totalRecords <= state.index ? 0 : totalPages}
                            </strong>  {' '}| Total{' '} <strong>{state.totalRecords}</strong> |
                        </span>

                        <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(0)}
                            disabled={state.index === 0} >
                            {'First'}
                        </button>

                        <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(state.index - 1)}
                            disabled={state.index === 0}
                        >
                            {'Prev -'}
                        </button>


                        <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(state.index + 1)}

                            disabled={totalPages <= 0 || state.index >= totalPages - 1}
                        >
                            {'- Next'}
                        </button>

                        <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(totalPages - 1)}
                            disabled={totalPages <= 0 || state.index >= totalPages - 1}
                        >
                            {'Last'}
                        </button>
                    </div>
                )

            }

        </div>
    )
}

// import React from 'react'

// // json-server --watch dummySlideList.json --port 7000
// export const Pagination = (props) => {
//     const state = props.initialState
//     const totalPages = Math.ceil(state.totalRecords / state.number_of_items).toFixed()
//     let showCurrentPage;
//     showCurrentPage =state.index
//     // if (state.index === 0) {
//     //     showCurrentPage = 1;
//     // }
//     // else {
//     //     showCurrentPage = state.index + 1
//     // }
//     return (
//         <div style={{ float: 'right' }}>
//             {
//                 state.allowPaging === true && (
//                     <div className="pagination row" style={{ display: 'inline-block' }}>
//                         <button className="btn btn-link col-xs-6" onClick={() => props.refreshListwithSearch()} >Refresh</button>

//                         <span className="col-xs-6">
//                             |{' '}Page
//                             {' '}
//                             <strong>
//                                 {showCurrentPage} of {state.totalRecords <= state.index ? 1 : totalPages}
//                             </strong>  {' '}|
//                         </span>

//                         <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(1)}
//                             disabled={state.index === 1} >
//                             {'First'}
//                         </button>

//                         <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(state.index - 1)}
//                             disabled={state.index === 1}
//                         >
//                             {'Prev -'}
//                         </button>


//                         <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(state.index + 1)}

//                             disabled={totalPages <= 1 || state.index >= totalPages}
//                         >
//                             {'- Next'}
//                         </button>

//                         <button className="btn btn-link col-xs-3" onClick={() => props.setIndex(totalPages)}
//                             disabled={totalPages <= 1 || state.index >= totalPages}
//                         >
//                             {'Last'}
//                         </button>
//                     </div>
//                 )

//             }

//         </div>
//     )
// }