import { useHistory } from 'react-router-dom'
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import React from 'react'
import { getToken, setAuthData, removeCookie } from "../Utils/Common";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { environment } from "../../environment";
import axios from 'axios';
import firebase from "firebase/app"
import "firebase/auth"


const baseURL = environment.MICALYS_POSTGRES_API

let uiConfig;
export const initializeFirebaseUI = (history) => {
    let config = {
        apiKey: "AIzaSyAoFDLyMkd9k3H4LpVB6XEzmg5j_hQJzxc",
        authDomain: "micalys-regelar.firebaseapp.com",
    }
    let config_production = {
        apiKey: "AIzaSyBbL__JbSF_knmL4JEgSmC66x545cCLNrg",
        authDomain: "micalys-medprime.firebaseapp.com",
    }
    if (environment.REACT_APP_SERVER_TYPE === "PRODUCTION") {
        !firebase.apps.length ? firebase.initializeApp(config_production) : firebase.app()

    } else {
        !firebase.apps.length ? firebase.initializeApp(config) : firebase.app()
    }

    uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            signInSuccessWithAuthResult: (authResult) => {
                const successResponse = () => {

                    const userInfo = authResult.additionalUserInfo;

                    // step:1--check if the user is new and signed in with password then send email for verification
                    if (userInfo.isNewUser && userInfo.providerId === "password") {
                        try {
                            authResult.user.sendEmailVerification();
                            NotificationManager.info('Verication mail has been sent!')
                            setTimeout(() => {
                                window.location.reload(1)
                            }, 2000)
                        } catch (e) {
                            console.log(e);
                        }
                    }

                    function checkIfEmailVerified() {
                        if (authResult.user.emailVerified) {
                            return true
                        }
                    }
                    //step:2--now isNewUser is false & check whether user has verified its email or not while logging in with passorwd
                    if (!userInfo.isNewUser && userInfo.providerId === "password") {
                        if (!checkIfEmailVerified()) {
                            NotificationManager.error("we've sent you an email.please verify first!");
                            setTimeout(() => {
                                window.location.reload(1)
                            }, 2000)
                        }
                    }

                    //step:3--allow login

                    if (checkIfEmailVerified()) {
                        authResult.user.getIdToken().then(res => {

                            // console.log("idToken",res)
                            axios.post(baseURL + 'login', { idtoken: res })
                                .then(async res => {
                                    if (res.data.Message) {
                                        NotificationManager.info(res.data.Message, '', 2000)
                                        setTimeout(() => {
                                            window.location.reload(1)
                                        }, 2000)
                                    }
                                    if (res.data.result) {
                                        NotificationManager.success('Successfully Logged in!', '', 2000);
                                        localStorage.setItem('providerId', userInfo.providerId);

                                        setTimeout(() => {
                                            const authData = {
                                                "token": res.data.result.access_token,
                                                "isAdmin": res.data.result.isAdmin,
                                                "username": firebase.auth().currentUser.displayName
                                            }
                                            // console.log("username",authData.username)

                                            localStorage.setItem('hasDevice', res.data.result.hasDevice);
                                            localStorage.setItem('isActive', res.data.result.isActive);
                                            localStorage.setItem('email', res.data.result.email);
                                            localStorage.setItem('isMaint', res.data.result.isMaint);

                                            // console.log("authData", authData)
                                            setAuthData(authData);
                                            history.push({ pathname: '/slides', state: { isToken: getToken() } })
                                        }, 700)
                                    }

                                })
                        }).catch(err => {
                            NotificationManager.error(err.message, '', 2000);
                        })
                    }

                }
                successResponse()
            }
        },
    }
}

export const Account = () => {
    const history = useHistory()
    initializeFirebaseUI(history);

    // useEffect(()=>{
    //     console.log("connect!")
    // },[])

    return (
        <div className='container-fluid'>
            <nav className="navbar navbar-expand-sm navbar-light bg-light">
                <h3 style={{ color: 'GrayText' }}><b>MICALYS</b></h3>
            </nav>
            <NotificationContainer />
            <div className="form-gap" style={{ paddingTop: '70px' }}></div>
            <div className="container" style={{ marginTop: '130px' }}>
                <div className="row justify-content-center">
                    <div className="col-md-4 col-md-offset-4">
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <div className="text-center">
                                    <h3 style={{ color: '#3f51b5' }}><i className="fa fa-user-circle-o fa-3x" aria-hidden="true"></i></h3>
                                    <div className="panel-body">
                                        <StyledFirebaseAuth
                                            uiConfig={uiConfig}
                                            firebaseAuth={firebase.auth()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const logout = (history) => {
    initializeFirebaseUI(history || '');   //imp

    firebase.auth().signOut()
        .then(() => {
            localStorage.clear();
            sessionStorage.clear();
            removeCookie();

            if (window.opener) {
                window.opener.close();
                window.opener.location.reload();
            }
            // history.push({ pathname: '/Login' })
            window.location.replace('/Login')
            NotificationManager.success('Successfully Signed out', '', 2000)
            window.location.reload(1)
            // firebase.auth().clearPersistence()

        }).catch((error) => {
            NotificationManager.error(error.message, '', 5000)
        })

}
