import React, { useReducer, useEffect, createContext, useRef, useState, useContext } from "react";
import { PageTitle } from "../../shared/PageTitle";
import { pageReducer, getSlideToken, getSlideViewerData, getScaleData, getannotated, addupdatescale, getFormUrl, getPdfFromCloud } from "../Service";
import { environment } from "../../../environment";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "ol/ol.css";
import Map from "ol/Map";
import TileLayer from "ol/layer/WebGLTile";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Zoomify from "ol/source/Zoomify";
import Cropper from "cropperjs";
import TileState from "ol/TileState";
import { Button, Modal, Image, Container, Row, Form } from "react-bootstrap";
// import { ReactPDF } from "react-pdf";
import { OverviewMap } from "ol/control";
import FullScreen from "ol/control/FullScreen";
import Overlay from "ol/Overlay";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { LineString, Polygon } from "ol/geom";
import Draw from "ol/interaction/Draw";
import { unByKey } from "ol/Observable";
import { ScaleLine } from "ol/control";
// import olMapScreenshot from 'ol-map-screenshot';
import Projection from "ol/proj/Projection";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import axios from "axios";
import Loader from "react-loader-spinner";

// const zoomifyURL = 'https://storage.googleapis.com/roopa_demo_bucket/slides/'

const zoomifyURL = environment.Micalys_Image_Server + "get_slide_img/";

export const SlideView = (props) => {
  const slide_id = props.urlData.id;

  const mapElement = useRef(null);
  const selectedElement = useRef(null);
  const zoomLevelElement = useRef(null);
  const imageElement = useRef(null);

  const [scaleModalVisible, set_scaleModalVisible] = useState(false);
  const [imageToPdf, setImageToPdf] = useState("");
  const [scaleValue, set_ScaleValue] = useState();

  const initialState = {
    slideInfo: {
      slideName: "",
      createdDate: "",
      slideToken: "",
    },
    disableTools: true,
    overviewModalVisible: false,
    wsImage: "",
  };

  const pageDispatcher = {
    set_slideInfo: (data) => dispatch({ type: "set_slideInfo", payload: data }),
    set_disableTools: (value) => dispatch({ type: "set_disableTools", payload: value }),
    set_overviewModalVisible: (value) => dispatch({ type: "set_overviewModalVisible", payload: value }),
    set_wsImage: (value) => dispatch({ type: "set_wsImage", payload: value }),
  };
  const [pageState, dispatch] = useReducer(pageReducer, initialState);

  const prepareSlideViewerData = (slide_id, slide_access_token, scaleData) => {
    getSlideViewerData(slide_id, slide_access_token)
      .then((res) => {
        if (res && res.data) {
          // console.log("scaleData", scaleData)
          NotificationManager.success("success!", "", 2000);

          var parser = new DOMParser();
          var xmlDoc = parser.parseFromString(res.data, "text/xml");
          var elements = xmlDoc.getElementsByTagName("IMAGE_PROPERTIES");
          var tileSize = Number(elements[0].getAttribute("TILESIZE"));

          if (elements[0].getAttribute("WIDTH") != null && elements[0].getAttribute("HEIGHT") != null) {
            var imgWidth = Number(elements[0].getAttribute("WIDTH"));
            var imgHeight = Number(elements[0].getAttribute("HEIGHT"));
            // console.log("imgeheight", imgHeight);
            // console.log("imgWidth", imgWidth)
          }

          let draw, measureTooltipElement, measureTooltip, helpTooltipElement, helpTooltip, sketch;
          var typeSelect = document.getElementById("selectedElement");

          var source = new Zoomify({
            url: zoomifyURL + slide_id + "/",
            size: [imgWidth, imgHeight],
            crossOrigin: "anonymous",
            zDirection: -1,
            tileSize: tileSize / 1,
            tilePixelRatio: 1,
          });
          var extent = source.getTileGrid().getExtent();

          const variables = {
            exposure: 0,
            contrast: 0,
            saturation: 0,
            brightness: 0,
          };

          var layer = new TileLayer({
            style: {
              exposure: ["var", "exposure"],
              contrast: ["var", "contrast"],
              saturation: ["var", "saturation"],
              brightness: ["var", "brightness"],
              variables: variables,
            },
            source: source,
          });
          var vector_layer = new VectorLayer({
            name: "my_vectorlayer",
            source: new VectorSource({
              wrapX: false,
            }),
            style: new Style({
              fill: new Fill({
                color: "rgba(255, 255, 255, 0.2)",
              }),
              stroke: new Stroke({
                color: "#ff0000",
                width: 2,
              }),
              image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                  color: "#ff0000",
                }),
              }),
            }),
          });

          function createHelpTooltip() {
            if (helpTooltipElement) {
              helpTooltipElement.parentNode.removeChild(helpTooltipElement);
            }
            helpTooltipElement = document.createElement("div");
            helpTooltipElement.className = "ol-tooltip hidden";

            helpTooltip = new Overlay({
              element: helpTooltipElement,
              offset: [15, 0],
              positioning: "center-left",
            });
            initialMap.addOverlay(helpTooltip);
          }
          function createMeasureTooltip() {
            if (measureTooltipElement) {
              measureTooltipElement.parentNode.removeChild(measureTooltipElement);
            }
            measureTooltipElement = document.createElement("div");
            measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
            measureTooltip = new Overlay({
              element: measureTooltipElement,
              offset: [0, -15],
              positioning: "bottom-center",
              stopEvent: false,
              insertFirst: false,
            });
            initialMap.addOverlay(measureTooltip);
          }
          function addInteraction(selectedElement) {
            if (selectedElement && selectedElement.id) {
              if (selectedElement.id !== "none") {
                const type = selectedElement.id === "area" ? "Polygon" : "LineString";

                draw = new Draw({
                  source: vector_layer.getSource(),
                  type: type,
                  style: new Style({
                    fill: new Fill({
                      color: "rgba(255, 255, 255, 0.2)",
                    }),
                    stroke: new Stroke({
                      color: "red",
                      lineDash: [10, 10],
                      width: 2,
                    }),
                    image: new CircleStyle({
                      radius: 5,
                      stroke: new Stroke({
                        color: "yellow",
                      }),
                      fill: new Fill({
                        color: "#ffcc33",
                      }),
                    }),
                  }),
                });

                createMeasureTooltip();
                createHelpTooltip();
                let listener;

                draw.on("drawstart", function (evt) {
                  // set sketch
                  sketch = evt.feature; //getDrawnFeatureObj i.e Feature{..}
                  listener = sketch.getGeometry().on("change", function (event) {});
                });

                draw.on("drawend", function (event) {
                  let geomType = event.feature.getGeometry();
                  let coordinates, output, tooltipCoord;
                  let arr = [];
                  let X, Y;
                  let Xarr = [],
                    Yarr = [];

                  if (geomType.getType() === "Polygon") {
                    coordinates = event.feature.getGeometry().getCoordinates();
                    coordinates = coordinates[0];
                    coordinates.forEach((el) => {
                      // pixelCoord = initialMap.getPixelFromCoordinate([el[0], el[1]]);
                      X = Math.round(el[0]);
                      Y = Math.round(el[1]);
                      arr.push([X, Y]);
                    });
                    // console.log("arr", arr);
                    arr.forEach((el) => {
                      Xarr.push(el[0]);
                      Yarr.push(el[1]);
                    });

                    output = polygonArea(Xarr, Yarr, Xarr.length);
                    tooltipCoord = geomType.getInteriorPoint().getCoordinates();

                    // console.log("output of Polygon", output)
                  } else if (geomType.getType() === "LineString") {
                    coordinates = event.feature.getGeometry().getCoordinates();
                    coordinates.forEach((el) => {
                      // pixelCoord = initialMap.getPixelFromCoordinate([el[0], el[1]]);

                      // X = Math.round(pixelCoord[0]);
                      // Y = Math.round(pixelCoord[1]);

                      X = Math.round(el[0]);
                      Y = Math.round(el[1]);

                      arr.push([X, Y]);
                    });
                    output = lineStringLength(arr);
                    // console.log("output of Linestring", output)
                    tooltipCoord = geomType.getLastCoordinate();
                  }
                  measureTooltipElement.innerHTML = output;
                  measureTooltip.setPosition(tooltipCoord);

                  measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
                  measureTooltip.setOffset([0, -7]);
                  // unset sketch
                  sketch = null;
                  // unset tooltip so that a new one can be created
                  measureTooltipElement = null;
                  createMeasureTooltip();
                  unByKey(listener);
                });
                initialMap.addInteraction(draw);
              }
            }
          }

          // *******************************************************ScaleLine Feature Start*****************************************************************************************//

          const scaleControl = new ScaleLine({
            //adding scaleLine
            units: "metric",
          });

          let px_To_mmVal = 1 / scaleData;

          let proj = new Projection({
            //this step is needed to set micro scale on map.
            units: "pixels",
            extent: [0, 0, imgWidth, imgHeight],
            metersPerUnit: px_To_mmVal / 1000000, //(this is in micro unit)=>here converted px to mm & then mm to micro meter to get value
            // global: true   //by adding this,overview map won't xoom while zooming parent map
          });

          // *******************************************************ScaleLine Feature Start*****************************************************************************************//

          // ******************************************************Overview Feature Start*******************************************************************************************//

          let overviewMapControl = new OverviewMap({
            className: "ol-overviewmap ol-custom-overviewmap",
            layers: [
              new TileLayer({
                source: source,
              }),
            ],
            // autoPan:true,
            collapseLabel: "\u00AB",
            label: "\u00BB",
            collapsed: true,
            collapsible: true,
            view: new View({
              resolutions: layer.getSource().getTileGrid().getResolutions(),
              // extent: extent,
              extent: [imgWidth, 0, 0, -imgHeight],
              projection: scaleData ? proj : "",
              constrainOnlyCenter: true,
              // center: [0, 0],
              // center: [500000, 6000000],
              maxZoom: 0,
              minZoom: 0,
              zoom: 0,
            }),
          });

          // ******************************************************Overview Feature End*****************************************************************************************//

          source.setTileLoadFunction(function (tile, src) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.addEventListener("loadend", function (evt) {
              var data = this.response;
              if (data !== undefined) {
                tile.getImage().src = URL.createObjectURL(data);
              } else {
                tile.setState(TileState.ERROR);
              }
            });
            xhr.addEventListener("error", function () {
              tile.setState(TileState.ERROR);
            });
            xhr.open("GET", src);
            xhr.setRequestHeader("Authorization", "Bearer " + slide_access_token);

            xhr.send();
          });
          const continueLineMsg = "Click to continue drawing the line";
          const continuePolygonMsg = "Click to continue drawing the polygon";

          const pointerMoveHandler = function (evt) {
            createMeasureTooltip();
            createHelpTooltip();

            if (evt.dragging) {
              return;
            }

            let helpMsg = "Click to start drawing";

            if (sketch) {
              const geom = sketch.getGeometry();
              if (geom instanceof Polygon) {
                helpMsg = continuePolygonMsg;
              } else if (geom instanceof LineString) {
                helpMsg = continueLineMsg;
              }

              helpTooltipElement.innerHTML = helpMsg;
              helpTooltip.setPosition(evt.coordinate);
              helpTooltipElement.classList.remove("hidden");
            }
          };

          // *******************************************************Initialize Map Start*****************************************************************************************//

          var initialMap = new Map({
            layers: [layer, vector_layer],
            loadTilesWhileAnimating: true,
            loadTilesWhileInteracting: true,
            target: mapElement.current,
            // interactions: interactions,
            view: new View({
              resolutions: layer.getSource().getTileGrid().getResolutions(),
              extent: extent,
              // extent: [imgWidth, 0, 0, -imgHeight],
              projection: proj,
              zoom: 7,
              constrainOnlyCenter: true,
              enableRotation: false,
              // center: [0, 0],
              center: [500000, 6000000],
            }),
          });

          if (scaleData) {
            initialMap.addControl(scaleControl);
          }
          overviewMapControl.getOverviewMap().on("click", function (event) {
            initialMap.getView().setCenter(event.coordinate);
          });
          initialMap.addControl(overviewMapControl);
          initialMap.addControl(new FullScreen());
          initialMap.getView().fit(extent);

          // console.log("current zoom", initialMap.getView().getZoom())

          const polygonArea = function (X, Y, n) {
            let area = 0.0;
            let j = n - 1;
            for (let i = 0; i < n; i++) {
              area += (X[j] + X[i]) * (Y[j] - Y[i]);
              j = i;
            }

            let val = Math.abs(area / 2.0);

            if (!Number.isInteger(val)) {
              val.toFixed(2);
            }

            let output;
            if (scaleData) {
              output = `${(val / Math.pow(scaleData, 2)).toFixed(2)} µm<sup>2</sup>`;
            } else {
              output = `${val} px<sup>2</sup>`;
            }

            return output;
          };

          const lineStringLength = function (lineCoords) {
            let output, data;
            let dataarr = [];
            for (let i = 0; i < lineCoords.length; i++) {
              let xn = lineCoords[i][0];
              let yn = lineCoords[i][1];

              if (lineCoords[i + 1] !== undefined) {
                let xinc = lineCoords[i + 1][0];
                let yinc = lineCoords[i + 1][1];

                // console.log("(x(n+1)-xn):", (xinc - xn))
                // console.log("(y(y+1)-yn):", (yinc - yn))

                data = Math.sqrt(Math.pow(xinc - xn, 2) + Math.pow(yinc - yn, 2));
                dataarr.push(data);
              }
            }

            let sum = dataarr.reduce(function (accumulator, currentValue) {
              return accumulator + currentValue;
            });

            let val = Math.round(sum);

            if (!Number.isInteger(val)) {
              val.toFixed(2);
            }

            if (scaleData) {
              output = `${(val / scaleData).toFixed(2)} µm`;
            } else {
              output = `${val} px`;
            }

            return output;
          };

          // *******************************************************Measure Feature Start*****************************************************************************************//
          if (typeSelect) {
            typeSelect.addEventListener("click", function (event) {
              initialMap.removeInteraction(draw);

              if (event.target.id !== "none") {
                initialMap.on("pointermove", pointerMoveHandler);
                createMeasureTooltip();
                createHelpTooltip();
                addInteraction(event.target);
              } else {
                sketch = null;
                initialMap.removeOverlay(helpTooltip);
                initialMap.removeOverlay(measureTooltip);
              }
            });
          }

          // *******************************************************Measure Feature End*****************************************************************************************//

          // *******************************************************Download View Feature Start*****************************************************************************************//

          const mapScreenshotParam = {
            // dim: [100, 70],
            format: "jpeg",
          };

          let exp_btn = document.getElementById("export-png");

          if (exp_btn) {
            exp_btn.onclick = async () => {
              // doDonwload("map-screenshot.jpg");
              doDonwload("map.png");
            };
          }

          async function doDonwload(fileName) {
            const response = await doScreenshot(fileName);
            // console.log("response:", response)
            // const element = document.createElement("a");
            // element.setAttribute("href", response);
            // element.setAttribute("download", fileName);
            // element.style.display = "none";
            // document.body.appendChild(element);
            // element.click();
            // document.body.removeChild(element);
          }

          async function doScreenshot(fileName) {
            // try {
            //     return await olMapScreenshot.getScreenshot(initialMap, mapScreenshotParam);
            // } catch (ex) {
            //     alert(ex.message);
            // }
            var rtval;
            // document.getElementById('export-png').addEventListener('click', function () {
            initialMap.once("rendercomplete", function () {
              const mapCanvas = document.createElement("canvas");
              const size = initialMap.getSize();
              mapCanvas.width = size[0];
              mapCanvas.height = size[1];
              const mapContext = mapCanvas.getContext("2d");
              Array.prototype.forEach.call(initialMap.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"), function (canvas) {
                if (canvas.width > 0) {
                  const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
                  mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
                  let matrix;
                  const transform = canvas.style.transform;
                  if (transform) {
                    // Get the transform parameters from the style's transform matrix
                    matrix = transform
                      .match(/^matrix\(([^\(]*)\)$/)[1]
                      .split(",")
                      .map(Number);
                  } else {
                    matrix = [parseFloat(canvas.style.width) / canvas.width, 0, 0, parseFloat(canvas.style.height) / canvas.height, 0, 0];
                  }
                  // Apply the transform to the export map context
                  CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
                  const backgroundColor = canvas.parentNode.style.backgroundColor;
                  if (backgroundColor) {
                    mapContext.fillStyle = backgroundColor;
                    mapContext.fillRect(0, 0, canvas.width, canvas.height);
                  }
                  mapContext.drawImage(canvas, 0, 0);
                }
              });

              mapContext.globalAlpha = 1;
              mapContext.setTransform(1, 0, 0, 1, 0, 0);
              rtval = mapCanvas.toDataURL();
              // console.log(1);
              const element = document.createElement("a");
              element.setAttribute("href", mapCanvas.toDataURL());
              element.setAttribute("download", fileName);
              element.style.display = "none";
              document.body.appendChild(element);
              element.click();

              //   const link = document.getElementById('image-download');
              //   link.href = mapCanvas.toDataURL();
              //   link.click();
            });
            initialMap.renderSync();
            // console.log(3);

            //   }
            //   );
            // console.log(2);
          }
          // *******************************************************Download View Feature End*****************************************************************************************//

          initialMap.on("moveend", () => {
            // var zoom = initialMap.getView().getZoom();
            // console.log("min zoom", zoom);
            // console.log("maxZoom", initialMap.getView().getMaxZoom()
            // )
            // var view = initialMap.getView();
            // // console.log("view", view);
            // var resolution = view.getResolution();
            // // alert(resolution)
            // console.log("resolution", resolution)
            // var resolutionss = view.getResolutions();
            // console.log("resolutionss", resolutionss)
          });

          let dynamicResolutionBtns = document.getElementById("dynamicResolution");

          if (dynamicResolutionBtns) {
            let resScale = scaleData / 5.275;
            var Resdict = {
              "80X": 1 * resScale,
              "40X": 1.7855350008023005 * resScale,
              "20X": 2.231796258402592 * resScale,
              "10X": 4.523187969088357 * resScale,
              "4X": 8.342052841421221 * resScale,
              fit: 106.34897959183674 * resScale,
            };

            dynamicResolutionBtns.addEventListener("click", (event) => {
              let resolutionVal = event.target.value;
              initialMap.getView().setResolution(Resdict[resolutionVal].toString());

              // switch (resolutionVal) {
              //     case '80X':
              //         initialMap.getView().setResolution('1')
              //         break;
              //     case '40X':
              //         initialMap.getView().setResolution('1.7855350008023005')
              //         break;
              //     case '20X':
              //         initialMap.getView().setResolution('2.231796258402592')
              //         break;
              //     case '10X':
              //         initialMap.getView().setResolution('4.523187969088357')
              //         break;
              //     case '4X':
              //         initialMap.getView().setResolution('8.342052841421221')
              //         break;
              //     case 'fit':
              //         initialMap.getView().setResolution('106.34897959183674')
              //         break;
              //     default:
              //     // code block
              // }
            });
          }
          // *******************************************************Initialize Map End End*****************************************************************************************//

          let variable;
          const updates = {};

          function setStyleToTiles() {
            for (variable in variables) {
              const name = variable;
              const element = document.getElementById(name);

              const value = variables[name];
              element.value = value.toString();
              document.getElementById(name + "-value").innerText = value.toFixed(2);

              const listener = function (event) {
                const value = parseFloat(event.target.value);
                document.getElementById(name + "-value").innerText = value.toFixed(2);
                updates[name] = value;
                layer.updateStyleVariables(updates);
              };
              element.addEventListener("input", listener);
              element.addEventListener("change", listener);

              document.getElementById("resettilestyle").addEventListener("click", () => {
                element.value = "0.00";
                document.getElementById(name + "-value").innerText = "0.00";
                updates[name] = 0;
                layer.updateStyleVariables(updates);
              });
            }
          }

          setStyleToTiles();
        }
      })
      .catch((e) => {
        NotificationManager.error("Error!", "", 2000);
      });
  };

  useEffect(() => {
    if (slide_id) {
      getSlideToken(slide_id)
        .then((res) => {
          if (res && res.data && res.data.slide_token) {
            let slide_access_token = res.data.slide_token;
            pageDispatcher.set_slideInfo(res.data);
            let scaleData = "";

            getScaleData(slide_id, res.data.slide_token)
              .then((res) => {
                if (res && res.data && res.data.scale) {
                  set_ScaleValue(res.data.scale);
                  pageDispatcher.set_disableTools(false);
                  scaleData = res.data.scale;
                  prepareSlideViewerData(slide_id, slide_access_token, scaleData);
                }
              })
              .catch((err) => {
                // console.log(err);
                pageDispatcher.set_disableTools(true);
                prepareSlideViewerData(slide_id, slide_access_token, scaleData);
              });
          }
        })
        .catch((e) => {
          NotificationManager.error("Not Authrized!!", "", 2000);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getFormatedDate = () => {
    if (pageState.slideInfo.createdDate) {
      // console.log("params.data.unixtime", pageState.slideInfo.createdDate)
      let date_val = new Date(parseInt(pageState.slideInfo.createdDate) * 1000);
      let humanDateFormat = date_val.toLocaleString("en-AU", { timeZone: "Asia/Kolkata" });
      return humanDateFormat;
    } else {
      return "";
    }
  };

  return (
    <div className="container-fluid">
      <PageContext.Provider value={{ pageState, pageDispatcher }}>
        <NotificationContainer />

        <PageTitle
          title={pageState.slideInfo.slideName}
          backLink="/slides"
        ></PageTitle>
        <br />

        <div className="row justify-content-between">
          <div className="">
            <UploadRequestForm />
          </div>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <OverViewComponent
              imageElement={imageElement}
              slide_id={slide_id}
            />
            <DisplayPdfFromCloud imageElement={imageElement} />
            <a
              href={`https://binilj04.github.io/pdf-from-images-react-app/`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fa fa-file-pdf-o fa-2x ml-4"
                aria-hidden="true"
              ></i>
            </a>
          </div>

          <div className="">
            <div style={{ textAlign: "right" }}>
              <a
                href="#!"
                style={{ color: "green" }}
                onClick={() => set_scaleModalVisible(true)}
              >
                + {scaleValue ? "Update" : "Add"} Scale
              </a>{" "}
              |&nbsp;
              <AddUpdateScaleComponent
                value={{ scaleModalVisible, set_scaleModalVisible }}
                scalVal={{ scaleValue, set_ScaleValue }}
                {...props}
              />
              <b>Created Date:</b>
              {getFormatedDate()}
            </div>
          </div>
        </div>

        <div className="row">
          <div
            ref={mapElement}
            className="map-container"
          >
            <DrawFeaturesComponent selectedElement={selectedElement} />
            <ScreenshotSlideComponent />
            <TileLayerStyleControls />
            <ZoomLevelsComponent zoomLevelElement={zoomLevelElement} />
          </div>
        </div>
      </PageContext.Provider>
    </div>
  );
};

const UploadRequestForm = (props) => {
  const context = useContext(PageContext);
  const [modalVisible, set_modalVisible] = useState(false);
  const [url, setUrl] = useState("");
  const [FileUploadFormValidated, setFileUploadFormValidated] = useState(false);
  const [fileObj, setFileObj] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  const [step, setStep] = useState(1);
  const [text, setText] = useState("");
  const [validateFileFormat, setValidateFileFormat] = useState(false);
  const FileUploadForm = useRef(null);

  useEffect(() => {
    if (url && text === "") setStep(2);
    else if (url === "" && text === "") setStep(1);
  }, [url, text]);

  const handleOk = (event) => {
    event.preventDefault();
    setStep(3);

    axios
      .put(url, fileObj)
      .then((res) => {
        if (res) {
          NotificationManager.success("success!", "", 2000);
          setText("success");
        }
      })
      .catch((err) => {
        // console.log(err);
        NotificationManager.error(err.message, "", 3000);
        setStep(2);
      });
  };
  const handleClose = (event) => {
    event.preventDefault();
    set_modalVisible(false);
    setText("");
    setStep(1);
    setDisableBtn(true);
  };

  return (
    <>
      <a
        href="#!"
        onClick={(e) => {
          getFormUrl(context.pageState.slideInfo.slideToken).then((res) => {
            if (res && res.data) {
              // console.log("form url", res.data);
              setUrl(res.data.message.replace(/\"/g, ""));
              set_modalVisible(true);
              setText("");
            } else if (res && res.data.Message) {
              NotificationManager.error(res.data.Message, "", 2000);
            }
          });
        }}
      >
        <h6>+ Upload Request Form</h6>
      </a>
      {modalVisible && (
        <Modal
          show={modalVisible}
          onHide={() => set_modalVisible(false)}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Request Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              {
                1: <CheckingAccess />,
                2: (
                  <FileUploadComponent
                    FileUploadFormValidated={FileUploadFormValidated}
                    FileUploadForm={FileUploadForm}
                    fileState={{ fileObj, setFileObj }}
                    checkFileFormat={{ validateFileFormat, setValidateFileFormat }}
                    setDisableBtn={setDisableBtn}
                    disableBtn={disableBtn}
                  />
                ),
                3: <Uploading text={text} />,
              }[step]
            }
          </Modal.Body>

          <Modal.Footer>
            {step === 2 && (
              <button
                className="btn btn-primary"
                onClick={(e) => handleOk(e)}
                disabled={disableBtn}
              >
                Upload Pdf
              </button>
            )}
            {step === 3 && (
              <button
                className="btn btn-primary"
                onClick={(e) => handleClose(e)}
                disabled={disableBtn}
              >
                Close
              </button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const CheckingAccess = () => {
  return <span style={{ color: "green" }}>Checking Access ....</span>;
};

const Uploading = ({ text }) => {
  return <span style={{ color: "green" }}>{text ? "Uploaded ....." : "Uploading pdf to cloud....."}</span>;
};

const DisplayPdfFromCloud = (props) => {
  const context = useContext(PageContext);

  const [modalVisible, set_modalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <a
        href="#!"
        style={{ float: "right" }}
        onClick={(e) => {
          set_modalVisible(true);
          setLoading(true);
          getPdfFromCloud(context.pageState.slideInfo.slideToken)
            .then((res) => {
              if (res && res.data) {
                // console.log("data pdf:", res.data);
                let blob = new Blob([res.data], { type: "application/pdf" });
                let blobURL = URL.createObjectURL(blob);

                context.pageDispatcher.set_wsImage(blobURL);
                setLoading(false);
              } else if (res && res.data.Message) {
                NotificationManager.error(res.data.Message, "", 2000);
                set_modalVisible(false);
                setLoading(false);
              }
            })
            .catch((err) => {
              NotificationManager.error(err.message, "", 3000);
            });
        }}
      >
        <img
          src={window.location.origin + "/fileImage.png"}
          width="30px"
          className="ml-4 mr-2"
          alt=""
        />
      </a>

      <Modal
        show={modalVisible}
        onHide={() => set_modalVisible(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Pdf From Cloud</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Loader
                  type="Rings"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  // timeout={10000}
                />
              </div>
            ) : (
              <iframe
                style={{ width: "100%", height: "88vh" }}
                src={context.pageState.wsImage}
                type="application/pdf"
                title="Pdf From Cloud"
              />
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const FileUploadComponent = (props) => {
  const { validateFileFormat, setValidateFileFormat } = props.checkFileFormat;

  return (
    <>
      <Form
        noValidate
        validated={props.FileUploadFormValidated}
        ref={props.FileUploadForm}
      >
        <Form.Group
          controlId="formFileSm"
          className="mb-3"
        >
          <Form.Label>Upload pdf files only:</Form.Label>
          <Form.Control
            type="file"
            required
            accept=".pdf"
            onChange={(e) => {
              if (e.target.files[0].type && e.target.files[0].type === "application/pdf") {
                setValidateFileFormat(false);
                props.setDisableBtn(false);
                props.fileState.setFileObj(e.target.files[0]);
              } else {
                setValidateFileFormat(true);
                props.setDisableBtn(true);
              }
            }}
          />
          {validateFileFormat ? <span style={{ color: "red" }}>Please Upload pdf files only!</span> : <span></span>}
          <Form.Control.Feedback type="invalid">please select a file</Form.Control.Feedback>
        </Form.Group>
      </Form>
    </>
  );
};

const AddUpdateScaleComponent = (props) => {
  const context = useContext(PageContext);

  const ScaleValueForm = useRef(null);
  // console.log("context",context)
  const [validated, setValidated] = useState(false);

  const { scaleModalVisible, set_scaleModalVisible } = props.value;

  const { scaleValue, set_ScaleValue } = props.scalVal;

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = ScaleValueForm.current;

    if (form.checkValidity()) {
      let obj = {
        scale_data: parseFloat(scaleValue),
        slide_id: props.urlData.id,
      };

      addupdatescale(obj, context.pageState.slideInfo.slideToken).then((res) => {
        if (res && res.data.Message) {
          NotificationManager.success(res.data.Message, "", 2000);
          set_scaleModalVisible(false);
        }
        if (res.data.Err) {
          NotificationManager.error(res.data.Err, "", 2000);
        }
      });
    } else {
      setValidated(true);
    }
  };

  return (
    <>
      <Modal
        show={scaleModalVisible}
        onHide={() => set_scaleModalVisible(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Update Scale</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            ref={ScaleValueForm}
            validated={validated}
          >
            <Form.Group controlId="scalevalue">
              {/* <Form.Label>Scale</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Enter scale value range 1 - 9"
                name="scaleval"
                value={scaleValue}
                onChange={(e) => set_ScaleValue(e.target.value)}
                required
              />

              <Form.Control.Feedback type="invalid">Please enter scale value.</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => set_scaleModalVisible(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={(e) => handleSubmit(e)}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const OverViewComponent = (props) => {
  const context = useContext(PageContext);

  const [modalVisible, set_modalVisible] = useState(false);

  return (
    <>
      <a
        href="#!"
        style={{ float: "right" }}
        onClick={(e) => {
          getannotated(context.pageState.slideInfo.slideToken, props.slide_id).then((res) => {
            // debugger
            if (res && res.data && res.data.byteLength > 0) {
              let blob = new Blob([res.data], { type: res.headers["content-type"] });
              let blobURL = URL.createObjectURL(blob);

              context.pageDispatcher.set_wsImage(blobURL);
              set_modalVisible(true);
              new Cropper(props.imageElement.current, {
                viewMode: 0,
                dragMode: "move",
                zoomable: true,
                background: true,
                autoCrop: false,
                toggleDragModeOnDblclick: false,
              });
            }
            if (res && res.data.Message) {
              NotificationManager.error(res.data.Message, "", 2000);
            }
          });
        }}
      >
        <h4>OverView</h4>
      </a>
      {context && context.pageState.wsImage && (
        <Modal
          show={modalVisible}
          onHide={() => set_modalVisible(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>WS Image</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Image
                  className="img-container"
                  src={context.pageState.wsImage}
                  alt=""
                  id="img"
                  ref={props.imageElement}
                />
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => set_modalVisible(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const DrawFeaturesComponent = (props) => {
  const context = useContext(PageContext);
  return (
    <div
      className=""
      style={{
        position: "absolute",
        right: "6px",
        top: "42px",
        height: "649px",
        width: "65px",
        display: "inline-block",
        backgroundColor: "transparent",
        zIndex: "10",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn btn-secondary"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={context.pageState.disableTools}
                style={{
                  cursor: context && context.pageState.disableTools ? "not-allowed" : "pointer",
                  backgroundColor: "rgba(0,60,136,0.5)",
                  color: "white",
                  padding: "0",
                  fontSize: "1.14em",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "1.375em",
                  width: "1.375em",
                  border: "none",
                  borderRadius: "2px",
                }}
              >
                <i
                  className="fa fa-paint-brush"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Measurement Type"
                ></i>
              </button>
              <div
                className="dropdown-menu"
                id="selectedElement"
                ref={props.selectedElement}
              >
                <a
                  className="dropdown-item"
                  href="#!"
                  id="length"
                >
                  Length (LineString)
                </a>
                <a
                  className="dropdown-item"
                  href="#!"
                  id="area"
                >
                  Area (Polygon)
                </a>
                <a
                  className="dropdown-item"
                  href="#!"
                  id="none"
                >
                  None
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ZoomLevelsComponent = (props) => {
  const context = useContext(PageContext);

  return (
    <div
      className=""
      style={{ position: "absolute", right: "16px", top: "139px", margin: "0px", zIndex: "30", fontSize: "18px" }}
    >
      <ButtonGroup
        vertical
        size="sm"
        id="dynamicResolution"
        style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
      >
        <Button
          variant="outline-secondary"
          value="80X"
          id="80X"
          style={{ cursor: context && context.pageState.disableTools ? "not-allowed" : "pointer" }}
          disabled={context.disableTools}
        >
          80X
        </Button>
        <Button
          variant="outline-secondary"
          value="40X"
          id="40X"
          style={{ cursor: context && context.pageState.disableTools ? "not-allowed" : "pointer" }}
          disabled={context.disableTools}
        >
          {" "}
          40X
        </Button>
        <Button
          variant="outline-secondary"
          value="20X"
          id="20X"
          style={{ cursor: context && context.pageState.disableTools ? "not-allowed" : "pointer" }}
          disabled={context.disableTools}
        >
          20X
        </Button>
        <Button
          variant="outline-secondary"
          value="10X"
          id="10X"
          style={{ cursor: context && context.pageState.disableTools ? "not-allowed" : "pointer" }}
          disabled={context.disableTools}
        >
          10X
        </Button>
        <Button
          variant="outline-secondary"
          value="4X"
          id="4X"
          style={{ cursor: context && context.pageState.disableTools ? "not-allowed" : "pointer" }}
          disabled={context.disableTools}
        >
          4X
        </Button>
        <Button
          variant="outline-secondary"
          value="fit"
          id="fit"
          style={{ cursor: context && context.pageState.disableTools ? "not-allowed" : "pointer" }}
          disabled={context.disableTools}
        >
          Fit
        </Button>
      </ButtonGroup>
    </div>
  );
};

const ScreenshotSlideComponent = () => {
  const context = useContext(PageContext);

  return (
    <div
      className=""
      style={{
        position: "absolute",
        right: "6px",
        top: "74px",
        height: "649px",
        width: "65px",
        display: "inline-block",
        backgroundColor: "transparent",
        zIndex: "10",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn btn-secondary"
                id="export-png"
                disabled={context.pageState.disableTools}
                style={{
                  cursor: context && context.pageState.disableTools ? "not-allowed" : "pointer",
                  backgroundColor: "rgba(0,60,136,0.5)",
                  color: "white",
                  padding: "0",
                  fontSize: "1.14em",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "1.375em",
                  width: "1.375em",
                  border: "none",
                  borderRadius: "2px",
                }}
              >
                <i
                  className="fa fa-arrow-circle-o-down"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Download current view"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TileLayerStyleControls = () => {
  const context = useContext(PageContext);

  return (
    <div
      className=""
      style={{
        position: "absolute",
        right: "6px",
        top: "106px",
        height: "649px",
        width: "65px",
        display: "inline-block",
        backgroundColor: "transparent",
        zIndex: "10",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn btn-secondary"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={context.disableTools}
                style={{
                  cursor: context && context.disableTools ? "not-allowed" : "pointer",
                  backgroundColor: "rgba(0,60,136,0.5)",
                  color: "white",
                  padding: "0",
                  fontSize: "1.14em",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "1.375em",
                  width: "1.375em",
                  border: "none",
                  borderRadius: "2px",
                }}
              >
                <i
                  className="fa fa-filter"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Filters"
                ></i>
              </button>
              <div
                className="dropdown-menu row"
                id="tilestyles"
                style={{ textAlign: "center" }}
              >
                Brightness: <span id="brightness-value"></span>
                <br />
                <input
                  id="brightness"
                  type="range"
                  min="-0.5"
                  max="1"
                  step="0.01"
                />
                Exposure: <span id="exposure-value"></span>
                <br />
                <input
                  id="exposure"
                  type="range"
                  min="-0.5"
                  max="1"
                  step="0.01"
                />
                <br />
                Contrast: <span id="contrast-value"></span>
                <br />
                <input
                  id="contrast"
                  type="range"
                  min="-0.5"
                  max="1"
                  step="0.01"
                />
                <br />
                Saturation: <span id="saturation-value"></span>
                <br />
                <input
                  id="saturation"
                  type="range"
                  min="-0.5"
                  max="1"
                  step="0.01"
                />
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    id="resettilestyle"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PageContext = createContext(null);
