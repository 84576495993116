import React, { useContext } from "react";
import MapComponent from "./Map/MapComponent";
import Layers from "./Map/Layers";
import VectorLayer from "./Map/VectorLayer";

// import { PageContext } from '../Remote';

const WsImage1 = ({ WSImageData, PostionCoords }) => {
  return (
    <div>
      <MapComponent
        WSImageData={WSImageData}
        PostionCoords={PostionCoords}
      >
        <Layers>
          <VectorLayer />
        </Layers>
      </MapComponent>
    </div>
  );
};
export default WsImage1;
