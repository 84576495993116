import { createContext } from "react";
import axios from "axios";
import { environment } from "../../environment";
import { getToken } from "../Utils/Common";
const baseURL = environment.MICALYS_POSTGRES_API;

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_activeDeviceList":
      return {
        ...state,
        activeDeviceList: action.payload,
      };

    case "set_enableRefreshBtn":
      console.log("REfreshBTN:call me once connected");
      return {
        ...state,
        isRefreshBtnDisable: action.payload,
      };

    // case "set_buttonVisible":
    //   return {
    //     ...state,
    //     isButtonVisible: action.payload,
    //   };

    case "set_subState":
      return {
        ...state,
        subState: action.payload,
      };

    case "set_modalVisible":
      return {
        ...state,
        modalVisible: action.payload,
      };
    case "set_updateTimeInterval":
      return {
        ...state,
        timeInterval: action.payload,
      };

    case "set_wsImage":
      return {
        ...state,
        wsImageData: action.payload,
      };

    case "set_wsRecentImage":
      return {
        ...state,
        wsRecentImageData: action.payload,
      };

    case "set_lensButton":
      return {
        ...state,
        selectedLensButton: action.payload,
      };

    case "set_lensXYPoints":
      console.log("set_lensXYPoints in service:", action.payload);
      return {
        ...state,
        selectedLensXYPoints: [...action.payload],
      };

    case "set_logMsg":
      return {
        ...state,
        MsgLog: action.payload,
      };

    case "set_connection_Msg":
      return {
        ...state,
        Connection_Msg: action.payload,
      };

    // case 'set_heartbeatData':
    //     return {
    //         ...state, heartbeatData: action.payload
    //     }

    case "set_position":
      console.log("in service", action.payload);
      return {
        ...state,
        positionData: [action.payload[0]],
      };

    case "set_gotoCoords":
      return {
        ...state,
        gotoCoords: [{ ...action.payload[0] }],
      };

    case "set_moveCoords":
      return {
        ...state,
        moveCoords: action.payload[0],
      };

    case "set_device_state":
      return {
        ...state,
        isDeviceBusy: action.payload,
      };

    default:
      return state;
  }
};

export const getActiveDeviceList = (dispatch) => {
  axios.get(baseURL + "listdevice", { headers: { Authorization: `Bearer ${getToken()}` } }).then((res) => {
    if (res && res.data) {
      dispatch({ type: "set_activeDeviceList", payload: res.data.activeDevices });
    }
  });
};

export const setOfferAndIceCandidate = (obj) => {
  return axios.post(baseURL + "setoffer", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getAnswer = (obj) => {
  return axios.post(baseURL + "getanswer", obj, { headers: { Authorization: `Bearer ${getToken()}` } });
};

export const getTurnCred = () => {
  return axios.post(baseURL + "turncred", {}, { headers: { Authorization: `Bearer  ${getToken()}` } });
};

export const PageContext = createContext(null);
