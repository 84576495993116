import React, { useContext } from 'react'
import { PageContext } from "../LiveView";

export const Logs = (props) => {
    const stateContext = useContext(PageContext);
    // console.log("props in Logs component",props.PostionCoords)
    const { x, y, z } = props && props.PostionCoords[0];

    let datetime = new Date().toLocaleTimeString();

    return (
        <div style={{
            // backgroundColor: 'lightblue',
            // // width: '110px',
            // // height: '110px',
            // overflow: 'scroll'
        }}>
            Logs Component
            {
                props && props.PostionCoords &&
                <div className="row">
                    <div className="col">
                        <b>X:</b>{x}
                    </div>
                    <div className="col">
                        <b>Y:</b>{y}
                    </div>
                    <div className="col">
                        <b>Z:</b>{z}
                    </div>
                </div>
            }
            <br/>
            {
                stateContext.pageState.MsgLog &&
                <div className="row">
                    <div className="col">
                        <b>Key Logs: </b>{stateContext.pageState.MsgLog} at {datetime}
                        {/* <p>Last Activity at {datetime}</p> */}
                    </div>                    
                </div>
            }

        </div>
    )
}