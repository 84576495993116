import { useState, useRef, useEffect,  } from "react";
import { Button, Modal, Form, Row, Col, Card } from 'react-bootstrap'
import { createGroup, deleteGroup, updateGroupName, getGroupList, getAddToMembersList, getAddedGroupMembersList, addToGroupMembers, removeMemberFromGroup, makeAdmin } from "../Service";
import { Multiselect } from "multiselect-react-dropdown";
import { NotificationContainer, NotificationManager } from 'react-notifications';


export const Groups = () => {

    const [createGroupModalVisible, setCreateGroupModalVisible] = useState(false);   //groupmodalstate
    const [membersModalVisible, setMembersModalVisible] = useState(false);  //membersmodalstate
    const [groupList, setGroupList] = useState([]);     //groupslistonload
    const [addedMembersList, setAddedMembersList] = useState([]); //getmemberslisttobeadded in addnew membersmodal

    const initialState = {
        mode: 'add',
        groupid: '',
        groupnamevalue: '',
        group_admin_user: '',
        groupname: '',
        group_admin_email: ''
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        getGroupList().then(res => {
            if (res && res.data.group_data) {
                setGroupList(res.data.group_data)
            }
        })
    }, [])

    const getAddedMembersList = (groupid) => {

        getAddedGroupMembersList(groupid).then(res => {
            if (res && res.data.members) {
                setAddedMembersList(res.data.members);
                setMembersModalVisible(true)
            }
        })
    }

    return (
        <div>
            <NotificationContainer />
            <div>
                <a href="#!" style={{ float: 'right', color: 'green' }} onClick={() => {
                    setState({ ...state, mode: 'add', groupnamevalue: '', groupid: '' })
                    setCreateGroupModalVisible(true)

                }}>+ Create Group</a>
                <CreateGroupModal modal={{ createGroupModalVisible, setCreateGroupModalVisible }} groupDataList={{ groupList, setGroupList }} statevalue={{ state, setState }} />
            </div>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#Sr</th>
                            <th scope="col">Group Admin</th>
                            <th scope="col">Group Name</th>
                            <th scope="col">Manage</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {groupList && groupList.map((item, key) => (
                            <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.email}</td>
                                <td>{item.group_name}</td>
                                <td>
                                    <a href="#!" onClick={() => {
                                        setState({ ...state, groupid: item.group_id, group_admin_user: item.group_admin_user, groupname: item.group_name, group_admin_email: item.email })
                                        getAddedMembersList(item.group_id)
                                    }}>
                                        <i className="fa fa-users" aria-hidden="true"></i> Members
                                    </a>
                                </td>
                                <td>
                                    <a href="#!" onClick={() => {
                                        setState({ ...state, mode: 'edit', groupnamevalue: item.group_name, groupid: item.group_id });
                                        setCreateGroupModalVisible(true)
                                    }}>
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </a>
                                    &nbsp;&nbsp;&nbsp;
                                    <a href="#!" onClick={() => {
                                        if (window.confirm('Sure wants to delete group and exit?')) {
                                            deleteGroup(item.group_id).then(res => {
                                                if (res && res.data.message) {
                                                    NotificationManager.success(res.data.message, '', 2000);

                                                    getGroupList().then(res => {
                                                        if (res && res.data.group_data) {
                                                            setGroupList(res.data.group_data)
                                                        }
                                                    })
                                                }
                                            })
                                        }
                                    }}>
                                        <i className="fa fa-trash-o" aria-hidden="true" style={{ color: 'red' }} ></i>

                                    </a>
                                </td>
                            </tr>
                        ))
                        }

                    </tbody>
                </table>

                <MembersModal modal={{ membersModalVisible, setMembersModalVisible }} addedMembersData={{ addedMembersList, setAddedMembersList }} groupDataList={{ groupList, setGroupList }} statevalue={{ state, setState }} />
            </div>
        </div>
    )
}

const CreateGroupModal = (props) => {
    const CreateGroupForm = useRef(null)

    const { createGroupModalVisible, setCreateGroupModalVisible } = props.modal;

    const { setGroupList } = props.groupDataList;

    const [validated, setValidated] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const form = CreateGroupForm.current

        if (form.checkValidity()) {

            let obj = {
                group_name: state.groupnamevalue
            }
            if (state.mode === 'add') {
                createGroup(obj).then(res => {
                    if (res && res.data.message) {
                        NotificationManager.success(res.data.message, '', 2000)
                        getGroupList().then(res => {
                            if (res && res.data.group_data) {
                                setGroupList(res.data.group_data)
                            }
                        })
                        setCreateGroupModalVisible(false);
                    }
                })
            }
            if (state.mode === 'edit') {
                updateGroupName(state.groupid, obj).then(res => {
                    if (res && res.data.message) {
                        NotificationManager.success(res.data.message, '', 2000)
                        getGroupList().then(res => {
                            if (res && res.data.group_data) {
                                setGroupList(res.data.group_data)
                            }
                        })
                        setCreateGroupModalVisible(false);
                    }
                })
            }

        }
        else {
            setValidated(true);
        }

    }

    const { state, setState } = props.statevalue;

    return (
        <>
            <Modal backdrop="static" show={createGroupModalVisible} onHide={() => setCreateGroupModalVisible(false)} aria-labelledby="contained-modal-title-lg" size="md"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{state.mode === 'add' ? 'Create Group' : 'Update Group Name'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} ref={CreateGroupForm}>
                        <Row>
                            <Col>
                                <Form.Label>Group Name:</Form.Label>
                                <Form.Control type="text" name="group_name" placeholder="enter group name" value={state.groupnamevalue} onChange={(event) => {
                                    setState({ ...state, groupnamevalue: event.target.value })
                                }}
                                    required />
                                <Form.Control.Feedback type="invalid" >
                                    group name is required.
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setCreateGroupModalVisible(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
                        {state.mode === 'add' ? 'Create' : 'Update'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const MembersModal = (props) => {
    const { membersModalVisible, setMembersModalVisible } = props.modal;
    const [addNewMemberModalVisible, setAddNewMemberModalVisible] = useState(false);

    const { addedMembersList, setAddedMembersList } = props.addedMembersData;

    const { setGroupList } = props.groupDataList;

    const { state, setState } = props.statevalue;

    const [addMemberList, setAddMemberList] = useState([]);


    const getMembersList = () => {
        getAddToMembersList(state.groupid).then(res => {
            if (res && res.data.members) {
                setAddMemberList(res.data.members)
                setAddNewMemberModalVisible(true)
            }

        })
    }

    const makeAdminToMember = (userid, email) => {
        if (window.confirm('Sure wants to make admin to this user?')) {

            setState({ ...state, group_admin_user: userid, group_admin_email: email })

            makeAdmin(state.groupid, userid).then(res => {
                if (res && res.data.message) {
                    NotificationManager.success(res.data.message, '', 2000);

                    getAddedGroupMembersList(state.groupid).then(res => {
                        if (res && res.data.members) {
                            setAddedMembersList(res.data.members);
                        }
                    })

                    getGroupList().then(res => {
                        if (res && res.data.group_data) {
                            setGroupList(res.data.group_data)
                        }
                    })

                }
            })
        }
    }
    const deleteMemberFromGroup = (userid) => {
        if (window.confirm('Sure wants to remove this user?')) {
            removeMemberFromGroup(state.groupid, userid).then(res => {
                if (res && res.data.message) {
                    NotificationManager.success(res.data.message, '', 2000);
                    getAddedGroupMembersList(state.groupid).then(res => {
                        if (res && res.data.members) {
                            setAddedMembersList(res.data.members);
                        }
                    })
                }
            })
        }
    }
    return (
        <>
            <Modal backdrop="static" show={membersModalVisible} onHide={() => setMembersModalVisible(false)} aria-labelledby="contained-modal-title-lg" size="lg"
                centered>
                <Modal.Header style={{ height: '90px' }}>
                    <Modal.Title style={{ color: 'dimgrey' }}><i className="fa fa-users" aria-hidden="true"></i> &nbsp;{state.groupname}</Modal.Title>
                    <br />
                </Modal.Header>
                <Modal.Body>
                    <label style={{ float: 'left', marginTop: '-41px' }}><b>Admin</b>: <a href="#!">{state.group_admin_email}</a></label>
                    <a href="#!" style={{ float: 'right', color: 'green', marginTop: '-41px' }} onClick={() => getMembersList()}><i className="fa fa-user-plus" aria-hidden="true"></i>  Add new members</a>
                    <AddNewMemberModal
                        modal={{ addNewMemberModalVisible, setAddNewMemberModalVisible }}
                        addMembersData={{ addMemberList, setAddMemberList }}
                        addedMembersData={{ addedMembersList, setAddedMembersList }}
                        {...props.statevalue} />
                    <div>
                        <Row xs={2} md={12} className="g-12">

                            {
                                addedMembersList && addedMembersList.length > 0 ? addedMembersList.filter(el => state.group_admin_user !== el.userid).map((item, key) => (

                                    <Col>
                                        <Card style={{ width: '20rem' }} className="shadow bg-white rounded">
                                            <Card.Body>
                                                <Card.Text>
                                                    <i className="fa fa-user-circle-o fa-lg" aria-hidden="true" ></i> &nbsp;
                                                    {item.email}

                                                    <a href="#!" style={{ color: "black" }} >
                                                        <i className="fa fa-ellipsis-v" aria-hidden="true" style={{ float: 'right' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                                        <div className="dropdown-menu" id="selectedElement" style={{ lineHeight: '15px', fontSize: '13px' }}>
                                                            <a className="dropdown-item" href="#!" id="makeadmin" onClick={() => makeAdminToMember(item.userid, item.email)}>Make Admin</a>

                                                            <a className="dropdown-item" href="#!" id="remove"
                                                                onClick={() => deleteMemberFromGroup(item.userid)}
                                                            >Remove from Group</a>
                                                        </div>
                                                    </a>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>

                                        <br />
                                    </Col>

                                )
                                ) : <div><h4 style={{ textAlign: 'center' }}>No Members Added Yet.</h4></div>
                            }
                        </Row>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {/* <label style={{ float: 'left', color: 'dimgrey', marginTop: '-41px', fontSize: '12px' }}>{addedMembersList && addedMembersList.filter((el) => state.group_admin_user === el.email).forEach(key=> key.email)}</label> */}

                    <Button variant="secondary" onClick={() => setMembersModalVisible(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const AddNewMemberModal = (props) => {

    const { addNewMemberModalVisible, setAddNewMemberModalVisible } = props.modal;
    const { addMemberList } = props.addMembersData
    const [selectedUser, setSelectedUser] = useState({});

    const [disableBtn, setDisableBtn] = useState(true)

    const {  setAddedMembersList } = props.addedMembersData;

    const handleSubmit = () => {

        addToGroupMembers(selectedUser).then(res => {
            if (res && res.data.message) {
                NotificationManager.success(res.data.message, '', 2000);

                getAddedGroupMembersList(props.state.groupid).then(res => {
                    if (res && res.data.members) {
                        setAddedMembersList(res.data.members);
                        setAddNewMemberModalVisible(false)
                    }
                })
            }
        })
    }

    return (
        <>
            <Modal show={addNewMemberModalVisible} backdrop="static"
                size="md"
                onHide={() => setAddNewMemberModalVisible(false)} aria-labelledby="example-custom-modal-styling-title"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Members</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Multiselect placeholder="Add from members" id="userslist"
                        options={addMemberList && addMemberList.map((item, key) => item.email)}
                        displayValue={"name"}
                        isObject={false}
                        emptyRecordMsg={"No Users"}
                        style={{
                            chips: {
                                background: 'green'
                            },
                            multiselectContainer: {
                                color: 'black'
                            },
                            searchBox: {
                                border: 'none',
                                borderBottom: '1px solid blue',
                                borderRadius: '0px'
                            }
                        }}
                        onSelect={(value) => {
                            if (value.length > 0) {
                                setDisableBtn(false)
                            }
                            else {
                                setDisableBtn(true)
                            }
                            let val = addMemberList.filter((el) => value.includes(el.email))
                            let result = val.map(el => el.userid)

                            let obj = {
                                group_id: props.state.groupid,
                                users: result
                            }
                            setSelectedUser(obj)
                        }}
                        onRemove={(value) => {
                            if (value.length > 0) {
                                setDisableBtn(false)
                            }
                            else {
                                setDisableBtn(true)
                            }
                        }}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setAddNewMemberModalVisible(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" disabled={disableBtn} onClick={() => handleSubmit()}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}





























