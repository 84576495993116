
import { createContext } from 'react'
import axios from 'axios';
import { environment } from "../../environment";
import { getToken } from "../Utils/Common";
const baseURL = environment.MICALYS_POSTGRES_API

export const pageReducer = (state, action) => {
  switch (action.type) {
    case 'set_userList':
      return {
        ...state, userList: action.payload
      }
    case 'set_diskSpaceData':
      return {
        ...state, diskSpaceData: action.payload
      }

    case 'set_isLoggedInWithEmail':
      return {
        ...state, isLoggedInWithEmail: action.payload
      }

    case 'set_cloudId':
      return {
        ...state, cloudId: action.payload
      }
    case 'set_deviceRegistrationToggle':
      console.log("pageState.deviceRegistrationToggle",action.payload)
      return {

        ...state, deviceRegistrationToggle: action.payload
      }
    case 'set_configDataToggle':
      console.log("pageState.configDataToggle",action.payload)
      return {

        ...state, configDataToggle: action.payload
      }
    case 'set_isMaint':
      return {
        ...state, 
        isMaint:{
          ...state.isMaint,
          otp: action.payload.otp,
          expiry: action.payload.expiry_time,
        }
      }
    default:
      return state
  }
}

export const getUserList = (dispatch) => {
  axios.get(baseURL + 'get_user_list', { headers: { Authorization: `Bearer ${getToken()}` } }).then(res => {
    if (res && res.data) {
      dispatch({ type: 'set_userList', payload: res.data.users })
    }
  })
}

export const getDiskSpaceData = () => {
  return axios.get(baseURL + 'get_disk_space_info', { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const changePermission = (data) => {
  return axios.post(baseURL + 'change_permission', data, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const addUserSubscription = (data) => {
  return axios.post(baseURL + 'addUserSubscription', data, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const getUserSubscriptions = (data) => {  //For Admin
  return axios.post(baseURL + 'getUserSubscriptions', data, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const deleteUserSubscription = (data) => {
  return axios.post(baseURL + 'deleteUserSubscription', data, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const getCloudId = (pageDispatcher) => {
  axios.get(baseURL + 'getcloudid', { headers: { Authorization: `Bearer ${getToken()}` } }).then(res => {
    if (res && res.data && res.data.cloudId) {
      pageDispatcher.set_cloudId(res.data.cloudId)
    }
  })
}

export const generateCloudId = (dispatch) => {
  return axios.post(baseURL + 'generatecloudid', {}, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const getOtp = () => {
  return axios.get(baseURL + 'get_otp', { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const generateQRCode = async () => {
  try {
    const response = await axios.get(baseURL + 'generate_qr_code', { 
      headers: { Authorization: `Bearer ${getToken()}` },
      responseType: 'blob' // Specify response type as blob to receive binary data
    });

    // Return the blob data directly
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Failed to generate QR code');
  }
};



export const generateOtp = () => {
  return axios.post(baseURL + 'generate_otp', {}, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const saveDeviceName = (obj) => {
  return axios.post(baseURL + 'set_device_name', obj, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const getDeviceNameList = () => {
  return axios.get(baseURL + 'get_device_name_list', { headers: { Authorization: `Bearer ${getToken()}` } })
}
export const getDeviceNameOfConfig = () => {
  return axios.get(baseURL + 'get_device_name_list_from_device_config_data', { headers: { Authorization: `Bearer ${getToken()}` } })
}
export const getDeviceSubscriptionStatus = () => {
  return axios.get(baseURL + 'get_device_subscription_status_of_active_devices', { headers: { Authorization: `Bearer ${getToken()}` } })
}
export const updateDeviceSubscription = (obj) => {
  return axios.post(baseURL + 'update_device_subscription_status', obj, { headers: { Authorization: `Bearer ${getToken()}` } })
}
export const getDeviceConfigData = (obj) => {
  return axios.post(baseURL + 'fetch_config_file_from_remote',obj, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const createGroup = (obj) => {
  return axios.post(baseURL + 'add_group', obj, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const updateGroupName = (group_id, obj) => {
  return axios.put(baseURL + `updateGroupName/${group_id}`, obj, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const deleteGroup = (group_id) => {
  return axios.delete(baseURL + `deleteGroup/${group_id}`, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const getGroupList = () => {
  return axios.get(baseURL + 'get_group_data', { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const getAddToMembersList = (group_id) => {
  return axios.get(baseURL + `get_addToMembersList/${group_id}`, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const getAddedGroupMembersList = (group_id) => {
  return axios.get(baseURL + `get_addedGroupMembersList/${group_id}`, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const addToGroupMembers = (obj) => {
  return axios.post(baseURL + 'add_members', obj, { headers: { Authorization: `Bearer ${getToken()}` } })
}
export const removeMemberFromGroup = (group_id, userid) => {
  return axios.delete(baseURL + `removeMemberFromGroup/${group_id}/${userid}`, { headers: { Authorization: `Bearer ${getToken()}` } })
}

export const makeAdmin = (group_id,userid) => {
  return axios.put(baseURL + `makeAdmin/${group_id}`,{'userid':userid}, { headers: { Authorization: `Bearer ${getToken()}` } })
}
export const PageContext = createContext(null)


