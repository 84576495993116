import React, { useContext, useState, useRef } from "react"
import { PageContext } from "../Settings";
import { changePermission, getUserList, addUserSubscription, getUserSubscriptions, deleteUserSubscription } from "../Service";
import { Button, Modal, Form, Container, Row, Col } from 'react-bootstrap'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getUser } from "../../Utils/Common";
import { RiAdminFill } from "react-icons/ri";
import { GiMicroscope } from "react-icons/gi";
import { TiSpanner } from "react-icons/ti";
import { MdConnectedTv } from "react-icons/md";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";

export const Users = () => {

    return (
        <>
            <NotificationContainer />
            <UsersComponent />
        </>
    )
}


const UsersComponent = () => {
    const context = useContext(PageContext);
    const [modalVisible, setModalVisible] = useState(false);
    const [submodalVisible, set_subModalVisible] = useState(false);

    const [viewsubmodalVisible, set_viewsubmodalVisible] = useState(false)

    const [selectedRow, setSelectedRow] = useState({})

    const [userSubscriptions, set_userSubscriptions] = useState([])

    return (
        <div>
            <div className="table">
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Sr</th>
                            <th>Users</th>
                            <th>Plan Type</th>
                            <th>Change Permission</th>
                            <th>Subscription</th>
                        </tr>
                    </thead>
                    <tbody>
                        {context.pageState.userList && context.pageState.userList.map((item, key) => (
                            <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{item.email}</td>
                                <td>{item.account_type}</td>
                                <td className="d-flex justify-content-between"><a href="#!" onClick={() => {
                                    setModalVisible(true)
                                    setSelectedRow(item)
                                }
                                }><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </a>
                                    <div className="d-flex justify-content-around" style={{"width":"30%"}}>
                                        {item.isadmin ? <RiAdminFill/>:<RiCheckboxBlankCircleLine  style={{ opacity: 0 }} />}
                                        {item.isactive ? <MdConnectedTv/>:<RiCheckboxBlankCircleLine style={{ opacity: 0 }}/>}
                                        {item.hasdevice ? <GiMicroscope/>: <RiCheckboxBlankCircleLine style={{ opacity: 0 }}/>}
                                        {item.ismaint ? <TiSpanner/>:<RiCheckboxBlankCircleLine style={{ opacity: 0 }}/>}
                                    </div>
                                </td>
                                    
                                <td>
                                    <a href="#!" style={{ color: 'green' }} onClick={() => {
                                        set_subModalVisible(true)
                                        setSelectedRow(item)
                                    }}><i className="fa fa-plus-circle" aria-hidden="true" ></i> Add</a> &nbsp;&nbsp;
                                    <a href="#!" style={{ color: 'red' }} onClick={() => {
                                        set_viewsubmodalVisible(true);
                                        getUserSubscriptions({ "userid": item.userid }).then(res => {
                                            if (res && res.data.result) {
                                                set_userSubscriptions(res.data.result);
                                                setSelectedRow(item)
                                            }
                                        })
                                    }}> View</a>
                                </td>
                            </tr>
                        ))
                        }

                    </tbody>
                </table>

            </div>
            {
                modalVisible && <ChangePermissionModal
                    modal={{ modalVisible, setModalVisible }}
                    selectedrow={{ selectedRow, setSelectedRow }} />
            }
            {
                submodalVisible && <SubscriptionModal
                    val={{ submodalVisible, set_subModalVisible }}
                    selectedrow={{ selectedRow, setSelectedRow }} />
            }
            {
                viewsubmodalVisible && <ViewSubscription
                    val={{ viewsubmodalVisible, set_viewsubmodalVisible }}
                    selectedrow={{ selectedRow, setSelectedRow }}
                    subdata={{ userSubscriptions, set_userSubscriptions }} />
            }


        </div>

    )
}


const ChangePermissionModal = (props) => {
    const context = useContext(PageContext);

    const { modalVisible, setModalVisible } = props.modal
    const { selectedRow, setSelectedRow } = props.selectedrow
    const [validated, setValidated] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();
        //send for update

        if (selectedRow.server_name) {
            changePermission(selectedRow).then(res => {
                if (res && res.data.Message === 'success') {
                    NotificationManager.success('Permission Updated.', '', 2000)
                    setModalVisible(false)
                    getUserList(context.dispatch)
                    localStorage.setItem('hasDevice', selectedRow.hasdevice);
                    localStorage.setItem('isMaint', selectedRow.ismaint);
                }
            })

        }
        else {
            setValidated(true)
        }


    }

    return (
        <>
            <Modal show={modalVisible} onHide={() => setModalVisible(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change Permission</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h5><b>Email:{selectedRow.email}</b></h5>
                    <Form>
                        <Form.Check
                            type='checkbox'
                            label={`Admin`}
                            checked={selectedRow.isadmin}
                            onChange={(e) => setSelectedRow({ ...selectedRow, isadmin: e.target.checked })}
                        />
                        <Form.Check
                            type='checkbox'
                            label={`Active`}
                            checked={selectedRow.isactive}
                            onChange={(e) => setSelectedRow({ ...selectedRow, isactive: e.target.checked })}

                        />
                        <Form.Check
                            type='checkbox'
                            label={`HasDevice`}
                            checked={selectedRow.hasdevice}
                            onChange={(e) => setSelectedRow({ ...selectedRow, hasdevice: e.target.checked })}

                        />
                        <Form.Check
                            type='checkbox'
                            label={`IsMaint`}
                            checked={selectedRow.ismaint}
                            onChange={(e) => setSelectedRow({ ...selectedRow, ismaint: e.target.checked })}
                        />
                        <br />
                    </Form>

                    <Form noValidate validated={validated}>

                        <Row>
                            <Col>
                                <Form.Label>Server Name:</Form.Label>
                                <Form.Control type="text" name="server_name" value={selectedRow.server_name || ''} placeholder="enter server name"
                                    onChange={(e) => setSelectedRow({ ...selectedRow, server_name: e.target.value })}
                                    required />
                                <Form.Control.Feedback type="invalid" >
                                    server name is required.
                                </Form.Control.Feedback>
                            </Col>

                            <Col>
                                <Form.Label>Plan Type:</Form.Label>
                                <Form.Control as="select" name="plantype"
                                    onChange={(e) => setSelectedRow({ ...selectedRow, account_type: e.target.value })}
                                >
                                    {
                                        selectedRow && selectedRow.account_type === 'space' ? <><option value="space">space</option>  <option value="slide">slide</option></> :
                                            selectedRow.account_type === 'slide' ? <><option value="slide">slide</option>  <option value="space">space</option></> :
                                                !selectedRow.account_type && <><option value="space">space</option>  <option value="slide">slide</option></>
                                    }



                                </Form.Control>
                            </Col>

                        </Row>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalVisible(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}

const SubscriptionModal = (props) => {
    const context = useContext(PageContext);

    const { submodalVisible, set_subModalVisible } = props.val;
    const { selectedRow } = props.selectedrow
    const [validated, setValidated] = useState(false);

    const SubscriptionForm = useRef(null)

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = SubscriptionForm.current

        if (form.checkValidity()) {
            let obj = {
                // plan_id: form['planid'].value,
                plan_type: form['plantype'].value,
                expiry_date: form['expiry_date'].value,
                plan_units: form['plan_unit'].value,
                plan_name: form['plan_name'].value,
                slide_active_days: form['slide_active_days'].value,
                userid: selectedRow.userid
            }
            // console.log("obj", obj)
            addUserSubscription(obj).then(res => {
                NotificationManager.success('Subscription Added Successfully.', '', 2000)
                set_subModalVisible(false)
                getUserList(context.dispatch)
            })

        }
        else {
            setValidated(true);
        }
    }
    // console.log("selectedRow", selectedRow)
    return (
        <>
            <Modal show={submodalVisible} onHide={() => set_subModalVisible(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form noValidate validated={validated} ref={SubscriptionForm}>
                            <Row>
                                <Col>
                                    <Form.Label>Plan Name:</Form.Label>
                                    <Form.Control type="text" name="plan_name" placeholder="enter plan name" required />
                                    <Form.Control.Feedback type="invalid">
                                        name is required.
                                    </Form.Control.Feedback>
                                </Col>
                                <Col>
                                    <Form.Label>Plan Type:</Form.Label>
                                    <Form.Control as="select" name="plantype">
                                        <option value="space">space</option>
                                        <option value="slide">slide</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Expiry Date:</Form.Label>
                                    <Form.Control type="date" name='expiry_date' placeholder="select expiry" format="yyyy-mm-dd" required />
                                    <Form.Control.Feedback type="invalid">
                                        expiryDate is required.
                                    </Form.Control.Feedback>
                                </Col>
                                <Col>
                                    <Form.Label>Plan Unit:</Form.Label>
                                    <Form.Control type="number" name="plan_unit" placeholder="enter plan unit" required />
                                    <Form.Control.Feedback type="invalid">
                                        unit is required.
                                    </Form.Control.Feedback>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label>Slide Active Days:</Form.Label>
                                    <Form.Control type="number" min='0' name="slide_active_days" placeholder="enter slide active" required />
                                    <Form.Control.Feedback type="invalid">
                                        days is required.
                                    </Form.Control.Feedback>
                                </Col>
                                <Col></Col>
                            </Row>
                        </Form>

                    </Container>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => set_subModalVisible(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const ViewSubscription = (props) => {
    const { viewsubmodalVisible, set_viewsubmodalVisible } = props.val;
    return (
        <>
            <Modal show={viewsubmodalVisible} onHide={() => set_viewsubmodalVisible(false)} aria-labelledby="contained-modal-title-lg" size="lg"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Subscriptions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <SubscriptionData {...props} />
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

export const SubscriptionData = (props) => {

    const { userSubscriptions, set_userSubscriptions } = props && props.subdata;

    // if (getUser()) {
    const { selectedRow } = props.selectedrow || ""
    // }

    return (
        <div>

            {
                userSubscriptions && userSubscriptions.length > 0 ?
                    < div >
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">#Sr</th>
                                    <th scope="col">Plan Name</th>
                                    <th scope="col">Plan Type</th>
                                    <th scope="col">Expiry Date</th>
                                    <th scope="col">Plan Units</th>
                                    <th scope="col">Slide Active Days</th>
                                    {getUser() && <th scope="col">Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userSubscriptions.map((item, key) => (

                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{item.plan_name}</td>
                                            <td>{item.plan_type}</td>
                                            <td>{item.expiry_date}</td>
                                            <td>{item.plan_units}</td>
                                            <td>{item.slide_active_days}</td>
                                            {getUser() && <td><a href="#!" onClick={() => deleteUserSubscription({ "plan_id": item.plan_id, "userid": selectedRow.userid })
                                                .then(res => {
                                                    if (res && res.data.message) {
                                                        // alert("deleted")
                                                        getUserSubscriptions({ "userid": selectedRow.userid }).then(res => {
                                                            if (res && res.data.result) {
                                                                set_userSubscriptions(res.data.result);
                                                            }
                                                        })
                                                    }
                                                })}>Delete</a>
                                            </td>}
                                        </tr>
                                    ))
                                }
                            </tbody>

                        </table>
                    </div>
                    : <div style={{ textAlign: 'center' }}>No subsciption.</div>
            }


        </div >
    )
}










// <Form>
// <Form.Label>Account Type:</Form.Label>
// <Form.Control
//     as="select"
// value={type}
// onChange={e => {
//     console.log("e.target.value", e.target.value);
//     setType(e.target.value);
// }}
// >
//     <option value="DICTUM" selected disabled>Select Account</option>
//     <option value="space">space</option>
//     <option value="slide">slide</option>
// </Form.Control>
// <br />
// <Form.Label>Select Expiry Date:</Form.Label>

// <Form.Control type="date" name='expiryDate' />

// </Form>