import React, { useReducer, useEffect, createContext, useContext, useState, useRef } from 'react'
import { pageReducer, getSlideRecords, deleteSlide, getSlideToken, save_updateTags, getSearchList, updateSlidename, myGroups, shareSlidetoGroup } from "../Service";
import { AgGridReact } from 'ag-grid-react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Multiselect } from "multiselect-react-dropdown";
import { Pagination } from "../../shared/Pagination";
import { AddUploadSlide } from "../AddUpload/AddUploadSlide";
import { Form,  Modal, Button, ListGroup } from 'react-bootstrap'

export const Slides = () => {

    const initialState = {
        allowPaging: true,
        number_of_items: 10,
        index: 0,
        totalRecords: 0,
        slide_name: '',
        hideDeletedSlideStatus: true,
        slideList: [],
        // taglist: [],
        modalVisible: false,
        SlidenameTitle: '',
        searchList: {
            slidenamelist: [],
            taglist: []
        },
        myJoinedGroups: [],
        user_id: ''
    }

    const pageDispatcher = {
        setSlideRecords: (data) => dispatch({ type: 'set_slideRecords', payload: data }),
        setTagList: (data) => dispatch({ type: 'set_taglist', payload: data }),
        setTagObj: (data) => dispatch({ type: 'set_tagObj', payload: data }),
        updateIndex: (updatedIndex) => dispatch({ type: 'set_updateIndex', payload: updatedIndex }),
        set_modalVisible: (value) => dispatch({ type: 'set_modalVisible', payload: value }),
        set_slideNameTitle: (title) => dispatch({ type: 'set_slidenameTitle', payload: title }),
        setSearchList: (data) => dispatch({ type: 'set_searchList', payload: data }),
        setMyJoinedGroups: (data) => dispatch({ type: 'set_setMyJoinedGroups', payload: data }),
    }

    const [pageState, dispatch] = useReducer(pageReducer, initialState);


    useEffect(() => {
        pageDispatcher.updateIndex(0);
        myGroups(pageDispatcher)
        getSlideRecords(pageState.index, pageState.number_of_items, pageDispatcher, '')
        getSearchList(pageDispatcher)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="container-fluid">
            <PageContext.Provider value={{ pageState, pageDispatcher }}>
                <NotificationContainer />
                <SlideListComponent />
            </PageContext.Provider>
        </div>
    )
}

const SlideListComponent = () => {
    const multiselectElement = useRef(null);

    const { pageState, pageDispatcher } = useContext(PageContext);
    const [showUploadComponent, setShowUploadComponent] = useState(false);

    const [selectedRowRecord, setSelectedRowRecord] = useState('')

    const [searchParams, setSearchParams] = useState({
        searchparams: {
            slide_name: '',
            tags: []
        }
    });
    const [disableBtn, setDisableBtn] = useState(true)
    let arr = [];

    const [isSlideNameEditable, setIsSlideNameEditable] = useState(false);

    const initialState = {
        modalVisible: false,
        selectedSlideID: ''
    }

    const [manageSlideState, setManageSlideState] = useState(initialState)

    function getTagsOptions() {
        pageState.searchList.taglist.map(el => {
            if (el.tagsarr !== null) {
                arr.push(el.tagsarr)
            }
            return 0
        })
        arr = arr.flat();
        let uniqueChars = [...new Set(arr)];
        // console.log("uniqueChars", uniqueChars)
        return uniqueChars
    }

    const deletebyslideId = (slide_id) => {
        if (window.confirm('Sure want to delete?')) {
            getSlideToken(slide_id).then(res => {
                if (res && res.data && res.data.slide_token) {
                    let obj = {
                        slide_id: slide_id
                    }
                    let slide_accessToken = res.data.slide_token;
                    deleteSlide(obj, slide_accessToken)
                        .then(res => {
                            if (res && res.data.Message) {
                                getSlideRecords(pageState.index, pageState.number_of_items, pageDispatcher, '')
                                NotificationManager.success(res.data.Message, '', 2000)
                            }
                        }).catch(e => {
                            console.log(e)
                        })

                }
            })
        }
    }

    const StatusIcon = (params) => {
        switch (params.colDef.headerName) {

            // <i className="fa fa-star" style={{color:'gold'}} aria-hidden="true"></i> 
            case "Status":   //status 1,2,3 if for gcs && status 4,5 is for server.
                return (
                    <div>
                        {
                            params.data.status === 0 ? (<><i className="fa fa-ellipsis-h" aria-hidden="true"></i><br /><label>Initialized</label></>) :
                                params.data.status === 1 ? (<><i className="fa fa-upload" aria-hidden="true"></i><br /><label>Uploaded</label></>) :
                                    params.data.status === 2 ? (<><i className="fa fa-spinner" aria-hidden="true"></i><br /><label>Processing</label></>) :
                                        params.data.status === 3 ? (<><i className="fa fa-check-square-o" aria-hidden="true"></i><br /><label>Done</label></>) :
                                            params.data.status === 4 ? (<><i className="fa fa-spinner" aria-hidden="true"></i><br /><label>Processing by tileserver</label></>) :
                                                params.data.status === 5 ? (<><i className="fa fa-check-square-o" aria-hidden="true"></i><br /><label>Done</label></>) :
                                                    params.data.status === 7 ? (<><i className="fa fa-trash" aria-hidden="true"></i><br /><label>Flagged Delete</label></>) :
                                                        params.data.status === 8 ? (<><i className="fa fa-trash" aria-hidden="true"></i><br /><label>Deleting</label></>) :
                                                            params.data.status === 9 ? (<><i className="fa fa-trash" aria-hidden="true"></i><br /><label>Deleted</label></>) :
                                                                params.data.status === -1 ? (<><i className="fa fa-exclamation-triangle" aria-hidden="true"></i><br /><label>Error</label></>) : params.data.status

                        }
                    </div>
                )
            default:
        }

    }

    const onCellValueChanged = (event) => {
        let obj = {
            slide_id: event.data.slide_id,
            slide_name: event.data.slide_name
        }
        updateSlidename(obj).then(res => {
            if (res && res.data.Message) {
                getSlideRecords(pageState.index, pageState.number_of_items, pageDispatcher, '')
                getSearchList(pageDispatcher);
                NotificationManager.success(res.data.Message, '', 2000)
            }
        })
    }

    const checkStatusForSlideView = (params) => {
        if (params.data.status === 0) {
            setShowUploadComponent(true)
            setSelectedRowRecord(params.data);
            // pageDispatcher.set_slideNameTitle(params.data.slide_name)
            pageDispatcher.set_modalVisible(true);
        }
        if (params.data.status === 3 || params.data.status === 5) {
            const win = window.open('/slideView/' + params.data.slide_id, "_blank");
            win.focus();
        }
    }

    const convertToDateFormat = (timeData) => {
        if (timeData) {
            let date_val = new Date(timeData * 1000);
            let humanDateFormat = date_val.toLocaleString("en-AU", { timeZone: 'Asia/Kolkata' });
            return humanDateFormat;
        }
        else return ""
    }

    const LinkButton = (props) => {
        return (
            <>
                <a href="#!" style={{ color: "black" }} >
                    <i className="fa fa-ellipsis-v fa-lg" aria-hidden="true" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                    <div className="dropdown-menu" id="selectedElement" style={{ lineHeight: '15px', fontSize: '13px',marginLeft:'-45%' }}>
                        <a className="dropdown-item" href="#!" id="sharetogroup" onClick={() => setManageSlideState({ ...manageSlideState, modalVisible: true, selectedSlideID: props.slide_id })}><i class="fa fa-users" aria-hidden="true"></i> Share to a group</a>
                        <a className="dropdown-item" href="#!" id="remove" onClick={() => alert("Testing")}><i class="fa fa-cog" aria-hidden="true"></i> Other settings</a>
                    </div>
                </a>
            </>
        )
    }
    const columnDefs = [
        {
            headerName: "Slides",
            field: "slide_name",
            sortable: true,
            width: 300,
            editable: isSlideNameEditable,
            suppressMovable: true,
            cellRendererFramework: (params) => {
                return <div>

                    {params.data.status === 5 && <i className="fa fa-star" style={{ color: 'gold' }} aria-hidden="true"></i>}
                    <button onClick={() => {
                        checkStatusForSlideView(params)
                    }} style={{ color: "#008cff", border: "none", backgroundColor: 'white' }}>{params.data.slide_name}
                    </button>

                    <i className="fa fa-pencil-square-o fa-lg" aria-hidden="true" style={{ top: '16px', position: 'absolute', 'right': '0px', cursor: 'pointer' }} onClick={() => setIsSlideNameEditable(!isSlideNameEditable)}></i>

                </div>


            }
        },
        {
            headerName: 'Image',
            field: 'wsImage' || '',
            width: 350,
            wrapText: true,
            height: 250,
            cellRendererFramework: (params) => {
                if (params.data.wsImage && (params.data.status === 3 || params.data.status === 5)) {
                    return (
                        <button style={{ color: "#008cff", border: "none", backgroundColor: 'white' }} onClick={() => checkStatusForSlideView(params)}>
                            <img
                                // src={`data:image/jpg;base64,${params.data.wsImage}`}
                                src={`${params.data.wsImage}`}
                                alt="ResponsiveImage"
                                className="img-fluid" />
                        </button>
                    )
                }
                else {
                    return (
                        <p>No img</p>
                        //     <div className="spinner-border text-info" role="status">
                        //     <span className="sr-only">Loading...</span>
                        //   </div>
                    )
                }
            }
        },
        {
            headerName: "Created Date",
            field: "unixtime",
            width: 120,
            wrapText: true,
            suppressMovable: true,
            cellRendererFramework: (params) =>
                convertToDateFormat(params.data.unixtime || '')

        },
        {
            headerName: "Expiry Date",
            field: "expiry_date",
            width: 120,
            wrapText: true,
            suppressMovable: true,
            cellRendererFramework: (params) =>
                convertToDateFormat(params.data.expiry_date || '')
        },
        {
            headerName: "Status",
            field: "status",
            sortable: true,
            width: 170,
            suppressMovable: true,
            cellRendererFramework: (params) => StatusIcon(params)

        },
        {
            headerName: "Tags",
            field: 'tagsarr',
            width: 270,
            sortable: true,
            suppressMovable: true,
            cellRendererFramework: (params) => {
                return (
                    <TagsInput
                        taglistdata={params.data.tagsarr}
                        slide_id={params.data.slide_id} />
                )
            }
        },
        {
            headerName: "Delete",
            field: "status",
            sortable: true,
            width: 150,
            suppressMovable: true,
            cellRendererFramework: (params) => {
                return <a href="#!"
                    onClick={() => deletebyslideId(params.data.slide_id)}>Delete</a>
            }
        },
        {
            headerName: "Manage",
            field: "manage  ",
            sortable: true,
            width: 295,
            cellRendererFramework: (params) => <LinkButton slide_id={params.data.slide_id} />
        }
    ]

    const onGridReady = params => {
        params.api.showLoadingOverlay()
        params.api.resetRowHeights();
    }

    return (
        <div className='rtable'>

            <div className="row">
                <div className="col">
                    <div className="float-right">
                        <button type="button" className="btn btn-link btn-sm" onClick={() => {
                            setShowUploadComponent(false)
                            pageDispatcher.set_modalVisible(true)
                        }
                        }>
                            <i className="fa fa-plus-circle" aria-hidden="true"></i>  Add Slide
                        </button>
                    </div>

                    {
                        pageState.modalVisible && <AddUploadSlide uploadShow={{ showUploadComponent, setShowUploadComponent }} selectedRowRecord={selectedRowRecord} />
                    }
                </div>
            </div>


            <div className="row justify-content-between">

                <div className="col-md-2">
                    <div className="form-group">
                        <input className="form-control rounded" type="search" id="slidenamelist" onChange={(event) => {

                            if (event.target.value || searchParams.searchparams['tags'].length > 0) {
                                setDisableBtn(false)
                            }
                            else {
                                setDisableBtn(true);
                                getSlideRecords(0, pageState.number_of_items, pageDispatcher, '')
                            }

                            setSearchParams({ ...searchParams, searchparams: { ...searchParams.searchparams, slide_name: event.target.value } })

                        }} placeholder="search by slide name" />
                        {/* <datalist id="datalistOptions">
                            {
                                pageState.searchList.slidenamelist.map((el, index) => {
                                    return <option key={index} value={el.slide_name} style={{ marginBottom: '1px' }} />
                                })
                            }
                        </datalist> */}
                    </div>

                </div>
                <div className="col-md-2">
                    <div style={{ lineHeight: "3px" }}>
                        <Multiselect placeholder="search by tags" id="tagnamelist" ref={multiselectElement}
                            showArrow
                            options={getTagsOptions()}
                            isObject={false}
                            onSelect={(value) => {
                                if (value.length > 0 || searchParams.searchparams['slide_name'] !== '') {
                                    setDisableBtn(false)
                                }
                                else {
                                    setDisableBtn(true)
                                }
                                setSearchParams({ ...searchParams, searchparams: { ...searchParams.searchparams, tags: value } })
                            }}
                            onRemove={(value) => {
                                if (value.length > 0 || searchParams.searchparams['slide_name'] !== '') {
                                    setDisableBtn(false)
                                }
                                else {
                                    setDisableBtn(true)
                                }
                            }}
                        />
                    </div>

                </div>
                <div className="col-md-1">
                    <button className="btn btn-primary" disabled={disableBtn} onClick={(e) => {
                        e.preventDefault();
                        getSlideRecords(0, pageState.number_of_items, pageDispatcher, searchParams)
                    }}>Search</button>
                    {/* <i className="fa fa-search" aria-hidden="true"></i> */}

                </div>
                <div className="col-md-1">
                    <button className="btn btn-link col-xs-6"
                        onClick={(e) => {
                            e.preventDefault();
                            setDisableBtn(true)
                            document.getElementById('slidenamelist').value = '';
                            multiselectElement.current.resetSelectedValues();
                            setSearchParams({ ...searchParams, searchparams: { ...searchParams.searchparams, slide_name: '', tags: [] } })
                            getSlideRecords(0, pageState.number_of_items, pageDispatcher, '')
                        }}
                    >clear all</button>
                </div>
                <div className="col-md-6">
                    <Pagination initialState={pageState}

                        refreshListwithSearch={() => {
                            pageDispatcher.updateIndex(0);
                            getSlideRecords(0, pageState.number_of_items, pageDispatcher, '')
                        }}

                        setIndex={(indexValue) => {
                            pageDispatcher.updateIndex(indexValue);
                            getSlideRecords(indexValue, pageState.number_of_items, pageDispatcher, '')
                        }}

                    />
                </div>

            </div>

            <div className="ag-theme-alpine">
                <AgGridReact
                    colResizeDefault='shift'
                    domLayout="autoHeight"
                    rowHeight={120}
                    columnDefs={columnDefs}
                    rowData={pageState.slideList}
                    onGridReady={onGridReady}
                    onCellValueChanged={onCellValueChanged}
                    suppressNoRowsOverlay={true}
                    // isPopup={true}
                    overlayLoadingTemplate={
                        '<span className="ag-overlay-loading-center">loading...</span>'
                    }
                >
                </AgGridReact>

            </div>

            <SlideManageComponent stateVal={{ manageSlideState, setManageSlideState }} />

        </div>
    )
}

const TagsInput = (props) => {
    const { pageState, pageDispatcher } = useContext(PageContext);

    const initialState = {
        readOnly: true,
        inputbackgroundColor: '#cccccc',
        outline: 'none',
        tagArr: []
    }
    const [tagInputState, setTagInputState] = useState(initialState);
    useEffect(() => {
        if (props.taglistdata !== null) {
            setTagInputState({ ...tagInputState, tagArr: props.taglistdata })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const deleteTag = (slide_id, idx) => {
        let arr = [...tagInputState.tagArr];
        const index = arr.indexOf(arr[idx]);
        if (index > -1) {
            arr.splice(index, 1);
        }
        let obj = {
            slide_id: slide_id,
            tags: JSON.stringify(arr)
        }
        save_updateTags(obj).then(async res => {
            if (res && res.data.message === 'success') {
                await getSlideRecords(pageState.index, pageState.number_of_items, pageDispatcher, '');
                await getSearchList(pageDispatcher);
            }
        })

    }

    return (
        <div
            style={{ overflowY: 'auto', width: 'auto', maxHeight: '130px', border: '1px solid gray' }}
        >
            <div className="input-tag">
                <ul className="input-tag__tags">
                    <li className="input-tag__tags__input">
                        <Form.Control size="sm" className="tag-input" type="text" style={{ position: 'sticky' }} placeholder="+ New Tag" onKeyDown={(event) => {
                            if (event.key === 'Enter' && event.target.value.trim().length !== 0) {

                                var data = pageState.slideList.filter(x => x.slide_id === props.slide_id);
                                let tagarr = [];

                                if (data && data[0].tagsarr !== null && data[0].tagsarr !== undefined) {
                                    tagarr = [...data[0].tagsarr]
                                    tagarr.unshift(event.target.value);
                                }
                                else {
                                    tagarr.push(event.target.value)
                                }
                                let obj = {
                                    slide_id: props.slide_id,
                                    tags: JSON.stringify(tagarr)
                                }
                                save_updateTags(obj).then(async res => {
                                    if (res && res.data.message === 'success') {
                                        await getSearchList(pageDispatcher)
                                        await getSlideRecords(pageState.index, pageState.number_of_items, pageDispatcher, '')
                                        setTagInputState({ ...tagInputState, readOnly: true, backgroundColor: '#cccccc', outline: 'none' });
                                    }
                                })
                            }
                        }
                        } />
                    </li>
                    <br />

                    {

                        tagInputState && tagInputState.tagArr && tagInputState.tagArr.map((tagitems, index) => {
                            return (

                                <li key={index}>
                                    {/* <span>{tagitems}</span> */}

                                    <input readOnly={tagInputState.readOnly} className="custometags" autoFocus
                                        type="text"
                                        value={tagitems}
                                        onDoubleClick={(event) => {
                                            event.stopPropagation();
                                            setTagInputState({ ...tagInputState, readOnly: false, backgroundColor: 'white', outline: '' })
                                            event.target.focus()
                                        }}
                                        onChange={(event) => {
                                            event.stopPropagation();
                                            let newArr = [...tagInputState.tagArr]

                                            var index = newArr.indexOf(tagitems);

                                            if (index !== -1) {
                                                newArr[index] = event.target.value;
                                            }
                                            setTagInputState({ ...tagInputState, tagArr: newArr })
                                        }}
                                        onKeyDown={(event) => {
                                            event.stopPropagation();
                                            if (event.key === 'Enter' && event.target.value.trim().length !== 0) {
                                                //update tag
                                                let arr = [...tagInputState.tagArr]
                                                let obj = {
                                                    slide_id: props.slide_id,
                                                    tags: JSON.stringify(arr)
                                                }
                                                save_updateTags(obj).then(async res => {
                                                    if (res && res.data.message === 'success') {
                                                        await getSearchList(pageDispatcher)
                                                        await getSlideRecords(pageState.index, pageState.number_of_items, pageDispatcher, '')
                                                        setTagInputState({ ...tagInputState, readOnly: true, backgroundColor: '#cccccc', outline: 'none' });

                                                    }
                                                })
                                            }
                                        }}
                                        style={{ outline: `${tagInputState.outline}`, backgroundColor: `${tagInputState.inputbackgroundColor}`, cursor: 'pointer' }}
                                    />
                                    <button type="button" onClick={() => deleteTag(props.slide_id, index)}>+</button>
                                </li>
                            )
                        })

                    }
                </ul>
            </div>
        </div >
    )
}


const SlideManageComponent = (props) => {
    const { pageState } = useContext(PageContext);
    const { manageSlideState, setManageSlideState } = props.stateVal
    // console.log("uid", pageState.userid)
    const handleClick = (groupid) => {
        let obj = {
            group_id: groupid,
            slide_id: manageSlideState.selectedSlideID
        }
        shareSlidetoGroup(obj).then(res => {
            if (res && res.data.Message) {
                NotificationManager.success(res.data.Message, '', 2000);
                setManageSlideState({ ...manageSlideState, modalVisible: false })
            }
            if (res && res.data.Msg) {
                NotificationManager.info(res.data.Msg, '', 2000);
            }
        })
    }
    return (
        <>
            <Modal backdrop="static" show={manageSlideState.modalVisible} onHide={() => setManageSlideState({ ...manageSlideState, modalVisible: false })} aria-labelledby="contained-modal-title-lg" size="md"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Choose Group
                        {/* 
                        <Form className="d-flex">
                            <FormControl
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                            />
                            <Button variant="outline-success"><i className="fa fa-search" aria-hidden="true"></i></Button>
                        </Form> */}

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        pageState && pageState.myJoinedGroups.length > 0 ? pageState.myJoinedGroups.map((item, key) => (
                            <ListGroup style={{ cursor: 'pointer' }} key={key}>
                                <ListGroup.Item disabled={pageState.userid !== item.group_admin_user ? true : false} onClick={() => handleClick(item.group_id)}>{item.group_name}

                                    {
                                        pageState.userid === item.group_admin_user &&
                                        <small style={{ color: 'green', float: 'right' }}><i className="fa fa-circle" aria-hidden="true"></i> Admin</small>
                                    }
                                </ListGroup.Item>
                            </ListGroup>
                        )) : <div>No Group Found.</div>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setManageSlideState({ ...manageSlideState, modalVisible: false })}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export const PageContext = createContext(null)

