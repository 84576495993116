import React, { useEffect, useState } from "react"
import { AgGridReact } from 'ag-grid-react';
import { getSlides, updateReportingStatus, updateAssignedUser, getAssociatedGroupUsers } from "../Service";
import { Pagination } from "../../shared/Pagination";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Modal, Button } from 'react-bootstrap'

export const Slides = (props) => {

    const { myGroupState, setMyGroupState } = props.state
    let pageState = myGroupState;


    const [slideState, setSlideState] = useState({
        disableBtn: true,
        searchparam: {
            slide_name: '',
            showmyslides: false
        },
        assignSlideModalVisible: false,
        selectedSlideId: '',
        selectedRowAssignedUser: '',
        selectedEmail: '',
        disableUpdateBtn: true
    })

    useEffect(() => {
        let checkiftrue = localStorage.getItem('showmyslides');

        if (checkiftrue && checkiftrue === 'true') {
            setSlideState({ ...slideState, searchparam: { ...slideState.searchparam, showmyslides: true } })
        }
        else {
            setSlideState({ ...slideState, searchparam: { ...slideState.searchparam, showmyslides: false } })
        }

    }, [slideState])

    const convertToDateFormat = (timeData) => {
        if (timeData) {
            let date_val = new Date(timeData * 1000);
            let humanDateFormat = date_val.toLocaleString("en-AU", { timeZone: 'Asia/Kolkata' });
            return humanDateFormat;
        }
        else return ""
    }
    const checkStatusForSlideView = (params) => {
        if (params.data.status === 3 || params.data.status === 5) {
            const win = window.open('/groupslideview/' + params.data.slide_id, "_blank");
            win.focus();
        }
    }

    const handleReportingStatus = (val, slide_id) => {
        let obj = {
            'slide_id': slide_id,
            'reporting_status': val
        }
        updateReportingStatus(obj).then(res => {
            if (res && res.data.Message) {
                NotificationManager.success(res.data.Message, '', 2000)
            }
        })
    }

    const LinkButton = (params) => {
        // console.log("props",props)
        return (
            <>
                <b style={{ fontSize: '15px' }}>{params.data.assigned_to || ''}</b>
                <a href="#!" style={{ position: 'absolute', 'right': '0px', color: 'green' }} onClick={() => {
                    setSlideState({ ...slideState, assignSlideModalVisible: true, selectedSlideId: params.data.slide_id, selectedRowAssignedUser: params.data.assigned_to })
                }}>
                    <i className="fa fa-pencil" aria-hidden="true" ></i>
                </a>
            </>
        )
    }

    const columnDefs = [
        // {
        //     headerName: "SlideId",
        //     field: "slide_id",
        //     width: 350,

        // },
        {
            headerName: "Slides",
            field: "slide_name",
            width: 380,
            cellRendererFramework: (params) => {
                return <div>
                    {params.data.status === 5 && <i className="fa fa-star" style={{ color: 'gold' }} aria-hidden="true"></i>}

                    <button onClick={() => {
                        checkStatusForSlideView(params)
                    }} style={{ color: "#008cff", border: "none", backgroundColor: 'white' }}>{params.data.slide_name}
                    </button>
                </div>
            }
        },
        {
            headerName: 'Image',
            field: 'wsImage' || '',
            width: 380,
            wrapText: true,
            height: 250,
            cellRendererFramework: (params) => {
                if (params.data.wsImage && (params.data.status === 3 || params.data.status === 5)) {
                    return (
                        <button style={{ color: "#008cff", border: "none", backgroundColor: 'white' }} onClick={() => checkStatusForSlideView(params)}>
                            <img
                                // src={`data:image/jpg;base64,${params.data.wsImage}`}
                                src={`${params.data.wsImage}`}
                                alt="ResponsiveImage"
                                className="img-fluid" />
                        </button>
                    )
                }
                else {
                    return (
                        <p>No img</p>
                        //     <div className="spinner-border text-info" role="status">
                        //     <span className="sr-only">Loading...</span>
                        //   </div>
                    )
                }
            }
        },
        {
            headerName: "Created Date",
            field: "unixtime",
            width: 170,
            cellRendererFramework: (params) =>
                convertToDateFormat(params.data.unixtime || '')
        },
        {
            headerName: "Expiry Date",
            field: "expiry_date",
            width: 170,
            cellRendererFramework: (params) =>
                convertToDateFormat(params.data.expiry_date || '')
        },
        {
            headerName: "Reporting Status",
            field: "rep_status",
            width: 280,
            // cellRendererFramework: (params) => StatusIcon(params)
            cellRendererFramework: (params) => {
                return (
                    <div>
                        <select class="custom-select custom-select-sm" onChange={(e) => {
                            handleReportingStatus(e.target.value, params.data.slide_id)
                        }}>
                            {
                                params.data.rep_status === 0 ? <><option value='0' selected>Not Reported</option> <option value='1'>Reporting</option><option value='2'>Reported</option><option value='-1'>Flag Issue</option></> :
                                    params.data.rep_status === 1 ? <><option value='1' selected>Reporting</option> <option value='0'>Not Reported</option><option value='2'>Reported</option><option value='-1'>Flag Issue</option></> :
                                        params.data.rep_status === 2 ? <><option value='2'>Reported</option> <option value='0'>Not Reported</option><option value='1'>Reporting</option><option value='-1'>Flag Issue</option></> :
                                            params.data.rep_status === -1 ? <><option value='-1'>Flag Issue</option> <option value='0'>Not Reported</option><option value='1'>Reporting</option><option value='2'>Reported</option></> :
                                                params.data.rep_status
                            }

                        </select>
                    </div>
                )
            }
        },
        {
            headerName: "Assigned to",
            field: "assigned_to",
            width: 400,
            cellRendererFramework: (params) => <LinkButton {...params} />
        }
    ]
    const onGridReady = params => {
        params.api.showLoadingOverlay()
        params.api.resetRowHeights();
    }

    function getList(index, number_of_items, searchparam) {
        getSlides(index, number_of_items, searchparam).then(res => {
            if (res && res.data.slide_data) {
                setMyGroupState({
                    ...myGroupState,
                    index: res.data.index,
                    totalRecords: res.data.totalRecords,
                    slideData: res.data.slide_data
                })
            }
        })
    }

    return (
        <div className="rtable">
            <NotificationContainer />
            {
                slideState.assignSlideModalVisible && <AssignSlideToUser stateVal={{ slideState, setSlideState }} {...props} />
            }
            <div className="row justify-content-between">
                <div className="col-md-2">
                    <div className="form-group">
                        <input className="form-control" type="search" id="searchslide"
                            onChange={(event) => {
                                if (event.target.value) {
                                    setSlideState({ ...slideState, searchparam: { ...slideState.searchparam, slide_name: event.target.value }, disableBtn: false })
                                }
                                else {
                                    setSlideState({ ...slideState, searchparam: { ...slideState.searchparam, slide_name: '' }, disableBtn: true })
                                    getList(0, myGroupState.number_of_items,'')

                                }
                            }} placeholder="search by slide name" />
                        {/* <datalist id="datalistOptions">
                            {
                                myGroupState.slideData.map((el, index) => {
                                    return <option key={index} value={el.slide_name} style={{ marginBottom: '1px' }} />
                                })
                            }
                        </datalist> */}
                    </div>
                </div>

                <div className="col-md-1" style={{ marginLeft: '-31%' }}>
                    <button className="btn btn-secondary" id='gslidesearch' disabled={slideState.disableBtn} onClick={(e) => {
                        e.preventDefault();
                        getList(0, myGroupState.number_of_items, slideState.searchparam)
                    }}>search</button>
                </div>

                <div className="col-md-6">
                    <Pagination initialState={pageState}
                        refreshListwithSearch={() => {
                            document.getElementById('searchslide').value = '';
                            setMyGroupState({ ...myGroupState, index: 0 });
                            setSlideState({ ...slideState, disableBtn: true, searchparam: { ...slideState.searchparam, slide_name: '' } })

                            let obj = {};
                            if (slideState.searchparam.showmyslides === true) {
                                obj['showmyslides'] = true
                            }
                            getList(0, myGroupState.number_of_items, obj);
                        }}

                        setIndex={(indexValue) => {
                            setMyGroupState({ ...myGroupState, index: indexValue })
                            getList(indexValue, myGroupState.number_of_items, slideState.searchparam)
                        }}

                    />
                </div>
            </div>
            <div className="form-check" style={{ textAlign: 'right', marginTop: '-19px' }}>
                <input type="checkbox" className="form-check-input" checked={slideState.searchparam.showmyslides}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setSlideState({ ...slideState, searchparam: { ...slideState.searchparam, showmyslides: e.target.checked } })
                        }
                        else {
                            setSlideState({ ...slideState, searchparam: { ...slideState.searchparam, showmyslides: false } })
                        }
                        localStorage.setItem("showmyslides", e.target.checked);
                        getList(myGroupState.index, myGroupState.number_of_items, { 'showmyslides': e.target.checked })
                    }}
                />
                <label className="form-check-label"><b>show my slides</b></label>
            </div>
            <div className="ag-theme-alpine">
                {
                    myGroupState && myGroupState.slideData &&
                    <AgGridReact
                        colResizeDefault='shift'
                        domLayout="autoHeight"
                        rowHeight={120}
                        columnDefs={columnDefs}
                        rowData={myGroupState.slideData}
                        onGridReady={onGridReady}
                        suppressNoRowsOverlay={true}
                        overlayLoadingTemplate={myGroupState.msg &&
                            '<span className="ag-overlay-loading-center">loading...</span>'
                        }
                    >
                    </AgGridReact>
                }


            </div>
        </div>
    )
}


const AssignSlideToUser = (props) => {
    const { slideState, setSlideState } = props.stateVal;
    const { myGroupState, setMyGroupState } = props.state

    const [userlist, setUserlist] = useState([])

    useEffect(() => {
        getAssociatedGroupUsers().then(res => {
            if (res && res.data.user_data) {
                setUserlist(res.data.user_data)
            }
        })
    }, [])
    const handleAssignTo = () => {
        let obj = {
            slide_id: slideState.selectedSlideId,
            assigned_to: slideState.selectedEmail
        }
        updateAssignedUser(obj).then(res => {
            if (res && res.data.Message) {
                setSlideState({ ...slideState, assignSlideModalVisible: false })
                getSlides(myGroupState.index, myGroupState.number_of_items, '').then(res => {
                    if (res && res.data.slide_data) {
                        setMyGroupState({
                            ...myGroupState,
                            index: res.data.index,
                            totalRecords: res.data.totalRecords,
                            slideData: res.data.slide_data
                        })
                    }
                })
                NotificationManager.success(res.data.Message, '', 2000)

            }
        })
    }
    return (
        <>
            <Modal backdrop="static"
                show={slideState.assignSlideModalVisible}
                onHide={() => setSlideState({ ...slideState, assignSlideModalVisible: false })}
                aria-labelledby="contained-modal-title-lg" size="md"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>

                        {/* <Form className="d-flex">
                            <FormControl
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                            />
                            <Button variant="outline-success"><i className="fa fa-search" aria-hidden="true"></i></Button>
                        </Form> */}

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label style={{ float: 'left', marginTop: '-41px' }}><b>Currently Assigned To</b>: <a href="#!">{slideState.selectedRowAssignedUser}</a></label>
                    <div className="form-group">
                        <input className="form-control" list="datalistOptions2" id="searchuser1"
                            onChange={(event) => {
                                if (event.target.value) {
                                    let data = userlist.filter(el => el.email === event.target.value)
                                    setSlideState({ ...slideState, selectedEmail: data.length > 0 && data[0]['user_id'], disableUpdateBtn: false })
                                }
                                else {
                                    setSlideState({ ...slideState, selectedEmail: '', disableUpdateBtn: true })
                                }
                            }}
                            placeholder="find by email" />
                        <datalist id="datalistOptions2">
                            {
                                userlist && userlist.map((el, index) => {
                                    return <option key={index} value={el.email} style={{ marginBottom: '1px' }} />
                                })
                            }
                        </datalist>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        onClick={() => setSlideState({ ...slideState, assignSlideModalVisible: false })}
                    >
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={slideState.disableUpdateBtn}
                        onClick={() => handleAssignTo()}
                    >
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}