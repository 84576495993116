import React, { useReducer, useEffect, createContext, useContext, useState } from 'react'
import { pageReducer, getActiveDeviceList, getTurnCred } from "./Service";

export const ActiveDeviceList = (props) => {
    const initialState = {
        activeDeviceList: [],
    }
    // const [turnCerd, setTurnCerd] = useState([]);

    const [pageState, dispatch] = useReducer(pageReducer, initialState)
    const activeRoomName = sessionStorage.getItem('activeroomname')
    const filteredActiveDevicelist = pageState.activeDeviceList.map(device => {
        if (device != activeRoomName) {
            return device
        }
    })

    // console.log("filteredActiveDevicelist:", filteredActiveDevicelist)

    useEffect(() => {
        const interval = setInterval(() => {
            getActiveDeviceList(dispatch)
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    const handleOnClick = async (item) => {
        const response = await getTurnCred();
        // console.log("response: ", response.data)
        const { turn_cred: { password, turn_url, user } } = response.data
        // console.log('inside handleOnClick', password, turn_url, user)
        sessionStorage.setItem('password', password);
        sessionStorage.setItem('turn_url', turn_url);
        sessionStorage.setItem('user', user);
        sessionStorage.setItem('devicename', item.devicename);
        sessionStorage.setItem('roomname', item.roomname);
        window.location.reload(true)

    }

    return (
        <div className='my-4'>
            {pageState.activeDeviceList.length > 0 ? (
                <>

                    {/* <tr>
                        <th className='px-5'>Sr</th>
                        <th className='px-5'>Device Name</th>
                    </tr> */}
                    {filteredActiveDevicelist.map((item, index) => (
                        <div key={item.roomname}>
                            {/* <td>
                                {index + 1}
                            </td> */}
                            <p onClick={() => handleOnClick(item)} style={{ color: "#008cff", cursor: 'pointer', backgroundColor: 'white' }}>{item.devicename}</p>
                        </div>
                    ))}

                </>) : ""}
        </div>
    )
}

export const PageContext = createContext(null)


