import React, { createContext, useEffect, useReducer, useContext } from "react";
import { PageTitle } from "../shared/PageTitle";
import { pageReducer, getUserList } from "./Service";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Users } from "./TabsComponent/Users";
import { DiskSpace } from "./TabsComponent/DiskSpace";
import { CloudId } from "./TabsComponent/CloudId";
import { Groups } from "./TabsComponent/Groups";
import { ChangePassword } from "./TabsComponent/ChangePassword";
import { Subscription } from "./TabsComponent/Subscription";
import { getUser } from "../Utils/Common";
import { initializeFirebaseUI } from "../Auth/Account";
import "firebase/auth";
import OtpGeneration from "./TabsComponent/OtpGeneration";
import DeviceRegistration from "./TabsComponent/DeviceRegistration";
import ConfigurationData from "./TabsComponent/ConfigurationData";
import DeviceSubscription from "./TabsComponent/DeviceSubscription";
import QRcodeGenerator from "./TabsComponent/QRcodeGenerator";

export const Settings = () => {
  const initialState = {
    userList: [],
    isLoggedInWithEmail: true,
    cloudId: "",
    isMaint: {
      otp: "",
      expiry: "",
    },
    deviceRegistrationToggle:false,
    configDataToggle:false,
    // diskSpaceData: {}
  };


  useEffect(() => {
    if (getUser()) {
      getUserList(dispatch);
    }
  }, []);

  const pageDispatcher = {
    set_isLoggedInWithEmail: (value) => dispatch({ type: "set_isLoggedInWithEmail", payload: value }),
    set_cloudId: (value) => dispatch({ type: "set_cloudId", payload: value }),
    set_isMaint: (value) => dispatch({ type: "set_isMaint", payload: value }),
    set_deviceRegistrationToggle: (value) => dispatch({ type: "set_deviceRegistrationToggle", payload: value }),
    set_configDataToggle: (value) => dispatch({ type: "set_configDataToggle", payload: value }),
  };

  const [pageState, dispatch] = useReducer(pageReducer, initialState);

  return (
    <div className="container-fluid">
      <PageContext.Provider value={{ pageState, pageDispatcher, dispatch }}>
        {/* {JSON.stringify(getUser())} */}
        <PageTitle
          title={"PageTitle"}
          backLink="/slides"
        ></PageTitle>
        <br />
        <div style={{ marginTop: "5%" }}>
          {pageState.userList && pageState.userList.length > 0 && getUser() && (
            <div className="rtable">
              <AdminTabComponent />
            </div>
          )}

          {
            //to check if user && "user" not admin & has "hasdevice"
            getUser() === false && (
              <div className="rtable">
                <UserTabComponent />
              </div>
            )
          }
        </div>
      </PageContext.Provider>
    </div>
  );
};

const checkProvider = (pageDispatcher) => {
  initializeFirebaseUI("");

  let providerId = localStorage.getItem("providerId");

  if (providerId !== "password") {
    pageDispatcher.set_isLoggedInWithEmail(false);
  }
};

export const AdminTabComponent = () => {
  const context = useContext(PageContext);
  const { pageState, pageDispatcher } = useContext(PageContext)
  let hasDevice = localStorage.getItem("hasDevice");
  let isMaint = localStorage.getItem("isMaint");

  const refreshDeviceRegistration=()=>{
    console.log("pageState.deviceRegistrationToggle",pageState.deviceRegistrationToggle)
    pageDispatcher.set_deviceRegistrationToggle(!pageState.deviceRegistrationToggle)
  }
  const refreshConfigData=()=>{
    console.log("pageState.configDataToggle",pageState.configDataToggle)
    pageDispatcher.set_configDataToggle(!pageState.configDataToggle)
  }

  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey="1"
    >
      <Row>
        <Col sm={2}>
          <Nav
            variant="pills"
            className="flex-column"
          >
            <Nav.Item>
              <Nav.Link eventKey="1">User Management</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="5">Group Management</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="2">Disk Space</Nav.Link>
            </Nav.Item>
            {JSON.parse(hasDevice) && (
              <Nav.Item>
                <Nav.Link eventKey="3">Cloud Id</Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item onClick={() => checkProvider(context.pageDispatcher)}>
              <Nav.Link eventKey="4">Change Password</Nav.Link>
            </Nav.Item>
            {JSON.parse(isMaint) && (
              <Nav.Item>
                <Nav.Link eventKey="6">OTP</Nav.Link>
              </Nav.Item>
            )}
            {JSON.parse(isMaint) && (
            <Nav.Item>
              <Nav.Link eventKey="7" onClick={refreshDeviceRegistration}>Device Registration</Nav.Link>
            </Nav.Item>
            )}
            {JSON.parse(isMaint) && (
            <Nav.Item>
              <Nav.Link eventKey="8" onClick={refreshConfigData}>Configuration Data</Nav.Link>
            </Nav.Item>
            )}
            {JSON.parse(isMaint) && (
            <Nav.Item>
              <Nav.Link eventKey="9">Device Subscription</Nav.Link>
            </Nav.Item>
            )}
            {JSON.parse(isMaint) && (
            <Nav.Item>
              <Nav.Link eventKey="10">QRcode </Nav.Link>
            </Nav.Item>
            )}
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="1">
              <Users />
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              <DiskSpace />
            </Tab.Pane>
            {JSON.parse(hasDevice) && (
              <Tab.Pane eventKey="3">
                <CloudId />
              </Tab.Pane>
            )}
            <Tab.Pane eventKey="4">
              {context.pageState.isLoggedInWithEmail ? (
                <ChangePassword />
              ) : (
                <div>
                  <b style={{ color: "green" }}>Log off to reset the password with your google account itself.</b>
                </div>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="5">
              <Groups />
            </Tab.Pane>
            {JSON.parse(isMaint) && (
              <Tab.Pane eventKey="6">
                <OtpGeneration />
              </Tab.Pane>
            )}
            {JSON.parse(isMaint) && (
            <Tab.Pane eventKey="7">
              <DeviceRegistration />
            </Tab.Pane>
            )}
            {JSON.parse(isMaint) && (
            <Tab.Pane eventKey="8">
              <ConfigurationData />
            </Tab.Pane>
            )}
            {JSON.parse(isMaint) && (
            <Tab.Pane eventKey="9">
              <DeviceSubscription />
            </Tab.Pane>
            )}
            {JSON.parse(isMaint) && (
            <Tab.Pane eventKey="10">
              <QRcodeGenerator />
            </Tab.Pane>
            )}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export const UserTabComponent = () => {
  const context = useContext(PageContext);

  let hasDevice = localStorage.getItem("hasDevice");
  let isMaint = localStorage.getItem("isMaint");

  const { pageState, pageDispatcher } = useContext(PageContext)
  const refreshDeviceRegistration=()=>{
    console.log("pageState.deviceRegistrationToggle",pageState.deviceRegistrationToggle)
    pageDispatcher.set_deviceRegistrationToggle(!pageState.deviceRegistrationToggle)
  }
  const refreshConfigData=()=>{
    console.log("pageState.configDataToggle",pageState.configDataToggle)
    pageDispatcher.set_configDataToggle(!pageState.configDataToggle)
  }

  // console.log("After setting hasDevice localstorage",typeof(hasDevice))

  return (
    <Tab.Container
      id="left-tabs-example"
      defaultActiveKey="2"
    >
      <Row>
        <Col sm={2}>
          <Nav
            variant="pills"
            className="flex-column"
          >
            {JSON.parse(hasDevice) && (
              <Nav.Item>
                <Nav.Link eventKey="1">CloudID</Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link eventKey="2">Disk Space</Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => checkProvider(context.pageDispatcher)}>
              <Nav.Link eventKey="3">Change Password</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="4">Subscription</Nav.Link>
            </Nav.Item>
            {JSON.parse(isMaint) && (
              <Nav.Item>
                <Nav.Link eventKey="5">OTP</Nav.Link>
              </Nav.Item>
            )}
            {JSON.parse(isMaint) && (
            <Nav.Item>
              <Nav.Link eventKey="6" onClick={refreshDeviceRegistration}>Device Registration</Nav.Link>
            </Nav.Item>
            )}
            {JSON.parse(isMaint) && (
            <Nav.Item>
              <Nav.Link eventKey="7" onClick={refreshConfigData}>Configuration Data</Nav.Link>
            </Nav.Item>
            )}
            {JSON.parse(isMaint) && (
            <Nav.Item>
              <Nav.Link eventKey="8">Device Subscription</Nav.Link>
            </Nav.Item>
            )}
            {JSON.parse(isMaint) && (
            <Nav.Item>
              <Nav.Link eventKey="9">QRcode</Nav.Link>
            </Nav.Item>
            )}
            
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            {JSON.parse(hasDevice) && (
              <Tab.Pane eventKey="1">
                <CloudId />
              </Tab.Pane>
            )}

            <Tab.Pane eventKey="2">
              <DiskSpace />
              {/* No Use nad DeviceInfo also no use of this component
                            api is not there yet! */}
            </Tab.Pane>

            <Tab.Pane eventKey="3">
              {context.pageState.isLoggedInWithEmail ? (
                <ChangePassword />
              ) : (
                <div>
                  <b style={{ color: "green" }}>Log off to reset the password with your google account itself.</b>
                </div>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="4">
              <Subscription />
            </Tab.Pane>
            {JSON.parse(isMaint) && (
              <Tab.Pane eventKey="5">
                <OtpGeneration />
              </Tab.Pane>
            )}
            {JSON.parse(isMaint) && (
            <Tab.Pane eventKey="6">
              <DeviceRegistration />
            </Tab.Pane>
            )}
            {JSON.parse(isMaint) && (
            <Tab.Pane eventKey="7">
              <ConfigurationData />
            </Tab.Pane>
            )}
            {JSON.parse(isMaint) && (
            <Tab.Pane eventKey="8">
              <DeviceSubscription />
            </Tab.Pane>
            )}
            {JSON.parse(isMaint) && (
            <Tab.Pane eventKey="9">
              <QRcodeGenerator />
            </Tab.Pane>
            )}
            
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export const PageContext = createContext(null);
