import React, { useEffect, useState, useContext } from "react";
import { saveDeviceName, getDeviceNameList } from "../Service";
import { PageContext } from "../Settings";
import DeviceDropdown from "./DeviceDropdown";

function DeviceNameInput() {
  const [deviceName, setDeviceName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [activeDevices, setActiveDevices] = useState([]);
  const [inactiveDevices, setInactiveDevices] = useState([]);
  const [isDeviceNameAvailable, setIsDeviceNameAvailable] = useState(true);
  const [requestStatus, setRequestStatus] = useState("loading");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [organize_Name, setOrganize_name] = useState("");

  const { pageState, pageDispatcher } = useContext(PageContext);

  useEffect(() => {
    async function fetchDeviceNames() {
      try {
        const response = await getDeviceNameList();
        setActiveDevices(response.data["active_devices List"]);
        setInactiveDevices(response.data["inactive_devices List"]);
        setOrganize_name(response.data["organisation_name"]);
        setRequestStatus("success");
      } catch (error) {
        console.error("Error fetching device names", error);
        if (error.response && error.response.status === 404) {
          setRequestStatus("timeout");
        } else {
          setRequestStatus("failure");
        }
      }
    }
    fetchDeviceNames();
  }, [pageState.deviceRegistrationToggle]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.trim(); // Remove leading and trailing spaces
    setDeviceName(trimmedValue);
    checkDeviceNameAvailability(trimmedValue);
  };

  const checkDeviceNameAvailability = (name) => {
    setIsDeviceNameAvailable(!activeDevices.includes(name));
  };

  const handleSave = async () => {
    if (!isDeviceNameAvailable) {
      const confirmMessage = `The device name "${deviceName}" is already in use. Do you want to use the same name?`;
      const userConfirmed = window.confirm(confirmMessage);

      if (!userConfirmed) {
        return;
      }
    }

    setIsSaving(true);
    const obj = { device_name: deviceName };
    try {
      await saveDeviceName(obj);
      setIsSaving(false);
      setDeviceName("");
      alert("Device name saved successfully");
      pageDispatcher.set_deviceRegistrationToggle(!pageState.deviceRegistrationToggle);
    } catch (error) {
      setIsSaving(false);
      console.error(" Error saving device name", error);
    }
  };

  const handleCopyDeviceName = (name) => {
    setDeviceName(name);
    setIsDeviceNameAvailable(!activeDevices.includes(name));
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const refreshRequestPage=()=>{
    console.log("refreshRequestPage",pageState.deviceRegistrationToggle)

    pageDispatcher.set_deviceRegistrationToggle(!pageState.deviceRegistrationToggle)
  }


  const showHideButtonValue=isDropdownVisible?"Hide":"Show"

  const headerStyle = {
    fontWeight: 'bold',
    color: 'blue',
  };

  return (
    <div className="d-flex flex-sm-column justify-content-center align-items-center">
      {requestStatus === "success" ? (
        <>
        <div className="d-flex " >
          {organize_Name ? <h>Set Device Name for <span  style={organize_Name ? headerStyle : {}}>{organize_Name}: </span></h> : <h>Set Device Name:</h>}
          <input
            type="text"
            placeholder="Enter device name"
            value={deviceName}
            onChange={handleInputChange}
          />
          <button
          className="ml-2"
            onClick={handleSave}
            disabled={isSaving || deviceName.trim() === ""}
          >
            {isSaving ? "Saving..." : "Save"}
          </button>
          </div>
          <div className="mt-5">
            <h className="mb-2" >Device Name List In use: <span><button onClick={toggleDropdown}>{showHideButtonValue}</button></span> </h>
            {isDropdownVisible && (
            <DeviceDropdown
              activeDevices={activeDevices}
              inactiveDevices={inactiveDevices}
              onCopyDeviceName={handleCopyDeviceName}
            />
          )}
          </div>
        </>
      ) : requestStatus === "timeout" ? (
        <div>
          <p>No Request</p>
          <button type="button" className="btn btn-primary"  onClick={refreshRequestPage}>Refresh</button>
        </div>

      ) : requestStatus === "failure" ? (
        <div>Error fetching device names</div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default DeviceNameInput;
