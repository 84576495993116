import { logout } from "../Auth/Account";

// ********************************************* set authData in cookie ************************************************//

export const setAuthData = (authData) => {
  try {

    var now = new Date();
    var expire = new Date();

    expire.setDate(now.getDate() + 2);

    var expires = "expires=" + expire.toGMTString();

    document.cookie = "token=" + authData.token + "; " + expires + "; path=/";

    document.cookie = "username=" + authData.username + "; " + expires + "; path=/";

    document.cookie = "isAdmin=" + authData.isAdmin + "; " + expires + "; path=/";

  }
  catch (err) {
    console.log(err)
  }

}

export const getToken = () => {
  try {

    const tokenKey = "token=";

    const decodedValue = decodeURIComponent(document.cookie); //to be careful

    const arrVal = decodedValue.split('; ');

    let res;
    arrVal.forEach(val => {
      if (val.indexOf(tokenKey) === 0) res = val.substring(tokenKey.length);
    })

    if (res) {

      let jwtpayload = JSON.parse(window.atob(res.split('.')[1]));

      const expirationTime = (jwtpayload.exp * 1000) - 60000;
      // console.log("jwtpayload", Date.now() >= expirationTime)

      if (Date.now() >= expirationTime) {  //Date.now() in milliseconds is greater or equal to the expiration time (converted to milliseconds)
        logout();
      }
      else {
        return res
      }
    }

  }
  catch (err) {
    console.log(err)
  }
}

export const getUser = () => {
  try {
    const unameKey = "isAdmin=";
    const decodedValue = decodeURIComponent(document.cookie); //to be careful
    const arrVal = decodedValue.split('; ');

    let res;
    arrVal.forEach(val => {
      if (val.indexOf(unameKey) === 0) res = val.substring(unameKey.length);
    })

    if (res === "true") {
      return true
    }
    else return false
  }

  catch (err) {
    console.log(err)
  }
}

export const getName = () => {
  try {
    const unameKey = "username=";
    const decodedValue = decodeURIComponent(document.cookie); //to be careful
    const arrVal = decodedValue.split('; ');

    let res;
    arrVal.forEach(val => {
      if (val.indexOf(unameKey) === 0) res = val.substring(unameKey.length);
    })
    return res
  }

  catch (err) {
    console.log(err)
  }
}

export const removeCookie = () => {
  try {
    var now = new Date();
    var expire = new Date();

    expire.setDate(now.getDate() - 1);

    var expires = "expires=" + expire.toGMTString();

    document.cookie = "token=; " + expires + "; path=/";

    document.cookie = "username=; " + expires + "; path=/";

    document.cookie = "isAdmin=; " + expires + "; path=/";

    document.cookie = "grouptoken=; " + expires + "; path=/";

  }
  catch (err) {
    console.log(err)
  }
}

export const setGroupToken = (grouptoken) => {
 sessionStorage.setItem('grouptoken',grouptoken)
}

export const getGroupToken=()=>{
  return sessionStorage.getItem('grouptoken')
}